import * as React from 'react';
import { styled } from 'styled-components';

import { Map } from '@longline/aqua-ui/map/Map';
import { Dock, Dockable } from '@longline/aqua-ui/containers/Dock';

import { useGlobalState } from '../contexts/state/GlobalStateContext';
import { useConfig } from '../services/config/IConfig';
import { MapControls } from './MapControls';
import { CircleBuilder, CircleEditor } from '../editors/CirleEditor';
import { PolygonBuilder, PolygonEditor } from '../editors/PolygonEditor';
import { StructureInfoBox } from '../infoboxes/StructureInfoBox';
import { ReferenceLayers } from './ReferenceLayers';
import { DataLayers } from './DataLayers';
import { StructuresLayer } from './StructuresLayer';
import { MarkerLayer } from './MarkerLayer';
import { Legends } from './Legends';
import { IStructure } from '../types/IStructure';
import { RectBuilder } from '../editors/RectEditor';
import { RectEditor } from '../editors/RectEditor/RectEditor';
import { AreaInfoBox } from '../infoboxes/AreaInfoBox';
import { AreasLayer } from './AreasLayer';
import { PolygonUtils } from '../util/PolygonUtils';
import { OrganixExportLayer } from './OrganixExportLayer';

interface IProps {
  /** @ignore */
  className?: string;
}

const MapViewBase = (props: IProps) => {
  const config = useConfig();
  const globalState = useGlobalState();
  const copiedStructure = React.useRef<IStructure>(null);

  React.useEffect(() => {
    // TODO: REMOVE THIS!
    // Clears entire Mapbox tile cache for testing of clipping strategy.
    // mapboxgl.clearStorage();
  }, []);  

  const handleKeydown = (e: React.KeyboardEvent) => {
    // If Ctrl+C is pressed, copy current stucture to memory.
    if(e.code == 'KeyC' && e.ctrlKey && globalState.selectedStructure != null) {
      // Make a deep copy:
      copiedStructure.current = JSON.parse(JSON.stringify(globalState.selectedStructure));
    }
    // If Ctrl+V is pressed, paste current structure from memory.
    if(e.code == 'KeyV' && e.ctrlKey && copiedStructure.current != null) {
      if(copiedStructure.current.type == 'circle') {
        // Move circle center by one radius right and down:
        const p = PolygonUtils.addMeters(copiedStructure.current.point, copiedStructure.current.radius, -copiedStructure.current.radius);
        copiedStructure.current.point = p;
      } else {
        // Move each point on polygon by one-half polygon bbox right and down:
        const bbox = PolygonUtils.getBbox(copiedStructure.current.points);
        const width = Math.abs(bbox.getEast() - bbox.getWest()) / 2
        const height = Math.abs(bbox.getNorth() - bbox.getSouth()) / 2;
        copiedStructure.current.points = copiedStructure.current.points.map(p => { return { lng: p.lng + width, lat: p.lat - height }; });
      }
      // Make a deep copy:
      globalState.addStructure(JSON.parse(JSON.stringify(copiedStructure.current)));
    }
  }

  return (
    <div 
      className={props.className} 
      // If mapholder is clicked:
      onClick={() => { 
        // Structure code requires mouse clicks; don't cancel it.
        if(globalState.mode == 'circle-builder') return;
        if(globalState.mode == 'polygon-builder') return;
        if(globalState.mode == 'area-builder') return;
        if(globalState.mode == 'area-editor') return;
        if(globalState.mode == 'areas' && globalState.selectedArea != null) {
          globalState.setMode('area-editor')
          return;
        }
        globalState.setMode(null);
      }}
      onKeyDown={handleKeydown}
    >
      <Map
        style={config.mapboxStyle}
        token={config.mapboxKey}
        clipped={1.0001}
        latitude={0}
        longitude={0}
        zoom={2}
        logoPosition='bottom-left'
        interactiveLayerIDs={["structures"]}
        noRotate
        preserveDrawingBuffer
        onLoad={(map) => globalState.setMap(map)}
      >
        <MapControls/>

        <ReferenceLayers/>
        <DataLayers/>
        <StructuresLayer/>
        {(globalState.mode == 'area-builder' || ((globalState.mode == 'areas' || globalState.mode == 'area-editor') && globalState.selectedArea)) && 
          <AreasLayer/>}
        <MarkerLayer layers={globalState.layers}/>

        {/* Circle editor */}
        {globalState.selectedStructure != null && globalState.selectedStructure.type == 'circle' && 
          <CircleEditor 
            point={globalState.selectedStructure.point}
            radius={globalState.selectedStructure.radius}
            onChange={globalState.updateSelectedStructureCircle}
            onCancel={() => globalState.setSelectedStructure(null)}
            onDelete={globalState.deleteSelectedStructure}
        />}        

        {/* Polygon editor */}
        {globalState.selectedStructure != null && globalState.selectedStructure.type == 'polygon' && 
          <PolygonEditor 
            points={globalState.selectedStructure.points}
            onChange={globalState.updateSelectedStructurePolygon}
            onCancel={() => globalState.setSelectedStructure(null)}
            onDelete={globalState.deleteSelectedStructure}
        />}                

        {/* Area editor */}
        {globalState.selectedArea != null && (globalState.mode == 'areas' || globalState.mode == 'area-editor') && 
          <RectEditor 
            rect={globalState.selectedArea.rect}
            resolution={globalState.selectedArea.resolution}
            onChange={globalState.updateSelectedArea}
        />}              

        {/* Circle builder */}
        {globalState.mode == 'circle-builder' && 
          <CircleBuilder onCancel={() => globalState.setMode(null)} onComplete={(point, radius) => globalState.addStructureCircle(point, radius)}/>}

        {/* Polygon builder */}
        {globalState.mode == 'polygon-builder' && 
          <PolygonBuilder onCancel={() => globalState.setMode(null)} onComplete={(points) => globalState.addStructurePolygon(points)}/>}          

        {/* Area builder */}
        {globalState.mode == 'area-builder' &&
          <RectBuilder onCancel={() => globalState.setMode(null)} onComplete={(rect) => globalState.addArea(rect)}/>}

        <Dock top={16} right={16} bottom={200}>
          {globalState.selectedStructure && globalState.mode == null && <Dockable longitude={0} latitude={0} alwaysDocked>
            <StructureInfoBox value={globalState.selectedStructure}/>
          </Dockable>}
          {globalState.selectedArea && globalState.mode == 'area-editor' && <Dockable longitude={0} latitude={0} alwaysDocked>
            <AreaInfoBox value={globalState.selectedArea}/>
          </Dockable>}
        </Dock>

        <Legends/>

        {globalState.organix != null && <OrganixExportLayer area={globalState.organix} onClose={() => globalState.stopOrganix()}/>}
        
      </Map>
    </div>
  );
}

const MapViewStyled = styled(MapViewBase)`
  position: absolute;
  left: 80px;
  top: 0;
  right: 0;
  bottom: 0;
  background: transparent;
`

const MapView = (props: IProps) => <MapViewStyled {...props}/>

export { MapView }


