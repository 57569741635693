import * as React from 'react';
import { IUser } from '../../api/models/IUser';

interface IAuth {
  user: IUser;
  login: (user: IUser) => void;
  logout: () => void;
}

const AuthContext = React.createContext<IAuth>({
  user: null,
  login: () => {},
  logout: () => {}
});

export { AuthContext, IAuth }
