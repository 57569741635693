import * as React from 'react';
import { Source } from 'react-map-gl/mapbox';
import { featureCollection, point } from '@turf/helpers';
import { HtmlMarkerLayer } from '@longline/aqua-ui/map/layers/HtmlMarkerLayer';

import { ILayer } from '../types/ILayer';
import { Marker } from './Marker';
import { useGlobalState } from '../contexts/state/GlobalStateContext';
import { Feature, FeatureCollection } from 'geojson';
import { LayerUtils } from '../util/LayerUtils';

interface IProps {
  layers: ILayer[];
}

const MarkerLayer = (props: IProps) => {
  const globalState = useGlobalState();

  // Build GeoJSON for markers.
  const getFeatures = (): FeatureCollection => {
    const features: Feature[] = [];
    for(let i = 0; i < props.layers.length; i++) {
      const layer = props.layers[i];
      // Don't show marker for hidden layers.
      if(!layer.visible) continue;
      // Get center of layer.
      features.push(point(LayerUtils.getCentroid(layer), { id: i }));
    }
    return featureCollection(features);
  }

  // Zoom to layer when marker is clicked:
  const handleClick = (index: number) => {
    globalState.focusLayer(globalState.layers[index]);
  }

  return (
    <Source id="markers" type="geojson" data={getFeatures()}>
      <HtmlMarkerLayer sourceId="markers" idField="id" minZoom={0} maxZoom={8}>
        {(markerProps) => <Marker onClick={() => handleClick(markerProps.id)} {...markerProps}/>}
      </HtmlMarkerLayer>
    </Source>
  )
}

export { MarkerLayer }