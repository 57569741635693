import * as React from 'react';
import styled from 'styled-components';

import { ILoginRequest } from '../../api/services/auth/ILoginRequest';
import { Form } from '@longline/aqua-ui/containers/Form';
import { Input } from '@longline/aqua-ui/inputs/Input';

interface IProps {
  /** Initial form data. */
  data: ILoginRequest;
  /** Called whenever form changes. */
  onChange: (data: ILoginRequest, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  dirty?: boolean;
  onSubmit: () => void;
}

const LoginForm = (props: IProps) => {
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if(e.key == 'Enter') props.onSubmit();
  }
  
  return (
    <div onKeyDown={handleKeyDown}>
      <Form data={props.data} dirty={props.dirty} onChange={props.onChange} onValidate={props.onValidate}>
        <Column>
          <Form.Field 
            rules={{
              required: { message: "Email is required." }
            }}
            name="email" 
            value={props.data.email}
            label="Email"
            wrapper='box'
            control={(<Input clearable transparent type="text" placeholder="Email" fluid/>)}
          />
          <Form.Field 
            rules={{
              required: { message: "Password is required." }
            }}          
            name="password" 
            value={props.data.password}
            label="Password"
            wrapper='box'
            control={(<Input transparent type="password" revealable placeholder="Password" fluid/>)}
          />
        </Column>
      </Form>
    </div>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export { LoginForm }
