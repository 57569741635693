// @ts-ignore
import init, { _ready, _malloc, _free, HEAP16, HEAP32, HEAPU8, _float_sqrt, _float_sqr, _float_sum } from './calc.mjs';

class Facade {

  static start = async () => {
    await init();
    console.log(_ready ? "WASM loaded" : "WASM failed to load");
  }

  static sqrt = (x: number): number => {
    if(!_ready) return null;
    return _float_sqrt(x);
  }

  static sqr = (x: number): number => {
    if(!_ready) return null;
    return _float_sqr(x);
  }

  static sum = (values: number[]): number => {
    if(!_ready) return null;
    // https://kapadia.github.io/emscripten/2013/09/13/emscripten-pointers-and-pointers.html
    const typedArray = new Float32Array(values);
    const bytes = typedArray.length * typedArray.BYTES_PER_ELEMENT;
    const pointer = _malloc(bytes);
    const heap = new Uint8Array(HEAPU8.buffer, pointer, bytes);
    heap.set(new Uint8Array(typedArray.buffer));
    const result = _float_sum(heap.byteOffset, values.length);
    _free(heap.byteOffset);
    return result;
  }
  
}

Facade.start();

export { Facade }
