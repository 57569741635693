import * as React from 'react';

import { IRect } from '../../types/IRect';
import { RectEditorSurface } from './RectEditorSurface';
import { RectEditorPoints } from './RectEditorPoints';
import { Guide } from '../../Guide';
import { Mouse } from '@longline/aqua-ui/controls/Mouse';

interface IProps {
  /** Rect */
  rect: IRect;
  /** Horizontal grid resolution */
  resolution: number;
  /** Fired when rects's points change. */
  onChange: (rect: IRect) => void;
}

const RectEditor = (props: IProps) => {
  const handleChange = (newRect: IRect) => {
    props.onChange(newRect);
  }

  return (
    <>
      <Guide x={-64} y={-48} title="Editing an export area">
        <ul>
          <li>Click <Mouse size={14} left/> and drag a corner point to resize the area.</li>
          <li>Click <Mouse size={14} left/> and drag the area's surrace to move the area.</li>
        </ul>
      </Guide>
      <RectEditorSurface rect={props.rect} resolution={props.resolution} onChange={handleChange} />
      <RectEditorPoints  rect={props.rect} onChange={handleChange}/>
    </>
  );
}

export { RectEditor }
