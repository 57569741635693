import * as React from 'react';

import { useGlobalState } from '../contexts/state/GlobalStateContext';
import { ReferenceLayer } from './ReferenceLayer';

const ReferenceLayers = () => {
  const globalState = useGlobalState();

  const referenceLayers = React.useMemo(() => {
    return globalState.references
      .filter(reference => reference.visible)
      .map((r, index) => <ReferenceLayer key={index} reference={r}/>)
  }, [globalState.references]);

  return referenceLayers;
}

export { ReferenceLayers }