import * as React from 'react';
import { InterpolationLayer } from '@longline/aqua-ui/map/layers/InterpolationLayer';
import { ParticlesLayer } from '@longline/aqua-ui/map/layers/ParticlesLayer';
import { ILayer } from "./types/ILayer";
import { ELayerType } from './types/ELayerType';

class LayerFactory {
  static getLayer = (index: number, layer: ILayer): React.ReactNode => {
    let data;

    switch(layer.type) {
      case ELayerType.CurrentSpeed:
        data = layer.data.map(point => { return { latitude: point.lat, longitude: point.lng, u: point.value[0], v: point.value[1] }; });
        return <ParticlesLayer 
          key={index} 
          data={data}
          particles={layer.trail}
          density={layer.density}
          pointSize={layer.pointSize}
          delay={layer.delay}
          gradientStops={layer.gradient}
          minZoom={7}
        />
      default: // all interpolation layers
        data = layer.data.map(point => { return { latitude: point.lat, longitude: point.lng, value: point.value[0] }; });
        return <InterpolationLayer 
          key={index} 
          data={data} 
          levels={layer.contours} 
          gradientStops={layer.gradient}
          minZoom={7}
        />
    }
  }
}

export { LayerFactory }
