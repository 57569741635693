import * as React from 'react';
import { FeatureCollection } from 'geojson';
import { Layer, MapMouseEvent, Source, useMap } from 'react-map-gl';

import { PolygonUtils } from '../../util/PolygonUtils';
import { POINTS_LAYER, POLYGON_CIRCLE_COLOR, POLYGON_CIRCLE_STROKE_COLOR, POLYGON_LAYER, POLYGON_LINE_COLOR_INVALID, POLYGON_LINE_COLOR_VALID } from '../EditorConfig';
import { IPoint } from '../../types/IPoint';
import { featureCollection, lineString, point } from '@turf/helpers';
import { Guide } from '../../Guide';
import { Mouse } from '@longline/aqua-ui/controls/Mouse';
import { Key } from '@longline/aqua-ui/controls/Key';

interface IProps {
  /** 
   * Fired when polygon builder is cancelled. 
   */
  onCancel: () => void;
  /** 
   * Fired when polygon builder completes. 
   * Returns a set of points that make up a valid polygon.
   */
  onComplete: (points: IPoint[]) => void;
}

const PolygonBuilder = (props: IProps) => {
  const { current: map } = useMap();
  // Polygon points
  const [points, setPoints] = React.useState<IPoint[]>([]);
  // Current point, being manipulated by user
  const [cursor, setCursor] = React.useState<IPoint>({ lat: 0, lng: 0 });

  const handleKeydown = (e: KeyboardEvent) => {
    switch(e.code) {
      case 'Enter':
      case 'NumpadEnter':
        submit();
        break;
      case 'Escape':
        props.onCancel();
        break;
    }
  }  

  const handleMouseMove = (e: MapMouseEvent) => {
    setCursor({ lng: e.lngLat.lng, lat: e.lngLat.lat });
  }

  const handleClickPoint = (e: MapMouseEvent) => {
    e.preventDefault(); // Prevent layerless click (handleClick) from running.
    submit();
  }

  const handleClick = (e: MapMouseEvent) => {
    if(e.defaultPrevented) return; // Don't run if points-layer click runs.
    e.preventDefault();
    const p: IPoint = { lng: e.lngLat.lng, lat: e.lngLat.lat };
    points.push(p);
    setPoints([...points]);
  }

  //
  // Polygons can only be submitted if they have at least 3 points and
  // are valid (i.e. do not self-intersect).
  // 
  const submit = () => {
    if(points.length >= 3 && PolygonUtils.isValid(points)) {
      props.onComplete(points);
    }
  }

  const mount = () => {
    // Start listening to keyboard:
    document.addEventListener('keydown', handleKeydown);         
    map.on('mousemove', handleMouseMove);
    map.on('click', POINTS_LAYER, handleClickPoint);
    map.on('click', handleClick);
    map.getCanvas().style.cursor = 'crosshair';
  }  

  const unmount = () => {
    // Stop listing to keyboard:
    document.removeEventListener('keydown', handleKeydown);      
    map.off('mousemove', handleMouseMove);
    map.off('click', POINTS_LAYER, handleClickPoint);    
    map.off('click', handleClick);
    map.getCanvas().style.cursor = '';
  }

  React.useEffect(() => {
    mount();
    return unmount
  }, []);

  const getPointsJSON = (): FeatureCollection => 
    featureCollection(points.map((p, idx) => point([p.lng, p.lat], {}, { id: idx })));

  const getLinesJSON = (): FeatureCollection => {
    if(points.length == 0) return null;
    return featureCollection([lineString([...points.map(p => [p.lng, p.lat]), [cursor.lng, cursor.lat]])]);
  }

  return (
    <>
      {points.length == 0 && <Guide x={-64} y={-48} title="Adding a polygon structure">
        To add a polygon structure, click <Mouse size={14} left/> the map where the polygon's first vertex must be. <Key value="ESC"/> cancels.
      </Guide>}
      {points.length > 0 && <Guide x={-64} y={-48} title="Adding a polygon structure">
        <ul>
          <li>To add points, click <Mouse size={14} left/> the map once for each vertex. A polygon cannot self-intersect. </li>
          <li>Click <Mouse size={14} left/> the first vertex once again to close the polygon.</li>
        </ul>
        <Key value="ESC"/> cancels.
      </Guide>}
      <Source type="geojson" data={getPointsJSON()}>
        <Layer 
          id={POINTS_LAYER}
          type="circle"
          paint={{
            "circle-color": POLYGON_CIRCLE_COLOR,
            "circle-stroke-color": POLYGON_CIRCLE_STROKE_COLOR,
            "circle-radius": 3,
            "circle-stroke-width": 2
          }}
        />      
      </Source>
      <Source type="geojson" data={getLinesJSON()}>
        <Layer 
          id={POLYGON_LAYER}
          type='line'
          paint={{
            "line-color": PolygonUtils.isValid(points) ? POLYGON_LINE_COLOR_VALID : POLYGON_LINE_COLOR_INVALID,
            "line-width": 1.5,
            "line-dasharray": [ 2, 1 ]
          }}
        />      
      </Source>      
    </>
  );
}

export { PolygonBuilder }
