import * as React from 'react';
import styled from 'styled-components';
import { ILayer } from '../../types/ILayer';
import { LayerUtils } from '../../util/LayerUtils';

interface IProps {
  /** @ignore */
  className?: string;
  /** Layer to show */
  layer: ILayer;
}

const LayerCardBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Type>{LayerUtils.layerTypeToDefinition(props.layer.type).name}</Type>
      <Source>{props.layer.source}</Source>
    </div>
  )
}

const Type = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
`

const Source = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  font-size: 80%;
`

const LayerCardStyled = styled(LayerCardBase)`
  display: flex;
  flex-direction: column;
`

const LayerCard = (props: IProps) => <LayerCardStyled {...props}/>

export { LayerCard }
