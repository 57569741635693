import * as React from 'react';
import styled from 'styled-components';

import { TertiaryButton } from '@longline/aqua-ui/controls/TertiaryButton';
import { InfoBox } from '@longline/aqua-ui/containers/InfoBox';
import { TabBar } from '@longline/aqua-ui/controls/TabBar';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { AreaInfoBoxHeader } from './AreaInfoBoxHeader';
import { IArea } from '../../types/IArea';
import { ELayerType } from '../../types/ELayerType';
import { AreaTab } from './AreaTab';
import { MonthsTab } from './MonthsTab';
import { ChartTab } from './ChartTab';

interface IProps {
  value?: IArea;
}

const AreaInfoBox = (props: IProps) => {
  const globalState = useGlobalState();
  const [mode, setMode] = React.useState<'area'|'months'|'chart'>('area');
  const [months, setMonths] = React.useState<string[]>(["1","2","3","4","5","6","7","8","9","10","11","12"]);

  const canExport = () => {
    if(globalState.organix != null) return false;
    if(!globalState.layers.find(l => l.type == ELayerType.Depth)) return false;
    if(!globalState.layers.find(l => l.type == ELayerType.CurrentSpeed)) return false;
    return true;
  }

  const handleChangeTab = (idx: number) => {
    if(idx == 0) setMode('area');
    if(idx == 1) setMode('months');
    if(idx == 2) setMode('chart');
  }

  return (
    <InfoBox 
      header={<AreaInfoBoxHeader value={props.value}/>}
      width={300}
      footer={<>
        <TertiaryButton onClick={() => globalState.focusArea(props.value)}>FOCUS</TertiaryButton>
        <TertiaryButton disabled={!canExport()} onClick={() => globalState.startOrganix(props.value)}>{globalState.organix != null ? "EXPORTING" : "EXPORT"}</TertiaryButton>
      </>}
    >
      <Dark>
        <TabBar onChange={handleChangeTab}>
          <TabBar.Tab>Area</TabBar.Tab>
          <TabBar.Tab>Months</TabBar.Tab>
          <TabBar.Tab>Chart</TabBar.Tab>
        </TabBar>
      </Dark>

      <InfoBoxScroller>
        {mode == 'area' && <AreaTab value={props.value}/>}
        {mode == 'months' && <MonthsTab months={months} onChange={setMonths}/>}
        {mode == 'chart' && <ChartTab months={months}/>}
      </InfoBoxScroller>
    </InfoBox>
  )
}

const InfoBoxScroller = styled.div`
  padding: 8px 16px 8px 16px;
  height: 440px;
  overflow-y: auto;
`

const Dark = styled.div`
  background: ${p => p.theme.colors.primary[5]};
`


export { AreaInfoBox }
