import * as React from 'react';

import { ListView } from '@longline/aqua-ui/controls/ListView/ListView';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { PaneHeader } from '../PaneHeader';
import { ISample } from '../../types/ISample';
import { SampleInfoBox } from '../../infoboxes/SampleInfoBox';
import { Number } from '@longline/aqua-ui/formatters/Number';
import { Pane } from '../Pane';

const Samples: ISample[] = [
  { name: "Antigonish Harbour", path: "AntigonishHarbour.fins", size: 4629 },
  { name: "Liverpool Bay", path: "LiverpoolBay.fins", size: 3284 },
  { name: "Lobster Bay", path: "LobsterBay.fins", size: 6064 },
  { name: "Mahone - St Margaret's Bay", path: "Mahone.fins", size: 7726 },
  { name: "Port Mouton", path: "PortMouton.fins", size: 738 },
  { name: "Saddle Island", path: "SaddleIsland.fins", size: 4348 },
  { name: "Shelburne Harbour", path: "ShelburneHarbour.fins", size: 2630 },
  { name: "Sober Island", path: "SoberIsland.fins", size: 447 },
  { name: "Whitehead Harbour", path: "WhiteheadHarbour.fins", size: 1476 },
  { name: "Wine Harbour", path: "WineHarbour.fins", size: 732 }
];

const SamplePane = () => {
  const globalState = useGlobalState();

  return (
    <Pane>
      {/* Title & Add structure buttons */}
      <PaneHeader title="FINS Systems" onToggle={globalState.togglePaneExpansion} expanded={globalState.expanded}>
      </PaneHeader>
      {/* Samples list */}
      <ListView 
        dark 
        data={Samples} 
        active={globalState.selectedSample}
        onClick={(sample) => globalState.setSelectedSample(sample)}
        shadow
        aux={<SampleInfoBox/>}
        total
        singular="system"
        plural="systems"
        noData="There are no systems."
        rowHeight={56}
      >
        <ListView.Column active width={3} name="Name">{(item: ISample) => item.name}</ListView.Column>
        <ListView.Column active={globalState.expanded} width="120px" name="Size" align="right">{(item: ISample) => <><Number value={item.size} decimals={0}/> kB</>}</ListView.Column>
      </ListView>
    </Pane>
  )
}

export { SamplePane }