import * as React from 'react';

import { Dialog } from '@longline/aqua-ui/services/Dialog';
import { Dropzone } from '@longline/aqua-ui/controls/Dropzone';
import { PrimaryButton } from '@longline/aqua-ui/controls/PrimaryButton';

import { SVG } from '@longline/aqua-ui/svg';
import { IProject } from '../../types/IProject';
import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { IStructure } from '../../types/IStructure';
import { IDataPoint } from '../../types/IDataPoint';
import { LayerUtils } from '../../util/LayerUtils';
import { IReference } from '../../types/IReference';

type TPhase = 'upload' | 'error';

interface IProps {
  /** ignore */
  className?: string;
  /** Fired when import is cancelled. */
  onCancel: () => void;
  /** Fired when import is complete. */
  onImport: () => void;
}

const LoadProjectDialog = (props: IProps) => {
  const globalState = useGlobalState();
  const [phase, setPhase] = React.useState<TPhase>('upload'); // Operating mode
  const [error, setError] = React.useState(""); // Current error

  const handleCancelImport = () => {
    props.onCancel();
  }

  const handleAddFiles = (files: File[]) => {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onerror = () => {
      setPhase('error');
      setError('The file could not be read.');
    }
    reader.onload = () => {
      try {
        const json = JSON.parse(reader.result.toString());
        if(json.version != "v2") globalState.loadProject(json);
        props.onImport();
      }
      catch(e) {
        setPhase('error');
        setError(e.message);
      }
    }
  }
  
  const renderUpload = () => {
    return (
      <Dialog inverted width={800} open={true} onClose={handleCancelImport}>
        <Dialog.Header>
          Load FINS Project
        </Dialog.Header>
        <Dialog.Content>
          <div> {/* <div> required to avoid Chrome horizontal scrollbar */}
            <Dropzone onAddFiles={handleAddFiles} accept=".fins"/>
          </div>
        </Dialog.Content>
        <Dialog.Footer>
          <PrimaryButton icon={SVG.Icons.Cross} onClick={handleCancelImport}>Close</PrimaryButton>
        </Dialog.Footer>
      </Dialog>      
    );
  }

  const renderError = () => {
    return (
      <Dialog width={800} open={true} onClose={handleCancelImport}>
        <Dialog.Header>
          Data import error
        </Dialog.Header>
        <Dialog.Content>
          An error occurred importing the data. The error was:
          {error}
        </Dialog.Content>
        <Dialog.Footer>
          <PrimaryButton onClick={handleCancelImport}>Cancel</PrimaryButton>
        </Dialog.Footer>
      </Dialog>     
    );
  }

  switch(phase) {
    case 'error': return renderError();
    default: return renderUpload();
  }
}

export { LoadProjectDialog }
