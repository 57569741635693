import * as React from 'react';
import styled, { css } from 'styled-components'

import { Dropdown } from '@longline/aqua-ui/inputs/Dropdown';

import { TCellValue } from '../../util/TCellValue';
import { ExcelConstants } from './ExcelConstants';
import { Number } from '@longline/aqua-ui/formatters/Number';
import { ListView } from '@longline/aqua-ui/controls/ListView/ListView';

interface IProps {
  /** @ignore */
  children?: React.ReactNode;
  /** Number of rows to grey out (from the top): */
  skiprows: number;
  /** Data to display. */
  data: TCellValue[][];
  /** Total rows in worksheet */
  rowCount: number;
  /** Is data currently loading? */
  loading: boolean;
  /** Longitude column number (0-based) */
  longitude: number;
  /** Latitude column number (0-based) */
  latitude: number;
  /** Depth column number (0-based) */
  depth: number;
  /** Eastward velocity column number (0-based) */
  eastspeed: number;
  /** Northward velocity column number (0-based) */
  northspeed: number;
  /** Yearly POC column number (0-based) */
  yearly_poc: number;
  /** Daily POC column number (0-based) */
  daily_poc: number;
  /** Dissolved oxygen column number (0-based) */
  oxygen: number;
  /** NH4 concentration column number (0-based) */
  ammonia: number;
  /** Chlorophyll column number (0-based) */
  chlorophyll: number;
  /** Pathongen column number (0-based) */
  pathogen: number;
  
  pocInSediment: number;
  pomInSediment: number;
  bacteriaInSediment: number;
  oxygenInSediment: number;
  sulphateInSediment: number;
  ironHydroxideInSediment: number;
  totalSulphideInSediment: number;
  ironInSediment: number;
  ironSulphideInSediment: number;

  /** Called when a data source is assigned to a column. */
  onSetColumn: (idx: number, type: string) => void;
}

/**
 * ExcelTable displays table content with Excel-like styling.
 */

const ExcelTable = (props: IProps) => {

  const formatValue = (value: TCellValue): any => {
    if(value == null) return null;
    switch(typeof(value)) {
      case 'number':
        return <Number value={value} decimals={4}/>;
      case "string":
        return <span title={value}>{value}</span>;
      case "object":
        return value;
      case "boolean":
        return value;
      default:
        return "other";
    }
  }

  const getDropValue = (idx: number) => {
    switch(idx) {
      case props.longitude: return ExcelConstants.STR_LONGITUDE;
      case props.latitude: return ExcelConstants.STR_LATITUDE;
      case props.depth: return ExcelConstants.STR_DEPTH;
      case props.eastspeed: return ExcelConstants.STR_U;
      case props.northspeed: return ExcelConstants.STR_V;
      case props.daily_poc: return ExcelConstants.STR_DAILY_POC;
      case props.yearly_poc: return ExcelConstants.STR_YEARLY_POC;
      case props.oxygen: return ExcelConstants.STR_OXYGEN;
      case props.ammonia: return ExcelConstants.STR_AMMONIA;
      case props.chlorophyll: return ExcelConstants.STR_CHLOROPHYLL;
      case props.pathogen: return ExcelConstants.STR_PATHOGEN;
      case props.pocInSediment: return ExcelConstants.STR_POC_IN_SEDIMENT;
      case props.pomInSediment: return ExcelConstants.STR_POM_IN_SEDIMENT
      case props.bacteriaInSediment: return ExcelConstants.STR_BACTERIA_SEDIMENT;
      case props.oxygenInSediment: return ExcelConstants.STR_OXYGEN_IN_SEDIMENT
      case props.sulphateInSediment: return ExcelConstants.STR_SULPHATE_IN_SEDIMENT
      case props.ironHydroxideInSediment: return ExcelConstants.STR_IRON_HYDROXIDE_IN_SEDIMENT
      case props.totalSulphideInSediment: return ExcelConstants.STR_TOTAL_SULPHIDE_IN_SEDIMENT
      case props.ironInSediment: return ExcelConstants.STR_IRON_IN_SEDIMENT
      case props.ironSulphideInSediment: return ExcelConstants.STR_IRON_SULPHIDE_IN_SEDIMENT;
      default: return null;
    }
  }

  const getSelectedColumns = () => {
    return [props.longitude, props.latitude, props.depth, props.eastspeed, props.northspeed,
            props.yearly_poc, props.daily_poc, props.oxygen, props.ammonia, props.chlorophyll ].filter(n => n != null);
  }

  const getDropdown = (index: number) => {
    const options = [
      ExcelConstants.STR_LONGITUDE, 
      ExcelConstants.STR_LATITUDE,
      ExcelConstants.STR_DEPTH, 
      ExcelConstants.STR_U, 
      ExcelConstants.STR_V,
      ExcelConstants.STR_DAILY_POC, 
      ExcelConstants.STR_YEARLY_POC, 
      ExcelConstants.STR_OXYGEN, 
      ExcelConstants.STR_AMMONIA,
      ExcelConstants.STR_CHLOROPHYLL, 
      ExcelConstants.STR_PATHOGEN, 
      ExcelConstants.STR_CHLOROPHYLL,
      ExcelConstants.STR_PATHOGEN,
      ExcelConstants.STR_POC_IN_SEDIMENT,
      ExcelConstants.STR_POM_IN_SEDIMENT,
      ExcelConstants.STR_BACTERIA_SEDIMENT,
      ExcelConstants.STR_OXYGEN_IN_SEDIMENT,
      ExcelConstants.STR_SULPHATE_IN_SEDIMENT,
      ExcelConstants.STR_IRON_HYDROXIDE_IN_SEDIMENT,
      ExcelConstants.STR_TOTAL_SULPHIDE_IN_SEDIMENT,
      ExcelConstants.STR_IRON_IN_SEDIMENT,
      ExcelConstants.STR_IRON_SULPHIDE_IN_SEDIMENT
    ];

    return (
      <DropdownWrapper key={index} $cols={props.data[0].length}>
        <Dropdown 
          clearable 
          fluid 
          minBodyWidth={200}
          placeholder="Select data type" 
          data={options} 
          label={(item) => item}
          value={getDropValue(index)}
          onChange={(value: string) => props.onSetColumn(index, value)}
        >
          <Dropdown.Column>{(item) => item}</Dropdown.Column>
        </Dropdown>
      </DropdownWrapper>
    );
  }

  // If data is not available, render a notice.
  if(props.loading == true || props.data.length == 0) {
    return (
      <TableWrapper key="ghosted">
        <ListView grid ghost dark shadow data={props.data}>
          {[0,1,2,3,4].map((_, index) => 
            <ListView.Column key={index} active name={index.toString()}>{(item) => ""}</ListView.Column>
          )}
        </ListView>      
        {props.data.length == 0 && <NoData>
          This worksheet contains no data.
        </NoData>}
      </TableWrapper>
    );
  }  

  return (
    <>
      <DropdownRow>
        {props.data[0].map((d, index) => getDropdown(index))}
      </DropdownRow>
      <TableWrapper $skiprows={props.skiprows} $selected={[...getSelectedColumns()]} key="realthing">
        <ListView grid dark noheader data={props.data}>
          {props.data[0].map((d, index) => 
            <ListView.Column key={index} active name={index.toString()}>
              {(item) => item[index] == null ? null : formatValue(item[index])}
            </ListView.Column>
          )}
        </ListView>
      </TableWrapper>
      <Counter>
        {props.data.length == 0 ? "(no data)" : <>Top 20 rows of <Number value={props.rowCount} decimals={0}/></>}
      </Counter>           
    </>
  );
}

const DropdownRow = styled.div`
  display: flex;
  background: ${p => p.theme.colors.neutral[10]};
  height: 48px;
  align-items: center;
`

const DropdownWrapper = styled.div<{ $cols: number }>`
  flex: 1;
  box-sizing: border-box;
  padding-left: 4px;
  padding-right: 4px;
  max-width: ${p => `${(100 / p.$cols)}%`};
`

const TableWrapper = styled.div<{ $skiprows?: number, $selected?: number[] }>`
  flex: 1;
  border-bottom: solid 1px #333;
  tr:nth-child(-n+${p => p.$skiprows}) {
    td {
      background-color: ${p => p.theme.colors.primary[4]};
      color: ${p => p.theme.colors.primary[2]};
    }
  }

  ${p => p.$selected && p.$selected.map(n => css`
    td:nth-child(${n+1}) {
      background-color: ${p.theme.colors.primary[1]};
    }
  `)}
`

const NoData = styled.div`
  position: absolute;
  z-index: 10;
  top: 100px;
  left: 50%;
  translate: -50% 0;
  display: flex;
  align-items: center;
  background: ${p => p.theme.colors.primary[2]};
  height: 28px;
  border-radius: 14px;
  padding-left: 12px;
  padding-right: 12px;
  margin: 20px;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
`

const Counter = styled.div`
  position: absolute;
  z-index: 10;
  right: 8px;
  bottom: 128px;
  background: ${p => p.theme.colors.primary[2]};
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 80%;
  box-shadow: 1px 1px 1px rgba(0,0,0,0.3);
`

export { ExcelTable }