import * as React from 'react';
import { Layer, Source } from 'react-map-gl/mapbox';

import { IReference } from '../types/IReference';

interface IProps {
  reference: IReference;
}

const ReferenceLayer = (props: IProps) => {
  // Determine min and max value of selected property.
  const minVal = React.useMemo(() => {
    if(!props.reference.property) return 0;
    return Math.min(...props.reference.geojson.features.map(f => f.properties[props.reference.property]));
  }, [props.reference.property]);

  const maxVal = React.useMemo(() => {
    if(!props.reference.property) return 0;
    return Math.max(...props.reference.geojson.features.map(f => f.properties[props.reference.property]));
  }, [props.reference.property]);

  const fillColor = props.reference.gradient.map(stop => {
    const range = maxVal - minVal;
    // Note: Mapbox can't handle colors of shape "#rrggbbaa", only "#rrggbb".
    return [ stop.pos * range + minVal, stop.color.substring(0, 7) ];
  }).flat();

  return (
    <Source type="geojson" data={props.reference.geojson}>
      <Layer 
        type="fill"
        paint={{
          "fill-color": props.reference.property ? [
            'interpolate',
            ['linear'],
            ['get', props.reference.property],
            ...fillColor
          ] : '#000000',
          "fill-opacity": props.reference.opacity / 100,
        }}
      />
      <Layer 
        type="line"
        paint={{
          "line-color": "#ffffff",
          "line-opacity": props.reference.opacity / 100,
          "line-width": 1,
        }}
      />
    </Source>
  )
}

export { ReferenceLayer }
