import React from "react";

/**
 * Back-end configuration: API and Auth URLs, and mapbox key.
 */
interface IConfig {
  /** Mapbox key. */
  mapboxKey: string;
  /** Mapbox style */
  mapboxStyle: string;
}

const ConfigContext = React.createContext<IConfig>(null); 

const useConfig = () => {
  return React.useContext(ConfigContext);
}

export { IConfig, ConfigContext, useConfig }
