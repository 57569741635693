import * as React from 'react';
import { Map } from 'mapbox-gl';

import { ILayer } from '../../types/ILayer';
import { IStructure } from '../../types/IStructure';
import { IPoint } from '../../types/IPoint';
import { IReference } from '../../types/IReference';
import { IProject } from '../../types/IProject';
import { IRect } from '../../types/IRect';
import { IArea } from '../../types/IArea';
import { ISample } from '../../types/ISample';

type TMode = 
    'project'         // Project pane open
  | 'layers'          // Layers pane open
  | 'structures'      // Structures pane open
  | 'references'      // References pane open
  | 'areas'           // Areas pane open
  | 'samples'         // Samples pane open
  | 'info'            // Info dialog open  
  | 'circle-builder'  // Circle builder
  | 'polygon-builder' // Polygon builder
  | 'area-builder'    // Area builder
  | 'area-editor';    // Area editor

interface IGlobalState {
  mode: TMode,
  setMode: (mode: TMode) => void;
  getMap: () => Map;
  setMap: (map: Map) => void;
  expanded: boolean,
  setPaneExpansion: (epxanded: boolean) => void;
  togglePaneExpansion: () => void;
  guide: boolean;
  setGuide: (guide: boolean) => void;

  // Project
  project: IProject;
  setProject: (project: IProject) => void;
  startLoadProject: () => void;
  loadProject: (json: any) => void;
  clearProject: () => void;
  saveProject: () => void;

  // Layers
  layers: ILayer[];
  addLayers: (newLayers: ILayer[], clear?: boolean) => void;
  startImport: () => void;
  toggleLayer: (layer: ILayer) => void;
  activeLayer: ILayer,
  setActiveLayer: (layer: ILayer) => void;
  deleteLayer: (layer: ILayer) => void;
  focusLayer: (layer: ILayer) => void;
  updateLayer: (layer: ILayer) => void;
  clearLayers: () => void;

  // Structures:
  structures: IStructure[];
  setStructures: (structures: IStructure[]) => void;
  selectedStructure: IStructure,
  clearStructures: () => void;
  setSelectedStructure: (structure: IStructure) => void;
  addStructure: (structure: IStructure) => void;
  addStructureCircle: (point: IPoint, radius: number) => void;
  updateSelectedStructureCircle: (point: IPoint, radius: number) => void;
  addStructurePolygon: (points: IPoint[]) => void;
  updateSelectedStructurePolygon: (points: IPoint[]) => void;
  deleteSelectedStructure: () => void;
  focusStructure: (structure: IStructure) => void;
  startEditStructure: () => void;

  // References:
  references: IReference[];
  setReferences: (references: IReference[]) => void;
  clearReferences: () => void;
  startReferenceImport: () => void;
  selectedReference: IReference;
  setSelectedReference: (reference: IReference) => void;
  toggleReference: (reference: IReference) => void;
  updateReference: (reference: IReference) => void;
  deleteReference: (reference: IReference) => void;
  focusReference: (reference: IReference) => void;

  // Areas
  areas: IArea[];
  setAreas: (areas: IArea[]) => void;
  selectedArea: IArea;
  setSelectedArea: (area: IArea) => void;
  deleteArea: (area: IArea) => void;
  clearAreas: () => void;
  addArea: (rect: IRect) => void;
  updateSelectedArea: (newRect: IRect) => void;
  updateArea: (area: IArea) => void;
  focusArea: (area: IArea) => void;
  startOrganix: (area: IArea) => void;
  stopOrganix: () => void;
  organix: IArea;

  // Samples:
  selectedSample: ISample;
  setSelectedSample: (sample: ISample) => void;
}

const GlobalStateContext = React.createContext<IGlobalState>(null);

const useGlobalState = () => {
  return React.useContext(GlobalStateContext);
}

export { IGlobalState, GlobalStateContext, useGlobalState, TMode }
