import * as React from 'react';
import { LngLatBounds, Map } from 'mapbox-gl';
import bbox from '@turf/bbox';

import { GlobalStateContext, TMode } from './GlobalStateContext';
import { ILayer } from '../../types/ILayer';
import { ExcelImport } from '../../dialogs/ExcelImport';
import { LayerUtils } from '../../util/LayerUtils';
import { IStructure } from '../../types/IStructure';
import { IPoint } from '../../types/IPoint';
import { PolygonUtils } from '../../util/PolygonUtils';
import { EditStructureDialog } from '../../dialogs/EditStructure/EditStructureDialog';
import { DefaultReference, IReference } from '../../types/IReference';
import { ReferenceImport } from '../../dialogs/ReferenceImport/ReferenceImport';
import { DefaultProject, IProject } from '../../types/IProject';
import { LoadProjectDialog } from '../../dialogs/LoadProject/LoadProjectDialog';
import { IDataPoint } from '../../types/IDataPoint';
import { IRect } from '../../types/IRect';
import { IArea } from '../../types/IArea';
import { IFinsFile } from '../../types/IFinsFile';
import { saveAs } from 'file-saver';
import { ISample } from '../../types/ISample';

interface IProps {
  children?: React.ReactNode;
}

const GlobalState = (props: IProps) => {
  const map = React.useRef<Map>(null);
  const [mode, setMode] = React.useState<TMode>(null);
  const [expanded, setExpanded] = React.useState(false);
  const [guide, setGuide] = React.useState(true);

  // Project
  const [project, setProject] = React.useState<IProject>(DefaultProject);
  const [loadingProject, setLoadingProject] = React.useState(false);

  // Layers
  const [importingLayer, setImportingLayer] = React.useState(false);
  const [layers, setLayers] = React.useState<ILayer[]>([]);
  const [activeLayer, setActiveLayer] = React.useState<ILayer>(null);

  // Structures
  const [showStructures, setShowStructures] = React.useState(true);
  const [structures, setStructures] = React.useState<IStructure[]>([]);
  const [selectedStructure, setSelectedStructure] = React.useState<IStructure>(null);
  const [editingStructure, setEditingStructure] = React.useState<IStructure>(null);

  // References
  const [referenceImporting, setReferenceImporting] = React.useState(false);
  const [references, setReferences] = React.useState<IReference[]>([]);
  const [selectedReference, setSelectedReference] = React.useState<IReference>(null);

  // Areas
  const [areas, setAreas] = React.useState<IArea[]>([]);
  const [selectedArea, setSelectedArea] = React.useState<IArea>(null);
  const [organix, setOrganix] = React.useState<IArea>(null);

  // Sample
  const [selectedSample, setSelectedSample] = React.useState<ISample>(null);

  const setMap = (_map: Map) => {
    map.current = _map;
  }

  const getMap = () => map.current;

  const startImport = () => {
    setImportingLayer(true);
  }

  const handleImport = (layers: ILayer[]) => {
    setImportingLayer(false);
    addLayers(layers);
  }

  const addLayers = (newLayers: ILayer[], clear?: boolean) => {
    setLayers(sortLayers([...(clear ? [] : layers), ...newLayers ]))
    // Focus on first new layer.
    if(newLayers.length > 0) focusLayer(newLayers[0]);       
  }

  const toggleLayer = (layer: ILayer) => {
    // Toggle argument layer's visibility:
    layer.visible = !layer.visible;
    // Make a new array of layers, so that components will update.
    setLayers(sortLayers([ ...layers.filter(a => a != layer), layer]));
  }

  const deleteLayer = (layer: ILayer) => {
    // Remove layer from array, creating new array so that components will update.
    setLayers([ ...layers.filter(a => a != layer)]);
  }

  const focusLayer = (layer: ILayer) => {
    const minLng = Math.min(...layer.data.map(p => p.lng));
    const maxLng = Math.max(...layer.data.map(p => p.lng));
    const minLat = Math.min(...layer.data.map(p => p.lat));
    const maxLat = Math.max(...layer.data.map(p => p.lat));
    map.current.fitBounds([
      [ minLng, minLat ],
      [ maxLng, maxLat ]
    ]);
  }
  
  // Update a single layer.
  const updateLayer = (layer: ILayer) => {
    // Update by creating new array of layers so that components update.
    setLayers(sortLayers([ ...layers.filter(a => a != layer), layer]));
  }

  const sortLayers = (layers: ILayer[]): ILayer[] => {
    return layers.sort((a, b) => {
      const aName = `${LayerUtils.layerTypeToDefinition(a.type).name} (${a.source})`;
      const bName = `${LayerUtils.layerTypeToDefinition(b.type).name} (${b.source})`;
      return aName.localeCompare(bName);
    });
  }

  const clearLayers = () => {
    setActiveLayer(null);
    setLayers([]);
  }

  const setPaneExpansion = (_expanded: boolean) => setExpanded(_expanded);

  const togglePaneExpansion = () => setExpanded(!expanded);

  const clearStructures = () => {
    setSelectedStructure(null);
    setStructures([]);
  }

  // A trick is used when pasting a structure. The currently-selected
  // structure must first be properly unmounted. The unmounting is 
  // detected using "pastable". After this, the new structure is added
  // and selected.
  const [pastable, setPastable] = React.useState<IStructure>(null);
  React.useEffect(() => {
    if(pastable) {
      pastable.name = `Structure ${structures.length+1}`;
      setStructures([ ...structures, pastable ]);
      setSelectedStructure(pastable);
      setMode(null);
      setPastable(null);
    }
  }, [pastable]);
  const addStructure = (structure: IStructure) => {
    setSelectedStructure(null);
    setPastable(structure);
  }

  const addStructureCircle = (point: IPoint, radius: number) => {
    const structure: IStructure = {
      name: `Structure ${structures.length+1}`,
      type: 'circle',
      point: point,
      radius: radius,
      stocking: project.stocking
    };
    setStructures([ ...structures, structure ]);
    setSelectedStructure(structure);
    setMode(null);
  }

  const updateSelectedStructureCircle = (point: IPoint, radius: number) => {
    selectedStructure.point = point;
    selectedStructure.radius = radius;
    setStructures([...structures]);
  }

  const addStructurePolygon = (points: IPoint[]) => {
    const structure: IStructure = {
      name: `Structure ${structures.length+1}`,
      type: 'polygon',
      points: points,
      stocking: project.stocking
    };
    setStructures([ ...structures, structure ]);
    setSelectedStructure(structure);
    setMode(null);
  }  

  const updateSelectedStructurePolygon = (points: IPoint[]) => {
    selectedStructure.points = points;
    setStructures([...structures]);
  }

  const deleteSelectedStructure = () => {
    setStructures([...structures.filter(s => s != selectedStructure)]);
    setSelectedStructure(null);
  }

  const focusStructure = (structure: IStructure) => {
    if(structure.type == 'circle') {
      // Create bbox for circle, with 3x padding around it:
      const bounds = new LngLatBounds(
        PolygonUtils.addMeters(structure.point, -structure.radius * 3, -structure.radius * 3),
        PolygonUtils.addMeters(structure.point,  structure.radius * 3,  structure.radius * 3)
      );
      map.current.fitBounds(bounds);
    } else {
      // Get bbox for polygon, and fly to it.
      map.current.fitBounds(PolygonUtils.getBbox(structure.points));
    }
  }

  const updateSelectedStructure = (_structure: IStructure) => {
    editingStructure.name = _structure.name;
    editingStructure.radius = _structure.radius;
    editingStructure.stocking = _structure.stocking;
    setStructures([...structures]);
    setSelectedStructure(editingStructure);
    setEditingStructure(null);
  }

  const startEditStructure = () => {
    setEditingStructure(selectedStructure);
    // Disable editors, since they will consume keyboard events:
    setSelectedStructure(null);
  }

  const cancelEditStructure = () => {
    setSelectedStructure(editingStructure);
    setEditingStructure(null);
  }

  // REFERENCES

  const startReferenceImport = () => {
    setReferenceImporting(true);
  }

  const clearReferences = () => {
    setSelectedReference(null);
    setReferences([]);
  }

  const handleReferenceImport = (reference: IReference) => {
    setReferenceImporting(false);
    setReferences([ ...references, reference ]);
    focusReference(reference);
  }

  const updateReference = (reference: IReference) => {
    // Update by creating new array of layers so that components update.
    setReferences([ ...references.filter(a => a != reference), reference]);
  }

  const deleteReference = (reference: IReference) => {
    // Remove reference from array, creating new array so that components will update.
    setReferences([ ...references.filter(a => a != reference)]);
  }

  const focusReference = (reference: IReference) => {
    const [ minLng, minLat, maxLng, maxLat ] = bbox(reference.geojson);
    map.current.fitBounds([
      [ minLng, minLat ],
      [ maxLng, maxLat ]
    ]);
  }

  const toggleReference = (reference: IReference) => {
    // Toggle argument references's visibility:
    reference.visible = !reference.visible;
    // Make a new array of references, so that components will update.
    setReferences([ ...references.filter(a => a != reference), reference]);
  }

  // PROJECT

  const startLoadProject = () => {
    setLoadingProject(true);
  }

  const loadProject = (json: any) => {
    setMode(null);
    setActiveLayer(null);
    setSelectedStructure(null);
    if(json.version && json.version == "2") {
      loadProjectV2(json);
    } else {
      loadProjectV1(json);
    }
  }

  const loadProjectV1 = (json: any) => {
    // Map location to zoom to:
    const latitude = json.Latitude;
    const longitude = json.Longitude;
    const zoom = json.Zoom;

    // Project info
    setProject({
      name: json.FarmName ?? DefaultProject.name,
      species: json.Species ?? DefaultProject.species,
      structureDepth: json.StructureDepth ?? DefaultProject.structureDepth,
      stocking: json.StockingDensity ?? DefaultProject.stocking
    });

    // Layers:
    if(json.LayerProxies) {
      const layers = json.LayerProxies.map((p: any) => {
        const layer = LayerUtils.createLayer(
          p.DataSet.Type, 
          p.DataSet.Name, 
          p.DataSet.Data.map((d: any) => {
            const dp: IDataPoint = {
              lng: d.Longitude,
              lat: d.Latitude,
              value: [ d.U, d.V ],
            };
            return dp;
          })
        );
        layer.visible = p.DataSet.Visible != false;
        return layer;
      });
      addLayers(layers, true); // clear layers first
    }

    // References:
    if(json.ReferenceProxies) {
      setReferences(json.ReferenceProxies.map((p: any) => {
        const reference: IReference = { ...DefaultReference,
          name: p.Name,
          geojson: JSON.parse(p.Json),
          visible: p.Visible
        };
        return reference;
      }));
    }

    // Structures:
    if(json.StructureProxies) {
      setStructures(json.StructureProxies.map((p: any) => { 
        const structure: IStructure = {
          type: 'circle',
          point: { lng: p.Longitude, lat: p.Latitude },
          points: [],
          radius: p.Radius,
          name: p.Name,
          stocking: p.StockingDensity
        };
        return structure;
      }
    ))}

    // Move map:
    getMap().flyTo({
      center: [longitude, latitude],
      zoom: zoom
    });    
  }

  const loadProjectV2 = (json: IFinsFile) => {
    setProject({...json.project});
    setLayers([...json.layers]);
    setStructures([...json.structures]);
    setReferences([...json.references]);
    setAreas([...json.areas]);
    getMap().flyTo({
      center: [json.map.longitude, json.map.latitude],
      zoom: json.map.zoom
    });       
  }

  const saveProject = () => {
    const file: IFinsFile = {
      version: "2",
      map: {
        longitude: map.current.getCenter().lng,
        latitude: map.current.getCenter().lat,
        zoom: map.current.getZoom()
      },
      project,
      layers,
      structures,
      references,
      areas
    }
    
    let blob = new Blob([JSON.stringify(file)], { type: "application/json; chartset=utf-8" });
    const filename = project.name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    saveAs(blob, `${filename}.fins`);
  }

  const clearProject = () => {
    setProject({...DefaultProject});
    setLayers([]);
    setStructures([]);
    setReferences([]);
    setAreas([]);
    setSelectedArea(null);
    setSelectedStructure(null);
    setSelectedReference(null);
    setSelectedSample(null);
  }

  // AREAS

  const sortAreas = (areas: IArea[]): IArea[] => {
    return areas.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });    
  }
    
  const deleteArea = (area: IArea) => {
    // Remove area from array, creating new array so that components will update.
    setAreas([...areas.filter(a => a != area)]);
    setSelectedArea(null);
  }

  const clearAreas = () => {
    setSelectedArea(null);
    setAreas([]);
  }
  
  const addArea = (rect: IRect) => {
    const area: IArea = {
      name: `Area ${areas.length+1}`,
      rect: rect,
      resolution: 100,
      bathymetry: 'positive'
    };
    setAreas(sortAreas([...areas, area]));
    setSelectedArea(area);
    setMode('area-editor');
  }

  const updateSelectedArea = (newRect: IRect) => {
    selectedArea.rect.minLng = newRect.minLng;
    selectedArea.rect.maxLng = newRect.maxLng;
    selectedArea.rect.minLat = newRect.minLat;
    selectedArea.rect.maxLat = newRect.maxLat;
    setAreas(sortAreas([...areas]));
  }

  const updateArea = (area: IArea) => {
    // Update by creating new array of areas so that components update.
    setAreas(sortAreas([ ...areas.filter(a => a != area), area]));
  }

  const focusArea = (area: IArea) => {
    map.current.fitBounds([
      [ area.rect.minLng, area.rect.minLat ],
      [ area.rect.maxLng, area.rect.maxLat ]
    ]);    
  }
  
  const startOrganix = (area: IArea) => {
    setOrganix(area);
  }

  const stopOrganix = () => {
    setOrganix(null);
  }

  return (
    <GlobalStateContext.Provider value={{
      guide,
      setGuide,      
      layers,
      structures,
      setStructures,
      references, setReferences,
      project, setProject, 
      selectedStructure,
      setSelectedStructure,
      selectedReference,
      setSelectedReference,
      setMap,
      getMap,
      startImport,
      startReferenceImport,
      addLayers,
      toggleLayer,
      activeLayer,
      setActiveLayer,
      deleteLayer,
      focusLayer,
      updateLayer,
      clearLayers,
      mode,
      setMode,
      expanded,
      setPaneExpansion,
      togglePaneExpansion,
      showStructures,
      setShowStructures,
      addStructure,
      addStructureCircle,
      updateSelectedStructureCircle,
      addStructurePolygon,
      updateSelectedStructurePolygon,
      deleteSelectedStructure,
      focusStructure,
      startEditStructure,
      clearReferences,
      updateReference,
      deleteReference,
      focusReference,
      toggleReference,
      startLoadProject,
      clearStructures,
      loadProject,
      clearProject,
      saveProject,
      areas,
      setAreas,
      selectedArea,
      setSelectedArea,
      deleteArea,
      clearAreas,
      addArea,
      updateSelectedArea,
      updateArea,
      focusArea,
      startOrganix,
      stopOrganix,
      organix,
      selectedSample, 
      setSelectedSample,
    }}>
      {props.children}
      {importingLayer && <ExcelImport onCancel={() => setImportingLayer(false)} onImport={handleImport} />}
      {referenceImporting && <ReferenceImport onCancel={() => setReferenceImporting(false)} onImport={handleReferenceImport}/>}
      {editingStructure && <EditStructureDialog structure={editingStructure} onCancel={cancelEditStructure} onSubmit={updateSelectedStructure}/>}
      {loadingProject && <LoadProjectDialog onCancel={() => setLoadingProject(false)} onImport={() => setLoadingProject(false)}/>}
      {/* {exportingOrganix && <OrganixExportDialog area={exportingOrganix} onClose={() => setExportingOrganix(null)}/>} */}
    </GlobalStateContext.Provider>
  )
}

export { GlobalState }
