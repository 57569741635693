import * as React from 'react';

import { IMapButtonProps, MapButton } from '@longline/aqua-ui/map/controls/base/MapButton';
import { useGlobalState } from '../contexts/state/GlobalStateContext';
import { Sprites } from '../svg';

const AddCircularStructureButton = (props: IMapButtonProps) => {
  const globalState = useGlobalState();
  return (
    <MapButton 
      active={globalState.mode == 'circle-builder'}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        globalState.setMode('circle-builder');
      }} 
      hint="Add a circular structure"
      {...props}
    >
      <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <use xlinkHref={Sprites.Icons.AddCircle}></use>
      </svg>
    </MapButton>
  );
}

export { AddCircularStructureButton }
