import * as React from 'react';
import { useTheme } from 'styled-components';

import { Dialog } from '@longline/aqua-ui/services/Dialog';
import { PrimaryButton } from '@longline/aqua-ui/controls/PrimaryButton';
import { Form } from '@longline/aqua-ui/containers/Form';
import { Input } from '@longline/aqua-ui/inputs/Input';

import { IStructure } from '../../types/IStructure';
import { SVG } from '@longline/aqua-ui/svg';

interface IProps {
  /** Structure to edit (a local copy is kept in state). */
  structure: IStructure;
  /** Fired when form is submitted with valid data. */
  onSubmit: (structure: IStructure) => void;
  /** Fired when edit is cancelled. */
  onCancel: () => void;
}

const EditStructureDialog = (props: IProps) => {
  const theme = useTheme();
  const structure = React.useRef({...props.structure});
  const [valid, setValid] = React.useState(true);

  const handleValidate = (_valid: boolean) => setValid(_valid);

  const handleChange = (data: IStructure, forceUpdate: boolean) => {
    // Diameter isn't a field in IStructure; convert it to radius.
    if((data as any).diameter) data.radius = (data as any).diameter / 2;
    Object.assign(structure.current, data);
  }

  return (
    <Dialog inverted open={true} canClose onClose={props.onCancel}>
      <Dialog.Header>Edit structure</Dialog.Header>
      <Dialog.Content>
        <Form
          data={structure}
          onChange={handleChange}
          onValidate={handleValidate}
        >
          <Form.Field
            name="name"
            label="Structure name"
            value={structure.current.name}
            hint="Please enter the structure name."
            wrapper='box'
            rules={{
              required: {}
            }}
            control={<Input/>}
          />
          <Form.Field
            name="stocking"
            label="Stocking"
            value={structure.current.stocking}
            hint="Please enter the number of animals contained in this structure."
            wrapper='box'
            rules={{
              required: {},
              isInt: {},
              min: { value: 0 },
              max: { value: 10000000 }
            }}
            control={<Input unit="animals"/>}
          />          
          {structure.current.type == 'circle' && <Form.Field
            name="diameter"
            label="Structure diameter"
            value={(structure.current.radius * 2).toFixed(2)}
            hint="Please set the structure diameter."
            wrapper='box'
            rules={{
              required: {},
              isFloat: {},
              min: { value: 1 },
              max: { value: 40000000 }
            }}
            control={<Input unit="m"/>}
          />}
        </Form>
      </Dialog.Content>
      <Dialog.Footer align="space-between">
        <PrimaryButton icon={SVG.Icons.Cross} color={theme.colors.primary[4]} onClick={props.onCancel}>Cancel</PrimaryButton>
        <PrimaryButton icon={SVG.Icons.Check} disabled={!valid} onClick={() => props.onSubmit(structure.current)}>Update structure</PrimaryButton>
      </Dialog.Footer>

    </Dialog>
  )
}

export { EditStructureDialog }


