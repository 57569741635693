/** @module @ignore */
import * as React from 'react';
import { Layer, MapMouseEvent, Source, useMap } from 'react-map-gl';
import { Feature, FeatureCollection, Polygon } from 'geojson';

import { IStructure } from '../types/IStructure';
import { PolygonUtils } from '../util/PolygonUtils';
import { useGlobalState } from '../contexts/state/GlobalStateContext';
import { polygon } from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';

const StructuresLayer = () => {
  const globalState = useGlobalState();
  const { current: map } = useMap();

  const structureToPolygon = (structure: IStructure): Feature<Polygon> => {
    if(structure.type == 'polygon') {
      return polygon([[...structure.points.map(p => [ p.lng, p.lat]), [structure.points[0].lng, structure.points[0].lat]]]);
    } else { // circle
      return PolygonUtils.getCirclePolygon(structure.point, structure.radius);
    }
  }

  const getJSON = React.useMemo((): FeatureCollection => {
    // Calculate a polygon feature for each structure.
    const polys = globalState.structures.map(s => structureToPolygon(s));

    return {
      type: 'FeatureCollection',
      features: globalState.structures.map((structure, idx) => { 
        
        // Do I intersect with any other polygon (excluding myself)?
        const intersects = globalState.structures
          .map((_, n) => idx == n ? false : booleanIntersects(polys[idx], polys[n]))
          .reduce((acc, val) => acc || val, false);

        return { 
          id: idx,
          type: 'Feature',
          properties: { intersects },
          geometry: {
            type: "Polygon",
            // Don't render currently selected polygon,
            // by giving it no coordinates.
            coordinates: structure == globalState.selectedStructure ? [] : polys[idx].geometry.coordinates
          }
        }
      })
    }
  }, [globalState.structures, globalState.selectedStructure]);

  const handleClick = (e: MapMouseEvent) => {
    if(!e.features) return;
    if(e.features.length == 0) return;
    const feature = e.features[0];
    if(feature.layer.id != 'structures') return;
    // Use a timeout to ensure polygon selection comes after deselection:
    setTimeout(() => {
      globalState.setSelectedStructure(globalState.structures[feature.id as number]) 
      globalState.setMode(null);
    }, 0);
  }

  React.useEffect(() => {
    map.on("click", "structures", handleClick);
    return () => {
      map.off("click", "structures", handleClick);
    }
  }, [globalState.structures, globalState.selectedStructure]);

  return (
    <Source generateId type="geojson" data={getJSON}>
      <Layer 
        id="structures" 
        type="fill"
        paint={{
          'fill-color': [
            'case',
            ['get', 'intersects'],
            'red',
            'lightblue'
            ],      
          'fill-opacity': 0.5
        }}
        />
      <Layer 
        type="line"
        paint={{
          'line-color': 'black',
          'line-width': 1
        }}
        />
  </Source>    
  );
}

export { StructuresLayer }
