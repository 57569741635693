import * as React from 'react';
import styled from 'styled-components';

import { MainMenu } from '@longline/aqua-ui/modules/MainMenu';

import { Sprites } from './svg';
import { useGlobalState } from './contexts/state/GlobalStateContext';
import { SVG } from '@longline/aqua-ui/svg';

interface IProps {
  /** @ignore */
  className?: string;
}

const NavBase = (props: IProps) => {
  const globalState = useGlobalState();

  return (
    <div className={props.className}>
      <Logo>
        <use xlinkHref={Sprites.Logos.LogoSymbolWhite}></use>
      </Logo>
      <MainMenuHolder>
        <MainMenu>
          <MainMenu.Item icon={Sprites.Icons.Project} active={globalState.mode=='project'} onClick={() => globalState.setMode('project')}>Project</MainMenu.Item>
          <MainMenu.Item icon={Sprites.Icons.Layers} active={globalState.mode=='layers'} onClick={() => globalState.setMode('layers')}>Layers</MainMenu.Item>
          <MainMenu.Item icon={Sprites.Icons.Structures} active={globalState.mode=='structures'} onClick={() => globalState.setMode('structures')}>Structures</MainMenu.Item>
          <MainMenu.Item icon={Sprites.Icons.GeoLayers} active={globalState.mode=='references'} onClick={() => globalState.setMode('references')}>References</MainMenu.Item>
          <MainMenu.Item icon={Sprites.Icons.Grid} active={globalState.mode=='areas'} onClick={() => globalState.setMode('areas')}>Export</MainMenu.Item>
          <MainMenu.Item secondary active={globalState.mode=='samples'} icon={Sprites.Icons.SampleSystems} onClick={() => globalState.setMode('samples')}>Systems</MainMenu.Item>
          <MainMenu.Item secondary active={globalState.mode=='info'} icon={SVG.Icons.Info} onClick={() => globalState.setMode('info')}>Information</MainMenu.Item>
        </MainMenu>
      </MainMenuHolder>
    </div>
  )
}

const Logo = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  background: #457298;
  padding: 16px;
  box-sizing: border-box;
`

const MainMenuHolder = styled.div`
  position: absolute;
  left: 0;
  top: 80px;
  width: 80px;
  bottom: 0;
`

const NavStyled = styled(NavBase)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 80px;
  z-index: 200; // Stay above Map.
`

const Nav = (props: IProps) => <NavStyled {...props}/>

export { Nav }
