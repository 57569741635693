import * as React from 'react';
import styled from 'styled-components';

import { Form } from '@longline/aqua-ui/containers/Form';
import { Input } from '@longline/aqua-ui/inputs/Input';
import { Validation } from '@longline/aqua-ui/containers/Form/Validation';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { PaneHeader } from '../PaneHeader';
import { Pane } from '../Pane';
import { PrimaryButton } from '@longline/aqua-ui/controls/PrimaryButton';
import { SecondaryButton } from '@longline/aqua-ui/controls/SecondaryButton';
import { SVG } from '@longline/aqua-ui/svg';
import { useConfirm } from '../../contexts/confirm/ConfirmContext';
import { useToast } from '@longline/aqua-ui/services/Toast/useToast';
import { Guide } from '../../Guide';

const ProjectPane = () => {
  const confirm = useConfirm();
  const globalState = useGlobalState();
  const toastService = useToast();

  const handleChangeName = (value: string) => {
    const validation = Validation.getValidation(value, { required: {} });
    if(validation !== null) return;
    globalState.setProject({...globalState.project, name: value});
  }
  
  const handleChangeSpecies = (value: string) => {
    const validation = Validation.getValidation(value, { required: {} });
    if(validation !== null) return;
    globalState.setProject({...globalState.project, species: value});
  }

  const handleChangeStructureDepth = (value: string) => {
    const validation = Validation.getValidation(value, { required: {}, isFloat: {}, min: { value: 0 }, max: { value: 1000 } });
    if(validation !== null) return;
    globalState.setProject({...globalState.project, structureDepth: parseFloat(value)});
  }

  const handleChangeStocking = (value: string) => {
    const validation = Validation.getValidation(value, { required: {}, isInt: {}, min: { value: 0 }, max: { value: 10000000 } });
    if(validation !== null) return;
    globalState.setProject({...globalState.project, stocking: parseInt(value)});
  }

  const handleImport = () => {
    globalState.startLoadProject();
  }

  const handleDelete = () => {
    confirm.start(() => globalState.clearProject, "Clear project", "Are you sure you wish to delete all layers, structures and other project data?");
  }

  const handleSave = () => {
    globalState.saveProject();
    toastService.toast("Project saved.", {});
  }

  return (
    <>
      <Pane>
        {/* Title */}
        <PaneHeader title="Project" onToggle={globalState.togglePaneExpansion} expanded={globalState.expanded}>
          <SecondaryButton negative icon={SVG.Icons.Delete} onClick={handleDelete}/>
        </PaneHeader>

        <Content>
          <Form.Field
            name="name"
            label="Farm name"
            value={globalState.project.name}
            hint="Please enter the farm name."
            wrapper='box'
            onChange={handleChangeName}
            rules={{
              required: {}
            }}
            control={<Input/>}
          />
          <Form.Field
            name="species"
            label="Species"
            value={globalState.project.species}
            hint="Please enter the species name."
            wrapper='box'
            onChange={handleChangeSpecies}
            rules={{
              required: {}
            }}
            control={<Input/>}
          />    
          <Form.Field
            name="structureDepth"
            label="Structure depth"
            value={globalState.project.structureDepth}
            hint="Please enter the structure depth."
            wrapper='box'
            onChange={handleChangeStructureDepth}
            rules={{
              required: {},
              isFloat: {},
              min: { value: 0 },
              max: { value: 1000 }
            }}
            control={<Input unit="m"/>}
          />
          <Form.Field
            name="stocking"
            label="Default stocking"
            value={globalState.project.stocking}
            hint="Default number of animals in a structure."
            wrapper='box'
            onChange={handleChangeStocking}
            rules={{
              required: {},
              isInt: {},
              min: { value: 0 },
              max: { value: 10000000 }
            }}
            control={<Input unit="animals"/>}
          />           
        </Content>
        
        <Footer>
          <PrimaryButton onClick={handleImport}>Load</PrimaryButton>
          <PrimaryButton onClick={handleSave}>Save</PrimaryButton>
        </Footer>

      </Pane>
      {globalState.selectedStructure == null && <Guide x={-64} y={-48} title="Project management">
        <ul>
          <li>FINS can load .fins files or import .fins files from desktop FINS.</li>
          <li>FINS can download the current project in .fins format.</li>
        </ul>
      </Guide>}
    </>
  )
}

const Content = styled.div`
  flex: 1;
  padding: 16px;
`

const Footer = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
`

export { ProjectPane }