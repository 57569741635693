import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import axios from 'axios';

import { ConfigContext, IConfig } from './services/config/IConfig';
import { Main } from './Main';

const ANIMATION_OPACITY_STEP = 0.04;

class App {

  //
  // Fade out, then remove the loading screen.
  //  
  private static removeLoader = () => {
    const loader = document.getElementById("apploader");
    let opacity = parseFloat(getComputedStyle(loader).opacity);
    opacity = Math.max(opacity - ANIMATION_OPACITY_STEP, 0);
    loader.style.opacity = opacity.toString();
    if(opacity > 0) {
      requestAnimationFrame(App.removeLoader);
    } else {
      loader.remove();
    }
  }

  private static handleStart = () => {
    document.getElementById('startbutton').removeEventListener('click', App.handleStart);
    document.getElementById('startbutton').remove();
    App.removeLoader();
  }

  static run = (node: React.ReactNode) => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    axios.get<IConfig>("/config.json")
    .then((res) => {
      document.getElementById('apploader__progress').remove();
      document.getElementById('startbutton').style.visibility = 'visible';
      document.getElementById('startbutton').addEventListener('click', App.handleStart);
      root.render(
        <ConfigContext.Provider value={res.data}>
          {node}
        </ConfigContext.Provider>
      );
    })
    .catch(() => {
      root.render("Error: Could not load config.json.");
    });
  }
}

App.run(<Main/>);
  
export { App }
