import { IGradientStop } from "@longline/aqua-ui/Types";
import { FeatureCollection, GeoJsonObject } from "geojson";
import { PresetGradients } from "./ILayerTypeDefinition";

interface IReference {
  name: string;
  visible: boolean;
  geojson: FeatureCollection;
  properties: string[];
  property: string;
  gradient: IGradientStop[];
  opacity: number;
}

const DefaultReference: IReference = {
  name: "Unnamed reference",
  geojson: null,
  visible: true,
  properties: [],
  property: null,
  gradient: PresetGradients.Blue,
  opacity: 50
}

export { IReference, DefaultReference }
