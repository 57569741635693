import * as React from 'react';
import styled from 'styled-components';

import { InfoBox } from '@longline/aqua-ui/containers/InfoBox';
import { TertiaryButton } from '@longline/aqua-ui/controls/TertiaryButton';
import { Number } from '@longline/aqua-ui/formatters/Number';
import { Form } from '@longline/aqua-ui/containers/Form';
import { Slider } from '@longline/aqua-ui/inputs/Slider';

import { ILayer } from '../../types/ILayer';
import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { LayerInfoBoxHeader } from './LayerInfoBoxHeader';
import { LayerUtils } from '../../util/LayerUtils';
import { Dropdown } from '@longline/aqua-ui/inputs/Dropdown';
import { Gradient } from '@longline/aqua-ui/controls/Gradient';
import { PolygonUtils } from '../../util/PolygonUtils';
import { InfoBoxRow } from '../InfoBoxRow';
import { ELayerType } from '../../types/ELayerType';
import { IGradientStop } from '@longline/aqua-ui/Types';

interface IProps {
  value?: ILayer;
}

const LayerInfoBox = (props: IProps) => {
  const globalState = useGlobalState();

  const humanArea = (name: string, aream2: number) => {
    if(aream2 > 1000000) return <InfoBoxRow name={<>{name} <Unit>(km2)</Unit></>} value={<Number value={aream2/1000000} decimals={2}/>}/>;
    return <InfoBoxRow name={<>{name} <Unit>(m2)</Unit></>} value={<Number value={aream2} decimals={0}/>}/>;
  }

  const handleUpdateContours = (value: number) => {
    props.value.contours = value;
    globalState.updateLayer(props.value);
  }

  const handleUpdateGradient = (value: IGradientStop[]) => {
    props.value.gradient = value;
    globalState.updateLayer(props.value);
  }

  const handleUpdateTrail = (value: number) => {
    props.value.trail = value;
    globalState.updateLayer(props.value);
  }

  const handleUpdateDensity = (value: number) => {
    props.value.density = value;
    globalState.updateLayer(props.value);
  }

  const handleUpdatePointSize = (value: number) => {
    props.value.pointSize = value;
    globalState.updateLayer(props.value);
  }

  const handleUpdateDelay = (value: number) => {
    props.value.delay = value;
    globalState.updateLayer(props.value);
  }  

  // Get layer definition:
  const def = LayerUtils.layerTypeToDefinition(props.value.type);

  // Get layer's min and max values.
  const [minValue, maxValue] = LayerUtils.getMinMax(props.value);

  // Get layer's area in m2:
  const minLng = Math.min(...props.value.data.map(p => p.lng));
  const maxLng = Math.max(...props.value.data.map(p => p.lng));
  const minLat = Math.min(...props.value.data.map(p => p.lat));
  const maxLat = Math.max(...props.value.data.map(p => p.lat));
  const width = PolygonUtils.distance( { lng: minLng, lat: minLat }, { lng: maxLng, lat: minLat });
  const height = PolygonUtils.distance( { lng: minLng, lat: minLat }, { lng: minLng, lat: maxLat });
  const aream2 = width * height;

  return (
    <InfoBox 
      header={<LayerInfoBoxHeader value={props.value}/>}
      footer={<>
        <TertiaryButton onClick={() => globalState.toggleLayer(props.value)}>TOGGLE</TertiaryButton>
        <TertiaryButton onClick={() => globalState.focusLayer(props.value)}>FOCUS</TertiaryButton>
      </>}
    >
      <InfoBoxScroller>
        <InfoBoxRow name="Source" value={props.value.source}/>
        <InfoBoxRow name="Points" value={<Number value={props.value.data.length} decimals={0}/>}/>
        {humanArea("Area", aream2)}
        <InfoBoxRow 
          name={<>Range <Unit>{def.unit}</Unit></>} 
          value={<><Number value={minValue} decimals={def.decimals}/> - <Number value={maxValue} decimals={def.decimals}/></>}
        />

        <Spacer/>

        {props.value.type != ELayerType.CurrentSpeed && <Form.Field
          label="Contour density" 
          name="contours"
          hint="Please select number of contour lines (or none)."
          wrapper='box'
          value={props.value.contours}
          onChange={handleUpdateContours}
          rules={{ required: {} }}
          control={<Slider padded min={0} max={255} />}
        />}

        <Form.Field
          label="Gradient"
          name="gradient"
          hint="Please select a rendering gradient."
          wrapper="box"
          value={props.value.gradient}
          onChange={handleUpdateGradient}
          rules={{ required: {} }}
          control={<Dropdown direction='up' data={def.gradients} label={(item) => <Gradient checkered rounded thickness={10} gradientStops={item}/>}>
            <Dropdown.Column>{(item) => <Gradient checkered rounded thickness={10} gradientStops={item}/>}</Dropdown.Column>
          </Dropdown>}
        />

        {props.value.type == ELayerType.CurrentSpeed && <>
          <div style={{ display: 'flex', gap: '8px'}}>
            <Form.Field
              label="Particle trail" 
              name="trail"
              hint="Particle trail length"
              wrapper='box'
              value={props.value.trail}
              onChange={handleUpdateTrail}
              weight={1}
              rules={{ required: {} }}
              control={<Slider padded min={20} max={80} ticks={7} snapToTicks/>}
            />
            <Form.Field
              label="Particle density" 
              name="density"
              hint="Number of particles."
              wrapper='box'
              value={props.value.density}
              onChange={handleUpdateDensity}
              weight={1}
              rules={{ required: {} }}
              control={<Slider padded min={10} max={80} ticks={8} snapToTicks/>}
            />
          </div>
          <div style={{ display: 'flex', gap: '8px'}}>
            <Form.Field
              label="Particle size" 
              name="size"
              hint="Particle size (pixels)."
              wrapper='box'
              weight={1}
              value={props.value.pointSize}
              onChange={handleUpdatePointSize}
              rules={{ required: {} }}
              control={<Slider padded min={1} max={10} ticks={11} snapToTicks/>}
            />      

            <Form.Field
              label="Particle delay" 
              name="delay"
              hint="Simulation delay."
              wrapper='box'
              weight={1}
              value={props.value.delay}
              onChange={handleUpdateDelay}
              rules={{ required: {} }}
              control={<Slider padded min={0} max={10} ticks={11} snapToTicks/>}
            />
          </div>
        </>}   
      </InfoBoxScroller>            
    </InfoBox>
  )
}

const InfoBoxScroller = styled.div`
  padding: 8px 16px 8px 16px;
  max-height: 60vh;
  overflow-y: auto;
`

const Unit = styled.span`
  display: inline-block;
  font-size: 80%;
  font-weight: normal;
  color: #eee;
`

const Spacer = styled.div`
  height: 16px;  
`

export { LayerInfoBox }
