import * as React from 'react';
import * as XLSX from 'xlsx';
import styled from 'styled-components'

import { Dialog } from '@longline/aqua-ui/services/Dialog';
import { Dropzone } from '@longline/aqua-ui/controls/Dropzone';
import { PrimaryButton } from '@longline/aqua-ui/controls/PrimaryButton';
import { TabBar } from '@longline/aqua-ui/controls/TabBar';

import { TCellValue } from '../../util/TCellValue';
import { ExcelTable } from './ExcelTable';
import { sheetToArray, TSheetToArrayResult } from '../../util/sheetToArray';
import { OptionsForm } from './OptionsForm';
import { ExcelConstants } from './ExcelConstants';
import { Sprites } from '../../svg';
import { SVG } from '@longline/aqua-ui/svg';
import { ILayer } from '../../types/ILayer';
import { LayerUtils } from '../../util/LayerUtils';
import { ELayerType } from '../../types/ELayerType';

// A module that allows 
// * uploading of an Excel file
// * Display sheets and (partial) content
// * Allows mapping of data sources to columns
// * Performs import of data sources into a DataSet.

type TPhase = 'upload' | 'configure' | 'error';

interface IProps {
  /** ignore */
  className?: string;
  /** Fired when import is cancelled. */
  onCancel: () => void;
  /** Fired when import is complete. */
  onImport: (layers: ILayer[]) => void;
}

const ExcelImport = (props: IProps) => {
  const [phase, setPhase] = React.useState<TPhase>('upload'); // Operating mode
  const [error, setError] = React.useState(""); // Current error
  const [workbook, setWorkbook] = React.useState<XLSX.WorkBook>(null); // Current workbook in memory
  const [data, setData] = React.useState<TCellValue[][]>([]); // Data array read from workbook
  const [sheetIndex, setSheetIndex] = React.useState(0); // Current worksheet index
  const [totalRows, setTotalRows] = React.useState(0);
  const [skiprows, setSkiprows] = React.useState("0"); // Number of header rows to skip
  const [reduction, setReduction] = React.useState("1"); // Data reduction: skip every n rows
  const [valid, setValid] = React.useState(true); // Is options form valid?
  const [loading, setLoading] = React.useState(false); // Currently loading?
  const [filename, setFilename] = React.useState<string>(null); // Name of imported file.

  const [latitude, setLatitude] = React.useState<number>(null);
  const [longitude, setLongitude] = React.useState<number>(null);
  const [depth, setDepth] = React.useState<number>(null);
  const [eastSpeed, setEastSpeed] = React.useState<number>(null);
  const [northSpeed, setNorthSpeed] = React.useState<number>(null);
  const [yearlyPOC, setYearlyPOC] = React.useState<number>(null);
  const [dailyPOC, setDailyPOC] = React.useState<number>(null);
  const [oxygen, setOxygen] = React.useState<number>(null);
  const [ammonia, setAmmonia] = React.useState<number>(null);
  const [chlorophyll, setChlorophyll] = React.useState<number>(null);
  const [pathogen, setPathogen] = React.useState<number>(null);
  const [pocInSediment, setPocInSediment] = React.useState<number>(null);
  const [pomInSediment, setPomInSediment] = React.useState<number>(null);
  const [bacteriaInSediment, setBacteriaInSediment] = React.useState<number>(null);
  const [oxygenInSediment, setOxygenInSediment] = React.useState<number>(null);
  const [sulphateInSediment, setSulphateInSediment] = React.useState<number>(null);
  const [ironHydroxideInSediment, setIronHydroxideInSediment] = React.useState<number>(null);
  const [totalSulphideInSediment, setTotalSulphideInSediment] = React.useState<number>(null);
  const [ironInSediment, setIronInSediment] = React.useState<number>(null);
  const [ironSulphideInSediment, setIronSulphideInSediment] = React.useState<number>(null);
  
  const handleCancelImport = () => {
    props.onCancel();
  }

  //
  // Upon user file selection:
  // * Read file from disk as ArrayBuffer
  // * Load Excel Workbook from ArrayBuffer
  // On error, state is set to 'error'.
  // See: https://github.com/exceljs/exceljs/issues/832
  // 
  const handleAddFiles = (files: File[]) => {
    const file = files[0];
    const reader = new FileReader();
    setFilename(file.name);
    reader.readAsArrayBuffer(file);
    reader.onerror = () => {
      setPhase('error');
      setError('The file could not be read.');
    }
    reader.onload = () => {
      const buffer = reader.result;
      try {
        const workbook = XLSX.read(buffer);
        if(workbook.SheetNames[0] == 'FINS Metadata') {
          loadOrganix(file.name, workbook);
        } else {
          loadData(workbook, 0);
        }
      }
      catch(e) {
        setPhase('error');
        setError(e.message);
      }
    }
  }

  const loadOrganix = (filename: string, workbook: XLSX.WorkBook) => {
    // Read entire worksheet into array.
    const result = sheetToArray(workbook, 1);
    
    // Remove rows to be skipped.
    result.data.splice(0, result.skiprows);
    
    const layers: ILayer[] = [];
    if(result.dailyPOC != null) layers.push(createLayer(filename, result, ELayerType.DailyPOC, result.dailyPOC));
    if(result.yearlyPOC != null) layers.push(createLayer(filename, result, ELayerType.YearlyPOC, result.yearlyPOC));
    if(result.oxygen != null) layers.push(createLayer(filename, result, ELayerType.Oxygen, result.oxygen));
    if(result.ammonia != null) layers.push(createLayer(filename, result, ELayerType.Ammonia, result.ammonia));
    if(result.chlorophyll != null) layers.push(createLayer(filename, result, ELayerType.Chlorophyll, result.chlorophyll));
    if(result.pathogen != null) layers.push(createLayer(filename, result, ELayerType.Pathogen, result.pathogen));

    if(result.pocInSediment != null) layers.push(createLayer(filename, result, ELayerType.PocInSediment, result.pocInSediment));
    if(result.pomInSediment != null) layers.push(createLayer(filename, result, ELayerType.PomInSediment, result.pomInSediment));
    if(result.bacteriaInSediment != null) layers.push(createLayer(filename, result, ELayerType.BacteriaInSediment, result.bacteriaInSediment));
    if(result.oxygenInSediment != null) layers.push(createLayer(filename, result, ELayerType.OxygenInSediment, result.oxygenInSediment));
    if(result.sulphateInSediment != null) layers.push(createLayer(filename, result, ELayerType.SulphateInSediment, result.sulphateInSediment));
    if(result.ironHydroxideInSediment != null) layers.push(createLayer(filename, result, ELayerType.IronHydroxideInSediment, result.ironHydroxideInSediment));
    if(result.totalSulphideInSediment != null) layers.push(createLayer(filename, result, ELayerType.TotalSulphideInSediment, result.totalSulphideInSediment));
    if(result.ironInSediment != null) layers.push(createLayer(filename, result, ELayerType.IronInSediment, result.ironInSediment));
    if(result.ironSulphideInSediment != null) layers.push(createLayer(filename, result, ELayerType.IronSulphideInsediment, result.ironSulphideInSediment));
    
    props.onImport(layers);      
  }

  const loadData = (workbook: XLSX.WorkBook, sheetIndex: number) => {
    // First, switch to configure mode and show loader.
    setPhase('configure');
    setSheetIndex(sheetIndex);
    setWorkbook(workbook);
    setLoading(true);
    
    // Next, load the data from the worksheet and hide loader:
    setTimeout(() => {
      const result = sheetToArray(workbook, sheetIndex, 20);
      setData([...result.data]);
      setTotalRows(result.totalRows);
      setSkiprows(result.skiprows.toString());
      setReduction("1");
      setLatitude(result.latitude);
      setLongitude(result.longitude);
      setDepth(result.depth);
      setEastSpeed(result.eastspeed);
      setNorthSpeed(result.northspeed);
      setDailyPOC(result.dailyPOC);
      setYearlyPOC(result.yearlyPOC);
      setOxygen(result.oxygen);
      setAmmonia(result.ammonia);
      setChlorophyll(result.chlorophyll);
      setPathogen(result.pathogen);
      setPocInSediment(result.pocInSediment);
      setPomInSediment(result.pomInSediment);
      setBacteriaInSediment(result.bacteriaInSediment);
      setOxygenInSediment(result.oxygenInSediment);
      setSulphateInSediment(result.sulphateInSediment);
      setIronHydroxideInSediment(result.ironHydroxideInSediment);
      setTotalSulphideInSediment(result.totalSulphideInSediment);
      setIronInSediment(result.ironInSediment);
      setIronSulphideInSediment(result.ironSulphideInSediment);
      setLoading(false);
    }, 100);
  }

  const handleChangeSheet = (idx: number) => {
    loadData(workbook, idx);
  }

  const handleChangeOptions = (skiprows: string, reduction: string, valid: boolean) => {
    setSkiprows(skiprows);
    setReduction(reduction);
    setValid(valid);
  }

  const handleSetColumn = (idx: number, value: string) => {
    if(latitude == idx) setLatitude(null);
    if(longitude == idx) setLongitude(null);
    if(depth == idx) setDepth(null);
    if(eastSpeed == idx) setEastSpeed(null);
    if(northSpeed == idx) setNorthSpeed(null);
    if(yearlyPOC == idx) setYearlyPOC(null);
    if(dailyPOC == idx) setDailyPOC(null);
    if(oxygen == idx) setOxygen(null);
    if(ammonia == idx) setAmmonia(null);
    if(chlorophyll == idx) setChlorophyll(null);
    if(pathogen == idx) setPathogen(null);
    if(pocInSediment == idx) setPocInSediment(null);
    if(pomInSediment == idx) setPomInSediment(null);
    if(bacteriaInSediment == idx) setBacteriaInSediment(null);
    if(oxygenInSediment == idx) setOxygenInSediment(null);
    if(sulphateInSediment == idx) setSulphateInSediment(null);
    if(ironHydroxideInSediment == idx) setIronHydroxideInSediment(null);
    if(totalSulphideInSediment == idx) setTotalSulphideInSediment(null);
    if(ironInSediment == idx) setIronInSediment(null);
    if(ironSulphideInSediment == idx) setIronSulphideInSediment(null);
    switch(value) {
      case ExcelConstants.STR_LONGITUDE: setLongitude(idx); break;
      case ExcelConstants.STR_LATITUDE: setLatitude(idx); break;
      case ExcelConstants.STR_DEPTH: setDepth(idx); break;
      case ExcelConstants.STR_U: setEastSpeed(idx); break;
      case ExcelConstants.STR_V: setNorthSpeed(idx); break;
      case ExcelConstants.STR_DAILY_POC: setDailyPOC(idx); break;
      case ExcelConstants.STR_YEARLY_POC: setYearlyPOC(idx); break;
      case ExcelConstants.STR_OXYGEN: setOxygen(idx); break;
      case ExcelConstants.STR_AMMONIA: setAmmonia(idx); break;
      case ExcelConstants.STR_CHLOROPHYLL: setChlorophyll(idx); break;
      case ExcelConstants.STR_PATHOGEN: setPathogen(idx); break;
      case ExcelConstants.STR_POC_IN_SEDIMENT: setPocInSediment(idx); break;
      case ExcelConstants.STR_POM_IN_SEDIMENT: setPomInSediment(idx); break;
      case ExcelConstants.STR_BACTERIA_SEDIMENT: setBacteriaInSediment(idx); break;
      case ExcelConstants.STR_OXYGEN_IN_SEDIMENT: setOxygenInSediment(idx); break;
      case ExcelConstants.STR_SULPHATE_IN_SEDIMENT: setSulphateInSediment(idx); break;
      case ExcelConstants.STR_IRON_HYDROXIDE_IN_SEDIMENT: setIronHydroxideInSediment(idx); break;
      case ExcelConstants.STR_TOTAL_SULPHIDE_IN_SEDIMENT: setTotalSulphideInSediment(idx); break;
      case ExcelConstants.STR_IRON_IN_SEDIMENT: setIronInSediment(idx); break;
      case ExcelConstants.STR_IRON_SULPHIDE_IN_SEDIMENT: setIronSulphideInSediment(idx); break;
    }
  }

  const isColumnAssignmentValid = () => {
    // Lat/lng are required.
    if(latitude == null) return false;
    if(longitude == null) return false;
    // If importing east speed, must also import north speed (and vice versa)
    if(eastSpeed == null && northSpeed != null) return false;
    if(northSpeed == null && eastSpeed != null) return false;
    // Must import at least one variable.
    if(depth == null && eastSpeed == null && dailyPOC == null && 
        yearlyPOC == null && oxygen == null && ammonia == null && chlorophyll == null &&
        pathogen == null 
        && pocInSediment == null
        && pomInSediment == null
        && bacteriaInSediment == null
        && oxygenInSediment == null
        && sulphateInSediment == null
        && ironHydroxideInSediment == null
        && totalSulphideInSediment == null
        && ironInSediment == null
        && ironSulphideInSediment == null) return false;
    return true;
  }

  const createLayer = (filename: string, result: TSheetToArrayResult, type: ELayerType, ...indices: number[]): ILayer => {
    const layer = LayerUtils.createLayer(type, filename,
      result.data.map((row: TCellValue[]) => { return { 
        lng: parseFloat(row[result.longitude] as string),
        lat: parseFloat(row[result.latitude] as string),
        value: indices.map(idx => parseFloat(row[idx] as string))
      }}));    
    return layer;
  }

  const handleImport = () => {
    setLoading(true);

    // Read entire worksheet into array.
    const result = sheetToArray(workbook, sheetIndex);
    
    // Remove rows to be skipped.
    result.data.splice(0, parseInt(skiprows));

    const layers: ILayer[] = [];

    if(depth != null) layers.push(createLayer(filename, result, ELayerType.Depth, depth));
    if(dailyPOC != null) layers.push(createLayer(filename, result, ELayerType.DailyPOC, dailyPOC));
    if(yearlyPOC != null) layers.push(createLayer(filename, result, ELayerType.YearlyPOC, yearlyPOC));
    if(oxygen != null) layers.push(createLayer(filename, result, ELayerType.Oxygen, oxygen));
    if(ammonia != null) layers.push(createLayer(filename, result, ELayerType.Ammonia, ammonia));
    if(chlorophyll != null) layers.push(createLayer(filename, result, ELayerType.Chlorophyll, chlorophyll));
    if(pathogen != null) layers.push(createLayer(filename, result, ELayerType.Pathogen, pathogen));
    if(pocInSediment != null) layers.push(createLayer(filename, result, ELayerType.PocInSediment, pocInSediment));
    if(pomInSediment != null) layers.push(createLayer(filename, result, ELayerType.PomInSediment, pomInSediment));
    if(bacteriaInSediment != null) layers.push(createLayer(filename, result, ELayerType.BacteriaInSediment, bacteriaInSediment));
    if(oxygenInSediment != null) layers.push(createLayer(filename, result, ELayerType.OxygenInSediment, oxygenInSediment));
    if(sulphateInSediment != null) layers.push(createLayer(filename, result, ELayerType.SulphateInSediment, sulphateInSediment));
    if(ironHydroxideInSediment != null) layers.push(createLayer(filename, result, ELayerType.IronHydroxideInSediment, ironHydroxideInSediment));
    if(totalSulphideInSediment != null) layers.push(createLayer(filename, result, ELayerType.TotalSulphideInSediment, totalSulphideInSediment));
    if(ironInSediment != null) layers.push(createLayer(filename, result, ELayerType.IronInSediment, ironInSediment));
    if(ironSulphideInSediment != null) layers.push(createLayer(filename, result, ELayerType.IronSulphideInsediment, ironSulphideInSediment));
    if(eastSpeed != null) layers.push(createLayer(filename, result, ELayerType.CurrentSpeed, eastSpeed, northSpeed));

    props.onImport(layers);
  }

  const renderUpload = () => {
    return (
      <Dialog inverted width={800} open={true} onClose={handleCancelImport}>
        <Dialog.Header>
          Import data from Microsoft Excel
        </Dialog.Header>
        <Dialog.Content>
          <div> {/* <div> required to avoid Chrome horizontal scrollbar */}
            <Dropzone onAddFiles={handleAddFiles} accept=".xls,.xlsx"/>
          </div>
        </Dialog.Content>
        <Dialog.Footer>
          <PrimaryButton icon={SVG.Icons.Cross} onClick={handleCancelImport}>Close</PrimaryButton>
        </Dialog.Footer>
      </Dialog>      
    );
  }

  const renderConfigure = () => {
    return (
      <Dialog inverted width={1000} open={true} onClose={handleCancelImport}>
        <Dialog.Header>
          Import data from Microsoft Excel
        </Dialog.Header>
        <Dialog.Content nopadding>
          <ContentFlex>
            <TabBar onChange={(idx: number) => handleChangeSheet(idx)}>
              {workbook.SheetNames.map(s => <TabBar.Tab key={s}>{s}</TabBar.Tab>)}
            </TabBar>

            <ExcelTable 
              data={data} 
              rowCount={totalRows}
              skiprows={parseInt(skiprows)}
              loading={loading}
              longitude={longitude}
              latitude={latitude}
              depth={depth}
              eastspeed={eastSpeed}
              northspeed={northSpeed}
              daily_poc={dailyPOC}
              yearly_poc={yearlyPOC}
              oxygen={oxygen}
              ammonia={ammonia}
              chlorophyll={chlorophyll}
              pathogen={pathogen}
              pocInSediment={pocInSediment}
              pomInSediment={pomInSediment}
              bacteriaInSediment={bacteriaInSediment}
              oxygenInSediment={oxygenInSediment}
              sulphateInSediment={sulphateInSediment}
              ironHydroxideInSediment={ironHydroxideInSediment}
              totalSulphideInSediment={totalSulphideInSediment}
              ironInSediment={ironInSediment}
              ironSulphideInSediment={ironSulphideInSediment}
              onSetColumn={handleSetColumn}
            />

            <OptionsForm 
              skiprows={skiprows} 
              reduction={reduction} 
              totalrows={totalRows}
              onChange={handleChangeOptions}
            />
          </ContentFlex>
        </Dialog.Content>
        <Dialog.Footer align='space-between'>
          <PrimaryButton icon={SVG.Icons.Cross} onClick={handleCancelImport}>Close</PrimaryButton>
          <PrimaryButton icon={Sprites.Icons.Import} disabled={!valid || data.length == 0 || !isColumnAssignmentValid()} onClick={handleImport}>Import</PrimaryButton>
        </Dialog.Footer>        
      </Dialog>
    )
  }

  const renderError = () => {
    return (
      <Dialog width={800} open={true} onClose={handleCancelImport}>
        <Dialog.Header>
          Data import error
        </Dialog.Header>
        <Dialog.Content>
          An error occurred importing the data. The error was:
          {error}
        </Dialog.Content>
        <Dialog.Footer>
          <PrimaryButton onClick={handleCancelImport}>Cancel</PrimaryButton>
        </Dialog.Footer>
      </Dialog>     
    );
  }

  switch(phase) {
    case 'configure': return renderConfigure();
    case 'error': return renderError();
    default: return renderUpload();
  }
}

const ContentFlex = styled.div`
  height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
`

export { ExcelImport }
