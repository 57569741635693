import * as React from 'react';

import { AuthContext } from './AuthContext';
import { IUser } from '../../api/models/IUser';

interface IProps {
  children?: React.ReactNode;
}

const Auth = (props: IProps) => {
  
  const login = (user: IUser) => {
    setUser({...user});
    // Store user in session:
    sessionStorage.setItem('user', JSON.stringify(user));
    // Store email in session:
    sessionStorage.setItem('email', user.email);
  }

  const logout = () => {
    setUser(null);
    // Remove user from session:
    sessionStorage.removeItem('user');
  }

  const [user, setUser] = React.useState<IUser>(null);

  return (
    <AuthContext.Provider value={{
      user,
      login,
      logout
    }}>
      {props.children}
    </AuthContext.Provider>
  );  
}

export { Auth }
