import * as React from 'react';
import styled from 'styled-components';

import { InfoBox } from '@longline/aqua-ui/containers/InfoBox';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { TertiaryButton } from '@longline/aqua-ui/controls/TertiaryButton';
import { IReference } from '../../types/IReference';
import { InfoBoxRow } from '../InfoBoxRow';
import { Number } from '@longline/aqua-ui/formatters/Number';
import { ReferenceInfoBoxHeader } from './ReferenceInfoBoxHeader';
import { Form } from '@longline/aqua-ui/containers/Form';
import { Dropdown } from '@longline/aqua-ui/inputs/Dropdown';
import { Gradient } from '@longline/aqua-ui/controls/Gradient';
import { PresetGradients } from '../../types/ILayerTypeDefinition';
import { IGradientStop } from '@longline/aqua-ui/Types';
import { Slider } from '@longline/aqua-ui/inputs/Slider';

interface IProps {
  value?: IReference;
}

const ReferenceInfoBox = (props: IProps) => {
  const globalState = useGlobalState();

  const handleUpdateOpacity = (value: number) => {
    props.value.opacity = value;
    globalState.updateReference(props.value);
  }

  const handleUpdateGradient = (value: IGradientStop[]) => {
    props.value.gradient = value;
    globalState.updateReference(props.value);
  }

  const handleUpdateProperty = (value: string) => {
    props.value.property = value;
    globalState.updateReference(props.value);
  }

  return (
    <InfoBox 
    header={<ReferenceInfoBoxHeader value={props.value}/>}
      width={300}
      footer={<>
        <TertiaryButton onClick={() => globalState.toggleReference(props.value)}>TOGGLE</TertiaryButton>
        <TertiaryButton onClick={() => globalState.focusReference(props.value)}>FOCUS</TertiaryButton>
      </>}
    >
      <InfoBoxScroller>
        <InfoBoxRow name="Source" value={props.value.name}/>
        <InfoBoxRow name="Features" value={<Number value={props.value.geojson.features.length} decimals={0}/>}/>
        <InfoBoxRow name="Properties" value={<Number value={props.value.properties.length} decimals={0}/>}/>

        <Spacer/>

        <Form.Field
          label="Opacity" 
          name="opacity"
          hint="Please set the layer opacity."
          wrapper='box'
          value={props.value.opacity}
          onChange={handleUpdateOpacity}
          rules={{ required: {} }}
          control={<Slider padded min={0} max={100} ticks={11} snapToTicks />}
        />

        <Form.Field
          label="Gradient"
          name="gradient"
          hint="Please select a rendering gradient."
          wrapper="box"
          value={props.value.gradient}
          onChange={handleUpdateGradient}
          rules={{ required: {} }}
          control={<Dropdown direction='up' maxItems={5} data={[ PresetGradients.Grey, PresetGradients.Blue, PresetGradients.Red, PresetGradients.DustyCactus, PresetGradients.Mimosa, PresetGradients.MelloYellow, PresetGradients.RetroWagon, PresetGradients.Rainbow]} label={(item) => <Gradient checkered rounded thickness={10} gradientStops={item}/>}>
            <Dropdown.Column>{(item) => <Gradient checkered rounded thickness={10} gradientStops={item}/>}</Dropdown.Column>
          </Dropdown>}
        />

        <Form.Field
          label="Property"
          name="property"
          hint="Please select a property."
          wrapper="box"
          value={props.value.property}
          onChange={handleUpdateProperty}
          control={<Dropdown clearable maxItems={5} direction='up' data={props.value.properties} label={(item) => item} placeholder="(none)">
            <Dropdown.Column>{(item) => item}</Dropdown.Column>
          </Dropdown>}
        />
      </InfoBoxScroller>
    </InfoBox>
  )
}

const InfoBoxScroller = styled.div`
  padding: 8px 16px 8px 16px;
  height: auto;
  max-height: 60vh;
  overflow-y: auto;
`

const Spacer = styled.div`
  height: 8px;
`

export { ReferenceInfoBox }
