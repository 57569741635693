import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  name: React.ReactNode;
  value: React.ReactNode;
}

const InfoBoxRowBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Name>{props.name}</Name>
      <Value>{props.value}</Value>
    </div>
  )
}

const Name = styled.div`
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  flex: 1;
  min-width: 65px;
`

const Value = styled.div`
  font-weight: 700;
  text-align: right;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;  
`

const InfoBoxRowStyled = styled(InfoBoxRowBase)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  gap: 16px;
  height: 28px;
  align-items: center;
`

const InfoBoxRow = (props: IProps) => <InfoBoxRowStyled {...props}/>

export { InfoBoxRow }
