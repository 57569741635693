import * as React from 'react';
import styled from 'styled-components';

import { useToast } from '@longline/aqua-ui/services/Toast/useToast';
import { PrimaryButton } from '@longline/aqua-ui/controls/PrimaryButton';

import { LoginForm } from './LoginForm';
import { Sprites } from '../../svg';
import { Public } from '../../ui/Public';
import { ILoginRequest } from '../../api/services/auth/ILoginRequest';
import { AuthApi } from '../../api/services/auth/AuthApi';
import { IUser } from '../../api/models/IUser';
import { useAuth } from '../../contexts/auth/useAuth';

// Adds a timeout to make sure that signin button animation actually gets seen:
const BUTTON_TIMEOUT = 250; // ms

const Login = () => {
  const auth = useAuth();
  const toastProvider = useToast();
  const credentials = React.useRef<ILoginRequest>({ email: sessionStorage.getItem('email') ? sessionStorage.getItem('email') : "", password: "" });
  const [loading, setLoading] = React.useState(false);
  const [valid, setValid] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);

  const handleChange = (loginForm: ILoginRequest, forceupdate: boolean) => {
    Object.assign(credentials.current, loginForm);
  }  

  const handleValidate = (_valid: boolean) => {
    setValid(_valid);
  }  

  const handleSubmit = () => {
    setDirty(true);
    if(!valid) return;
    if(loading) return;
    setLoading(true);
    setTimeout(() => {
      AuthApi.login(credentials.current.email, credentials.current.password)
      .then((user: IUser) => auth.login(user))
      .catch(() => {
        setLoading(false);
        toastProvider.toast("Login failed.", {});
      });
    }, BUTTON_TIMEOUT);
    // Adds a 250ms timeout to make sure that signin button
    // animation actually gets seen.    
  }    

  return (
    <>
      <Public.Box>
        <Public.Header/>
        <Public.Body>
          <LoginForm data={credentials.current} dirty={dirty} onChange={handleChange} onValidate={handleValidate} onSubmit={handleSubmit}/>
        </Public.Body>
      </Public.Box>
      <Public.Buttons>
        <PrimaryButton icon={loading ? {url: Sprites.Icons.CircleNotch, animated: true } : null} disabled={loading} onClick={handleSubmit}>SIGN IN</PrimaryButton>
      </Public.Buttons>    
    </>    
  )
}  

const Links = styled.div`
  margin-top: 12px;
  font-size: 80%;
`

const Longline = styled.svg`
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 48px;
  height: 64px;
`

export { Login }
