import * as React from 'react';
import styled from 'styled-components';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { ISample } from '../../types/ISample';
import { Icon } from '@longline/aqua-ui/controls/Icon';
import { SVG } from '@longline/aqua-ui/svg';

interface IProps {
  className?: string;
  value: ISample;
}

const SampleInfoBoxHeaderBase = (props: IProps) => {
  const globalState = useGlobalState();

  const handleClose = () => {
    globalState.setSelectedSample(null);
  }

  const handleKeyDown = (ev: KeyboardEvent) => {
    if(ev.code == 'Escape') handleClose();
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  return (
    <div className={props.className}>
      <Block>
        System
      </Block>
      <Block>
        <Icon url={SVG.Icons.Cross} onClick={handleClose}/>
      </Block>
    </div>
  )
}

const Block = styled.div`
  display: flex;
  flex-direction: row;  
  justify-content: start;
  gap: 8px;
  align-items: center;
`

const SampleInfoBoxHeaderStyled = styled(SampleInfoBoxHeaderBase)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SampleInfoBoxHeader = (props: IProps) => <SampleInfoBoxHeaderStyled {...props}/>

export { SampleInfoBoxHeader }
