import * as React from 'react';
import styled, { css } from 'styled-components';
import { useGlobalState } from './contexts/state/GlobalStateContext';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Guide title */
  title: string;
  /** Guide x-position. Negative is from right. */
  x: number;
  /** Guide y-position. Negative is from bottom. */
  y: number;
}

const GuideBase = (props: IProps) => {
  const globalState = useGlobalState();

  const handleEnd = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    globalState.setGuide(false);
  }

  if(!globalState.guide) return null;

  return (
    <div className={props.className}>
      <Header>
        {props.title}
      </Header>
      <Content>
        {props.children} 
      </Content>
      <Footer>
        <Button onClick={handleEnd}>End guide</Button>
      </Footer>
    </div>
  )
}

const Button = styled.button`
  user-select: none;
  box-sizing: border-box;
  outline: none;
  background: white;
  color: #333;
  border: none;
  padding-left: 8px;
  padding-right: 8px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
`

const Header = styled.div`
  background-color: ${p => p.theme.colors.neutral[10]};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-left: solid 1px ${p => p.theme.colors.primary[3]}; 
  border-right: solid 1px ${p => p.theme.colors.primary[3]};
  border-top: solid 1px ${p => p.theme.colors.primary[3]};
  padding-left: 16px;
  padding-right: 16px;
  background-color: ${p => p.theme.colors.neutral[10]}px;
  display: flex;
  align-items: center;
  height: 32px;
  box-sizing: border-box;
  font-weight: 700;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Content = styled.div`
  background-color: ${p => p.theme.colors.neutral[10]};
  color: ${p => p.theme.colors.neutral[100]};
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  border-left: solid 1px ${p => p.theme.colors.primary[3]};
  border-right: solid 1px ${p => p.theme.colors.primary[3]};
`

const Footer = styled.div`
  background-color: ${p => p.theme.colors.primary[5]};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: solid 1px ${p => p.theme.colors.primary[3]};
  border-left: solid 1px ${p => p.theme.colors.primary[3]};
  border-right: solid 1px ${p => p.theme.colors.primary[3]};
  border-bottom: solid 1px ${p => p.theme.colors.primary[3]};
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 8px;
  height: 36px;
`

const GuideStyled = styled(GuideBase)`
  position: absolute;
  z-index: 300;
  ${p => p.x >= 0 && css`left: ${p.x}px;`}
  ${p => p.y >= 0 && css`top: ${p.y}px;`}
  ${p => p.x <  0 && css`right: ${-p.x}px;`}
  ${p => p.y <  0 && css`bottom: ${-p.y}px;`}
  max-width: 200px;
  font-size: 12px;
  line-height: 20px;
  ul {
    margin: 0;
    padding: 0;
    
  }
  li {
    margin: 0 0 0 10px;
    padding: 0;
  }
`

const Guide = (props: IProps) => <GuideStyled {...props}/>

export { Guide }
