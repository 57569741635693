import * as React from 'react';

import { Dialog } from '@longline/aqua-ui/services/Dialog';
import { Dropzone } from '@longline/aqua-ui/controls/Dropzone';
import { PrimaryButton } from '@longline/aqua-ui/controls/PrimaryButton';

import { SVG } from '@longline/aqua-ui/svg';
import { DefaultReference, IReference } from '../../types/IReference';
import { FeatureCollection } from 'geojson';

type TPhase = 'upload' | 'error';

interface IProps {
  /** ignore */
  className?: string;
  /** Fired when import is cancelled. */
  onCancel: () => void;
  /** Fired when import is complete. */
  onImport: (reference: IReference) => void;
}

const ReferenceImport = (props: IProps) => {
  const [phase, setPhase] = React.useState<TPhase>('upload'); // Operating mode
  const [error, setError] = React.useState(""); // Current error

  const handleCancelImport = () => {
    props.onCancel();
  }

  const handleAddFiles = (files: File[]) => {
    const file = files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onerror = () => {
      setPhase('error');
      setError('The file could not be read.');
    }
    reader.onload = () => {
      try {
        const json: FeatureCollection = JSON.parse(reader.result.toString());
        // Find any properties in GeoJSON
        let properties: string[] = [];
        if(json.features.length > 0) {
          properties = Object.keys(json.features[0].properties);
        }
        const reference: IReference = { 
          ...DefaultReference,
          name: file.name,
          visible: true,
          geojson: json,
          properties: properties,
          property: null
        };
        props.onImport(reference);
      }
      catch(e) {
        setPhase('error');
        setError(e.message);
      }
    }
  }
  
  const renderUpload = () => {
    return (
      <Dialog inverted width={800} open={true} onClose={handleCancelImport}>
        <Dialog.Header>
          Import GeoJSON data
        </Dialog.Header>
        <Dialog.Content>
          <div> {/* <div> required to avoid Chrome horizontal scrollbar */}
            <Dropzone onAddFiles={handleAddFiles} accept=".json,.geojson"/>
          </div>
        </Dialog.Content>
        <Dialog.Footer>
          <PrimaryButton icon={SVG.Icons.Cross} onClick={handleCancelImport}>Close</PrimaryButton>
        </Dialog.Footer>
      </Dialog>      
    );
  }

  const renderError = () => {
    return (
      <Dialog width={800} open={true} onClose={handleCancelImport}>
        <Dialog.Header>
          Data import error
        </Dialog.Header>
        <Dialog.Content>
          An error occurred importing the data. The error was:
          {error}
        </Dialog.Content>
        <Dialog.Footer>
          <PrimaryButton onClick={handleCancelImport}>Cancel</PrimaryButton>
        </Dialog.Footer>
      </Dialog>     
    );
  }

  switch(phase) {
    case 'error': return renderError();
    default: return renderUpload();
  }
}

export { ReferenceImport }
