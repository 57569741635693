import * as React from 'react';
import styled from 'styled-components';

import { ILayer } from '../types/ILayer';
import { Gradient } from '@longline/aqua-ui/controls/Gradient';
import { LayerUtils } from '../util/LayerUtils';
import { Number } from '@longline/aqua-ui/formatters/Number';

interface IProps {
  /** @ignore */
  className?: string;
  /** Layer to render legend for. */
  layer: ILayer;
}

const LegendBase = (props: IProps) => {
  const def = LayerUtils.layerTypeToDefinition(props.layer.type);
  const [min, max] = LayerUtils.getMinMax(props.layer);
  return (
    <div className={props.className}>
      <Quantity><>{def.name} {def.unit ? <>({def.unit})</> : null}</></Quantity>
      <Min><Number value={min} decimals={def.decimals}/></Min>
      <GradientHolder>
        <Gradient gradientStops={props.layer.gradient} rounded thickness={8}/>
      </GradientHolder>
      <Max><Number value={max} decimals={def.decimals}/></Max>
    </div>
  )
}

const Quantity = styled.div`
  white-space: nowrap;
  width: 120px;
`

const Min = styled.div`
  width: 60px;
  text-align: right;
`

const Max = styled.div`
  width: 60px;
`

const GradientHolder = styled.div`
  flex: 1;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 1px rgba(0,0,0,0.5);
`

const LegendStyled = styled(LegendBase)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 80%;
`

const Legend = (props: IProps) => <LegendStyled {...props}/>

export { Legend }
