import * as React from 'react';
import { styled } from 'styled-components';

import { Dropdown } from '@longline/aqua-ui/inputs/Dropdown';
import { Form } from '@longline/aqua-ui/containers/Form';

import { Facade } from '../../c/Facade';

import { Chart as ChartJS,
  CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(
  CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
import { Line } from 'react-chartjs-2';

type TCalc = 'None' | 'Square' | 'Square root' | 'Sum';

interface IProps {
  months: string[];
}

const ChartTab = (props: IProps) => {
  const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const [calc, setCalc] = React.useState<TCalc>('None');

  const [data, setData] = React.useState({
    labels,
    datasets: []
  });

  React.useEffect(() => {
    setData(getData());
  }, [calc]);

  const getMonthlyData = () => {
    return props.months.map((m, index) => {
      const val = parseFloat(m);
      if(isNaN(val)) return null;
      switch(calc) {
        case 'Square': return Facade.sqr(val);
        case 'Square root': return Facade.sqrt(val);
        case 'Sum': {
          const arr = props.months
            .slice(0, index + 1)
            .map(m => {
              const val = parseFloat(m);
              if(isNaN(val)) return 0;
              return val;
            });
          return Facade.sum(arr);
        }
        default: return val;
      }
    });
  }  
  
  const getData = () => {
    return {
      labels,
      datasets: [
        {
          label: "Calculated",
          data: getMonthlyData(),
          borderColor: 'steelblue',
          backgroundColor: 'darkblue',
        }, {
          label: "Original",
          data: props.months,
          borderColor: 'darkorchid',
          backgroundColor: 'indigo',
        }
      ]
    }
  };

  return (
    <Layout>
      <Form.Field
        name="calc"
        label="C++ Calculation"
        rules={{ required: {}}}
        value={calc}
        onChange={setCalc}
        wrapper='box'
        hint="Please pick a calculation to perform"
        control={
          <Dropdown data={['None','Square','Square root', 'Sum']} label={item => item} value={calc} onChange={setCalc}>
            <Dropdown.Column>{item => item}</Dropdown.Column>
          </Dropdown>
        }/>
      <Chart>
        <Line options={{
            responsive: true,
            maintainAspectRatio: false, 
            plugins: {
              legend: {
                display: false,
                position: 'top' as const,
              },
              title: {
                display: false,
                text: '',
              }
            },
          }} 
          data={data} 
        />
      </Chart>
    </Layout>
  )
}

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Chart = styled.div`
  position: relative;
  flex: 1;
`

export { ChartTab }

