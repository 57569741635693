/** @module @ignore */
import * as React from 'react';
import { FeatureCollection } from 'geojson';
import { Layer, LngLat, MapMouseEvent, Source, useMap } from 'react-map-gl/mapbox';
import { GeoJSONFeature } from 'mapbox-gl';

import { POINTS_LAYER, POLYGON_FILL_COLOR_VALID, POLYGON_LAYER, POLYGON_LINE_COLOR_VALID } from '../EditorConfig';
import { featureCollection, multiLineString, polygon } from '@turf/helpers';
import { IRect } from '../../types/IRect';
import { PolygonUtils } from '../../util/PolygonUtils';

interface IProps {
  /** Current rect. */
  rect: IRect;
  /** Horizontal grid resolution */
  resolution: number;
  /** Fired when rect changes. */
  onChange: (rect: IRect) => void;
}

const RectEditorSurface = (props: IProps) => {
  const { current: map } = useMap();

  // Currently dragging?
  const dragging = React.useRef(false);
  // Point where dragging began:
  const dragSource = React.useRef<LngLat>(null);
  // Copy of props.rect, to be used by non-React events:
  const copyRect = React.useRef<IRect>(null);  
  // Original points when dragging began:
  const originalRect = React.useRef<IRect>(null);

  const handleMouseDown = (e: MapMouseEvent) => {
    if(e.features.find((f: GeoJSONFeature) => f.layer.id != POLYGON_LAYER)) return;
    // Disable map drag:
    map.getMap().dragPan.disable();
    map.getCanvas().style.cursor = 'move';
    dragging.current = true;
    originalRect.current = { ...copyRect.current };
    dragSource.current = e.lngLat;
  }

  const handleMouseUp = (e: MapMouseEvent) => {
    // Re-enable map drag:
    map.getMap().dragPan.enable();
    map.getCanvas().style.cursor = '';
    dragging.current = false;
  }

  const handleMouseMove = (e: MapMouseEvent) => {
    if(!dragging.current) return;
    // Find dragging distance, in lng/lat difference:
    const dLat = e.lngLat.lat - dragSource.current.lat;
    const dLng = e.lngLat.lng - dragSource.current.lng;
    // Update rect.
    const rect: IRect = {
      minLng: originalRect.current.minLng + dLng,
      maxLng: originalRect.current.maxLng + dLng,
      minLat: originalRect.current.minLat + dLat,
      maxLat: originalRect.current.maxLat + dLat,
    }
    props.onChange(rect);
  }

  const handleClick = (e: MapMouseEvent) => {
    // If the polygon is clicked, then the click must be consumed so it
    // doesn't propagate to the parent. The parent must check for
    // isDefaultPrevented.
    e.preventDefault();
  }

  const mount = () => {
    copyRect.current = props.rect;
    // Register for clicks and moves:
    map.on('mousedown', [POLYGON_LAYER, POINTS_LAYER], handleMouseDown);
    map.on('mouseup', handleMouseUp);
    map.on('mousemove', handleMouseMove);
    map.on('click', POLYGON_LAYER, handleClick);
  }

  const unmount = () => {
    // Unregister for clicks and moves:
    map.off('mousedown', [POLYGON_LAYER, POINTS_LAYER], handleMouseDown);
    map.off('mouseup', handleMouseUp);
    map.off('mousemove', handleMouseMove);
    map.off('click', POLYGON_LAYER, handleClick);
  }

  // Mounting/unmounting must only happen once.
  React.useEffect(() => {
    mount();
    return unmount
  }, [props.rect]);  
 
  const getRectJSON = (): FeatureCollection => {
    if(!props.rect) return null;

    const poly = polygon([[
      [props.rect.minLng, props.rect.minLat],
      [props.rect.maxLng, props.rect.minLat],
      [props.rect.maxLng, props.rect.maxLat],
      [props.rect.minLng, props.rect.maxLat],
      [props.rect.minLng, props.rect.minLat]
    ]]);

    return featureCollection([poly]);
  }

  const getGridJSON = (): FeatureCollection => {
    if(!props.rect) return null;

    const HOR = props.resolution;

    const width = PolygonUtils.distance(
      { lng: props.rect.minLng, lat: props.rect.minLat}, 
      { lng: props.rect.maxLng, lat: props.rect.minLat});
    const height =PolygonUtils.distance(
      { lng: props.rect.minLng, lat: props.rect.minLat}, 
      { lng: props.rect.minLng, lat: props.rect.maxLat});
    const ratio = Math.abs(height / width);
    const VERT = Math.floor(props.resolution * ratio);

    const lines = [];
    for(let i = 0; i < HOR - 1; i++) {
      const lng = props.rect.minLng + (props.rect.maxLng - props.rect.minLng) / HOR * (i + 1);
      lines.push([ 
        [ lng, props.rect.minLat ],
        [ lng, props.rect.maxLat ]
      ]);
    }
    for(let i = 0; i < VERT - 1; i++) {
      const lat = props.rect.minLat + (props.rect.maxLat - props.rect.minLat) / VERT * (i + 1);
      lines.push([ 
        [ props.rect.minLng, lat ],
        [ props.rect.maxLng, lat ]
      ]);
    }
    return featureCollection([multiLineString(lines)]);
  }


  return (
    <>
      <Source type="geojson" data={getGridJSON()}>
        <Layer 
          type="line"
          paint={{
            "line-color": POLYGON_LINE_COLOR_VALID,
            "line-width": 0.1,
          }}
        />      
      </Source>
      <Source type="geojson" data={getRectJSON()}>
        <Layer 
          id={POLYGON_LAYER}
          type="fill"
          paint={{
            "fill-color": POLYGON_FILL_COLOR_VALID,
            "fill-opacity": 0.2
          }}
        />        
        <Layer 
          type="line"
          paint={{
            "line-color": POLYGON_LINE_COLOR_VALID,
            "line-width": 1.5,
            "line-dasharray": [ 2, 1 ]
          }}
        />
      </Source>
    </>
  );
}

export { RectEditorSurface }
