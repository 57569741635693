/** @module @ignore */
import * as React from 'react';
import { FeatureCollection } from 'geojson';
import { Layer, LngLat, MapboxGeoJSONFeature, MapMouseEvent, Source, useMap } from 'react-map-gl';

import { PolygonUtils } from '../../util/PolygonUtils';
import { POINTS_LAYER, POLYGON_FILL_COLOR_VALID, POLYGON_LAYER, POLYGON_LINE_COLOR_VALID } from '../EditorConfig';
import { NUM_CIRCLE_POINTS } from '../EditorConfig';
import { IPoint } from '../../types/IPoint';
import { featureCollection } from '@turf/helpers';

interface ICircleEditorSurfaceProps {
  point: IPoint;
  radius: number;
  /** Fired when points change. */
  onChange: (point: IPoint, radius: number) => void;
}

let radius: number;
let startDragPoint: IPoint;

const CircleEditorSurface = (props: ICircleEditorSurfaceProps) => {
  const { current: map } = useMap();
  // Currently dragging?
  let dragging = false;
  // Point where dragging began:
  let dragSource: LngLat = null;
  // Original points when dragging began:
  let currentdragPoint: IPoint;

  const handleMouseDown = (e: MapMouseEvent) => {
    if(e.features.find((f: MapboxGeoJSONFeature) => f.layer.id != POLYGON_LAYER)) return;
    // Disable map drag:
    map.getMap().dragPan.disable();
    map.getCanvas().style.cursor = 'move';
    dragging = true;
    dragSource = e.lngLat;
    currentdragPoint = startDragPoint;
  }

  const handleMouseUp = (e: MapMouseEvent) => {
    // Re-enable map drag:
    map.getMap().dragPan.enable();
    map.getCanvas().style.cursor = '';
    dragging = false;
  }

  const handleMouseMove = (e: MapMouseEvent) => {
    if(!dragging) return;
    const dLat = e.lngLat.lat - dragSource.lat;
    const dLng = e.lngLat.lng - dragSource.lng;
    const newPoint = {
      lat: currentdragPoint.lat + dLat,
      lng: currentdragPoint.lng + dLng
    }
    props.onChange(newPoint, radius);
  }

  const handleClick = (e: MapMouseEvent) => {
    // If the polygon is clicked, then the click must be consumed so it
    // doesn't propagate to the parent. The parent must check for
    // isDefaultPrevented.
    e.preventDefault();
  }

  const mount = () => {
    // Register for clicks and moves:
    map.on('mousedown', [POLYGON_LAYER, POINTS_LAYER], handleMouseDown);
    map.on('mouseup', handleMouseUp);
    map.on('mousemove', handleMouseMove);
    map.on('click', POLYGON_LAYER, handleClick);
  }

  const unmount = () => {
    // Unregister for clicks and moves:
    map.off('mousedown', [POLYGON_LAYER, POINTS_LAYER], handleMouseDown);
    map.off('mouseup', handleMouseUp);
    map.off('mousemove', handleMouseMove);
    map.off('click', POLYGON_LAYER, handleClick);
  }

  // Mounting/unmounting must only happen once.
  React.useEffect(() => {
    mount();
    return unmount
  }, []);  
  
  // startDragPoints must be updated every time the props points are updated.
  React.useEffect(() => {
    startDragPoint = props.point;
    radius = props.radius;
  }, [props.point, props.radius]);
  
  const getCircleJSON = (): FeatureCollection => 
    featureCollection([PolygonUtils.getCirclePolygon(props.point, props.radius)]);

  return (
    <Source type="geojson" data={getCircleJSON()}>
      <Layer 
        id={POLYGON_LAYER}
        type="fill"
        paint={{
          "fill-color": POLYGON_FILL_COLOR_VALID,
          "fill-opacity": 0.2
        }}
      />        
      <Layer 
        type="line"
        paint={{
          "line-color": POLYGON_LINE_COLOR_VALID,
          "line-width": 1.5,
          "line-dasharray": [ 2, 1 ]
        }}
      />
    </Source>    
  );
}

export { CircleEditorSurface }
