import * as React from 'react';
import styled, { useTheme } from 'styled-components';

import { Dialog } from '@longline/aqua-ui/services/Dialog';
import { PrimaryButton } from '@longline/aqua-ui/controls/PrimaryButton';
import { Form } from '@longline/aqua-ui/containers/Form';
import { Input } from '@longline/aqua-ui/inputs/Input';

import { IStructure } from '../../types/IStructure';
import { SVG } from '@longline/aqua-ui/svg';

interface IProps {
  /** Structure to edit (a local copy is kept in state). */
  structure: IStructure;
  /** Fired when form is submitted with valid data. */
  onSubmit: (structure: IStructure) => void;
  /** Fired when edit is cancelled. */
  onCancel: () => void;
}

const EditStructureDialog = (props: IProps) => {
  const theme = useTheme();
  const structureRef = React.useRef({...props.structure});
  const [valid, setValid] = React.useState(true);

  const [diameter, setDiameter] = React.useState(Math.round((props.structure.radius * 2) * 100) / 100);
  const [circumference, setCircumference] = React.useState(Math.round((props.structure.radius * 2 * Math.PI) * 100) / 100);

  const handleValidate = (_valid: boolean) => setValid(_valid);

  const handleChange = (data: IStructure) => {
    if(data.name) structureRef.current.name = data.name;
    if(data.stocking) structureRef.current.stocking = data.stocking;
    if((data as any).diameter) {
      const r = (data as any).diameter / 2;
      structureRef.current.radius = r;
      if(isNaN(r)) {
        setCircumference(0);
      } else {
        setCircumference(Math.round((r * 2 * Math.PI) * 100 )/ 100);
      }
    }
    if((data as any).circumference) {
      const r = (data as any).circumference / (2 * Math.PI);
      structureRef.current.radius = r;
      if(isNaN(r)) {
        setDiameter(0);
      } else {
        setDiameter(Math.round((r * 2) * 100 )/ 100);
      }
    }
  }


  return (
    <Dialog inverted open={true} canClose onClose={props.onCancel}>
      <Dialog.Header>Edit structure</Dialog.Header>
      <Dialog.Content>
        <Form
          data={props.structure}
          onChange={handleChange}
          onValidate={handleValidate}
        >
          <Form.Field
            name="name"
            label="Structure name"
            value={structureRef.current.name}
            hint="Please enter the structure name."
            wrapper='box'
            rules={{
              required: {}
            }}
            control={<Input/>}
          />
          <Form.Field
            name="stocking"
            label="Stocking"
            value={structureRef.current.stocking}
            hint="Please enter the number of animals contained in this structure."
            wrapper='box'
            rules={{
              required: {},
              isInt: {},
              min: { value: 0 },
              max: { value: 10000000 }
            }}
            control={<Input unit="animals"/>}
          />          
          {props.structure.type == 'circle' && 
          <Group>
            <Form.Field
              name="diameter"
              label="Structure diameter"
              value={diameter}
              hint="Please set the structure diameter."
              wrapper='box'
              rules={{
                required: {},
                isFloat: {},
                min: { value: 1 },
                max: { value: 40000000 }
              }}
              control={<Input unit="m"/>}
            />
            <Form.Field
              name="circumference"
              label="Structure circumference"
              value={circumference}
              hint="Please set the structure circumference."
              wrapper='box'
              rules={{
                required: {},
                isFloat: {},
                min: { value: 1 },
                max: { value: 40000000 }
              }}
              control={<Input unit="m"/>}
            />
          </Group>}
        </Form>
      </Dialog.Content>
      <Dialog.Footer align="space-between">
        <PrimaryButton icon={SVG.Icons.Cross} color={theme.colors.primary[4]} onClick={props.onCancel}>Cancel</PrimaryButton>
        <PrimaryButton icon={SVG.Icons.Check} disabled={!valid} onClick={() => props.onSubmit(structureRef.current)}>Update structure</PrimaryButton>
      </Dialog.Footer>
      
    </Dialog>
  )
}

const Group = styled.div`
  display: flex;
  gap: 8px;
  & > * { flex: 1; }
`

export { EditStructureDialog }
