import * as React from 'react';
import styled from 'styled-components';

import { ILayer } from '../../types/ILayer';
import { EyeIcon } from '../EyeIcon';
import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { LayerUtils } from '../../util/LayerUtils';
import { DeleteIcon } from '../../contexts/confirm/DeleteIcon';
import { SVG } from '@longline/aqua-ui/svg';
import { Icon } from '@longline/aqua-ui/controls/Icon';

interface IProps {
  className?: string;
  value: ILayer;
}

const LayerInfoBoxHeaderBase = (props: IProps) => {
  const globalState = useGlobalState();
  const def = LayerUtils.layerTypeToDefinition(props.value.type);

  const handleClose = () => {
    globalState.setActiveLayer(null);
  }

  const handleKeyDown = (ev: KeyboardEvent) => {
    if(ev.code == 'Escape') handleClose();
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);  

  return (
    <div className={props.className}>
      <Block>
        {def.name}
        {def.unit && <Unit>({def.unit})</Unit>}
      </Block>
      <Block>
        <EyeIcon visible={props.value.visible} onClick={() => globalState.toggleLayer(props.value)}/>
        <DeleteIcon confirm title="Delete layer" text="Are you sure you wish to delete this layer?" onClick={() => globalState.deleteLayer(props.value)}/>
        <Icon url={SVG.Icons.Cross} onClick={handleClose}/>
      </Block>
    </div>
  )
}

const Unit = styled.div`
  font-size: 60%;
`

const Block = styled.div`
  display: flex;
  flex-direction: row;  
  justify-content: start;
  gap: 8px;
  align-items: center;
`

const LayerInfoBoxHeaderStyled = styled(LayerInfoBoxHeaderBase)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LayerInfoBoxHeader = (props: IProps) => <LayerInfoBoxHeaderStyled {...props}/>

export { LayerInfoBoxHeader }
