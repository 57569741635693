import * as React from 'react';
import styled from 'styled-components';

import { Input } from '@longline/aqua-ui/inputs/Input';
import { Form } from '@longline/aqua-ui/containers/Form';
import { Number } from '@longline/aqua-ui/formatters/Number';

interface IProps {
  /** Current skiprows value */
  skiprows: string;    
  /** Current reduction value */
  reduction: string;
  /** Total rows in worksheet */
  totalrows: number;
  /** Called whenever form changes. */
  onChange: (skiprows: string, reduction: string, value: boolean) => void;
}

/**
 * OptionsForm presents input boxes for skiprows and reduction, and performs
 * input validation.
 */
const OptionsForm = (props: IProps) => {

  const handleChangeSkiprows = (value: string) => {
    props.onChange(value, props.reduction, 
      isSkiprowsValid(value) == null && isReductionValid(props.reduction) == null);
  }

  const handleChangeReduction = (value: string) => {
    props.onChange(props.skiprows, value, 
      isSkiprowsValid(props.skiprows) == null && isReductionValid(value) == null);
  }

  const isInteger = (value: string): boolean => {
    // Check for integer:
    if(!value) return false;
    const regex = /^[-+]?[0-9]+$/;
    return regex.test(value);
  }

  // Determine whether provided skiprows value is valid.
  // Returns null if valid, or an error string.
  const isSkiprowsValid = (value: string): string => {
    if(!isInteger(value)) return "Number of rows must be whole number.";

    const numval = parseInt(value);
    if(numval < 0) return "Number of rows must be 0 or more.";
    if(numval > 99) return "Number of rows may not exceed 99.";
    return null;
  }

  // Determine whether provided reduction value is valid.
  // Returns null if valid, or an error string.
  const isReductionValid = (value: string) => {
    if(!isInteger(value)) return "Reduction factor must be whole number.";

    const numval = parseInt(value);
    if(numval < 1) return "Reduction factor must be 1 or more.";
    if(numval > 1000) return "Reduction factor may not exceed 1,000.";
    return null;    
  }

  // Calculates number of rows returned after reduction.
  const getReductionText = (): React.ReactNode => {
    const r = parseInt(props.reduction);
    if(r == 1) return "";
    const points = Math.floor(props.totalrows / r);
    return <>A reduction factor of <b>{r}</b> will yield <b><Number value={points} decimals={0}/></b> data points.</>;
  }

  const skiprowsValid = isSkiprowsValid(props.skiprows);
  const reductionValid = isReductionValid(props.reduction);

  return (
    <FormHolder>

      <Form.Field
        label="Skip header rows" 
        name="skiprows"
        wrapper='box'
        value={props.skiprows}
        disabled={props.totalrows == 0}
        rules={{
          isInt: { },
          min: { value: 0 },
          max: { value: 99 },
        }}
        onChange={handleChangeSkiprows}
        hint={skiprowsValid == null ? "Rows containing column headers can be skipped." : skiprowsValid}
        control={<Input fluid type="text" error={skiprowsValid != null} placeholder="Number of rows"/>}
      />
      <Form.Field
        label="Data reduction factor"
        name="reduction"
        wrapper='box'
        value={props.reduction}
        disabled={props.totalrows == 0}
        rules={{
          isInt: { },
          min: { value: 1 },
          max: { value: 1000 },
        }}        
        onChange={handleChangeReduction}
        hint={reductionValid == null ? (props.reduction == "1" ? <>Data density can be reduced by reading only every nth row.</> : getReductionText()) : reductionValid}
        control={<Input fluid type="text" error={reductionValid != null} placeholder="Read every nth row"/>}
      />

    </FormHolder>
  )
}

const FormHolder = styled.div`
  padding: 16px 20px 8px 20px;
  display: flex;
  gap: 16px;  
  & > * { flex: 1 }
`

export { OptionsForm }
