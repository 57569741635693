import * as React from 'react';

import { Dialog } from '@longline/aqua-ui/services/Dialog';
import { PrimaryButton } from '@longline/aqua-ui/controls/PrimaryButton';

import { ConfirmContext } from './ConfirmContext';

interface IProps {
  children?: React.ReactNode;
}

const Confirm = (props: IProps) => {
  const [active, setActive] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [action, setAction] = React.useState(null);

  const start = (action: () => void, title: string, text: string) => {
    setAction(action);
    setTitle(title);
    setText(text);
    setActive(true);
  }

  const doAction = () => {
    action();
    setActive(false);
  }

  return (
    <ConfirmContext.Provider value={{
      start
    }}>
      {props.children}
      <Dialog open={active} canClose={true} onClose={() => setActive(false)}>
        <Dialog.Header>
          {title}
        </Dialog.Header>
        <Dialog.Content>
          {text}
        </Dialog.Content>
        <Dialog.Footer>
          <PrimaryButton onClick={() => setActive(false)}>Cancel</PrimaryButton>
          <PrimaryButton onClick={doAction}>OK</PrimaryButton>
        </Dialog.Footer>
      </Dialog>      
    </ConfirmContext.Provider>
  )
}

export { Confirm }
