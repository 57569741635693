import { IGradientStop } from "@longline/aqua-ui/Types";

class PresetGradients {
  static BlueSolid: IGradientStop[] = [
    { pos: 0.00, color: '#acf0f2ff' },
    { pos: 0.25, color: '#72baccff' },
    { pos: 0.50, color: '#4184a6ff' },
    { pos: 0.75, color: '#1a517eff' },
    { pos: 1.00, color: '#002253ff' },
  ];

  static Blue: IGradientStop[] = [
    { pos: 0.00, color: '#acf0f201' },
    { pos: 0.0001, color: '#acf0f2ff' },
    { pos: 0.25, color: '#72baccff' },
    { pos: 0.50, color: '#4184a6ff' },
    { pos: 0.75, color: '#1a517eff' },
    { pos: 1.00, color: '#002253ff' },
  ];  
  
  static Green: IGradientStop[] = [
    { pos: 0.00, color: '#cae17001' },
    { pos: 0.0001, color: '#cae170ff' },
    { pos: 0.25, color: '#7bca5dff' },
    { pos: 0.50, color: '#4eb274ff' },
    { pos: 0.75, color: '#307c63ff' },
    { pos: 1.00, color: '#1a454fff' },
  ];

  static Red: IGradientStop[] = [
    { pos: 0.00, color: '#45000301' },
    { pos: 0.0001, color: '#450003ff' },
    { pos: 0.25, color: '#6f111aff' },
    { pos: 0.50, color: '#9d262cff' },
    { pos: 0.75, color: '#cd3c3fff' },
    { pos: 1.00, color: '#ff5252ff' },
  ];

  static Mimosa: IGradientStop[] = [
    { pos: 0.00, color: '#daae5101' },
    { pos: 0.0001, color: '#daae51ff' },
    { pos: 1.00, color: '#d53369ff' },    
  ];

  static Rainbow: IGradientStop[] = [
    { pos: 0.00, color: '#00796b01' },
    { pos: 0.0001, color: '#00796bff' },
    { pos: 0.25, color: '#3993cbff' },
    { pos: 0.50, color: '#d494e3ff' },
    { pos: 0.75, color: '#ffb7b2ff' },
    { pos: 1.00, color: '#fff176ff' },
  ];  
  
  static MelloYellow: IGradientStop[] = [
    { pos: 0.00, color: '#3ad59f01' },
    { pos: 0.0001, color: '#3ad59fff' },
    { pos: 1.00, color: '#f8ff00ff' }
  ];

  static DustyCactus: IGradientStop[] = [
    { pos: 0.00, color: '#fcff9e01' },
    { pos: 0.0001, color: '#fcff9eff' },
    { pos: 1.00, color: '#c67700ff' }
  ];  

  static RetroWagon: IGradientStop[] = [
    { pos: 0.00, color: '#FDBB2D01' },
    { pos: 0.0001, color: '#FDBB2Dff' },
    { pos: 1.00, color: '#22C1C3ff' }
  ];    

  static Grey: IGradientStop[] = [
    { pos: 0.00, color: '#ffffff01' },
    { pos: 0.0001, color: '#ffffffff' },
    { pos: 1.00, color: '#000000ff' }
  ]; 

  static CurrentBlue: IGradientStop[] = [
    { pos: 0.0,  color: '#ffffffff' },
    { pos: 0.25, color: '#5c5cffff' },
    { pos: 0.5,  color: '#6363b001' },
    { pos: 1.0,  color: '#6363b001' },
  ];

  static CurrentGrey: IGradientStop[] = [
    { pos: 0.00,  color: '#ffffffff' },
    { pos: 0.25,  color: '#808080ff' },
    { pos: 0.50,  color: '#80808001' },
    { pos: 1.00,  color: '#00000001' },
  ];

  static GreyStepped: IGradientStop[] = [
    { pos: 0,       color: '#00000000' },
    { pos: 0.0001,  color: '#000000ff' },
    { pos: 0.0625,  color: '#000000ff' }, { pos: 0.0625,  color: '#101010ff' },
    { pos: 0.125,   color: '#101010ff' }, { pos: 0.125,   color: '#202020ff' },
    { pos: 0.1875,  color: '#202020ff' }, { pos: 0.1875,  color: '#303030ff' },
    { pos: 0.25,    color: '#303030ff' }, { pos: 0.25,    color: '#404040ff' },
    { pos: 0.3125,  color: '#404040ff' }, { pos: 0.3125,  color: '#505050ff' },
    { pos: 0.375,   color: '#505050ff' }, { pos: 0.375,   color: '#606060ff' },
    { pos: 0.4375,  color: '#606060ff' }, { pos: 0.4375,  color: '#707070ff' },
    { pos: 0.5,     color: '#707070ff' }, { pos: 0.5,     color: '#808080ff' },
    { pos: 0.5625,  color: '#808080ff' }, { pos: 0.5625,  color: '#909090ff' },
    { pos: 0.625,   color: '#909090ff' }, { pos: 0.625,   color: '#a0a0a0ff' },
    { pos: 0.6875,  color: '#a0a0a0ff' }, { pos: 0.6875,  color: '#b0b0b0ff' },
    { pos: 0.75,    color: '#b0b0b0ff' }, { pos: 0.75,    color: '#c0c0c0ff' },
    { pos: 0.8125,  color: '#c0c0c0ff' }, { pos: 0.8125,  color: '#dededeff' },
    { pos: 0.875,   color: '#dededeff' }, { pos: 0.875,   color: '#e0e0e0ff' },
    { pos: 0.9375,  color: '#e0e0e0ff' }, { pos: 0.9375,  color: '#f0f0f0ff' },
    { pos: 1,       color: '#f0f0f0ff' }, { pos: 1,       color: '#ffffffff' },
  ]
}

export { PresetGradients }
