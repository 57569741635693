import * as React from 'react';
import styled from 'styled-components';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { IReference } from '../../types/IReference';
import { EyeIcon } from '../EyeIcon';
import { DeleteIcon } from '../../contexts/confirm/DeleteIcon';
import { Icon } from '@longline/aqua-ui/controls/Icon';
import { SVG } from '@longline/aqua-ui/svg';

interface IProps {
  className?: string;
  value: IReference;
}

const ReferenceInfoBoxHeaderBase = (props: IProps) => {
  const globalState = useGlobalState();

  const handleClose = () => {
    globalState.setSelectedReference(null);
  }

  const handleKeyDown = (ev: KeyboardEvent) => {
    if(ev.code == 'Escape') handleClose();
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  return (
    <div className={props.className}>
      <Block>
        Reference
      </Block>
      <Block>
        <EyeIcon visible={props.value.visible} onClick={() => globalState.toggleReference(props.value)}/>
        <DeleteIcon confirm title="Delete this reference" text="Are you sure you wish to delete this reference?" onClick={() => globalState.deleteReference(props.value)}/>
        <Icon url={SVG.Icons.Cross} onClick={handleClose}/>
      </Block>
    </div>
  )
}

const Block = styled.div`
  display: flex;
  flex-direction: row;  
  justify-content: start;
  gap: 8px;
  align-items: center;
`

const ReferenceInfoBoxHeaderStyled = styled(ReferenceInfoBoxHeaderBase)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ReferenceInfoBoxHeader = (props: IProps) => <ReferenceInfoBoxHeaderStyled {...props}/>

export { ReferenceInfoBoxHeader }
