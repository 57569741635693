import { LngLatBounds, LngLat } from 'mapbox-gl';
import { polygon } from "@turf/helpers";
import { Feature, Polygon } from 'geojson';
import area from "@turf/area";

import { IPoint } from "../types/IPoint";
import { NUM_CIRCLE_POINTS } from '../editors/EditorConfig';
import { IArea } from '../types/IArea';

const TO_RAD = Math.PI / 180;
const EARTH_RADIUS = 6378137;   // Radius of earth in meters

/**
 * The Polygon class provides some simple geometric analysis tools: 
 * * Haversine distance
 * * Add meters to a (lat, lng)
 * * Polygon self-intersection check
 */
class PolygonUtils {
  /**
   * Convert angle degrees to radians.
   * @param degrees Degrees value
   * @returns Radians
   */
  static toRadians = (degrees: number) => TO_RAD * degrees;


  /**
   * Calculates the distance between two points in meters using the [Haversine
   * formula](https://en.wikipedia.org/wiki/Haversine_formula).
   * 
   * @param point1 Source point.
   * @param point2 Destination point.
   * @returns Distance between point 1 and point 2 in meters.
   * 
   * @example
   * ```ts
   * const dist = Polygon.distance({ lng: 0, lat: 0 }, { lng: 1, lat: 0 });
   * ```
   */
  static distance = (point1: IPoint, point2: IPoint) => {
    var dLat = point2.lat * TO_RAD - point1.lat * TO_RAD;
    var dLon = point2.lng * TO_RAD - point1.lng * TO_RAD;
    var a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(point1.lat * TO_RAD) * Math.cos(point2.lat * TO_RAD) *
    Math.sin(dLon/2) * Math.sin(dLon/2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    var d = EARTH_RADIUS * c;
    return d; // meters
  }

  /**
   * Given a point `(lng, lat)`, add `(dx, dy)` meters to its location. Negative
   * values are subtracted.
   * 
   * @param point Source point.
   * @param dx Longitude meters to add.
   * @param dy Latitude meters to add.
   * @returns Returns new point.
   */
  static addMeters = (point: IPoint, dx: number, dy: number): IPoint => {
    return {
      lng: point.lng + (dx / EARTH_RADIUS) * (180 / Math.PI) / Math.cos(point.lat * TO_RAD),
      lat: point.lat + (dy / EARTH_RADIUS) * (180 / Math.PI)
    }
  }

  private static turn = (p1: IPoint, p2: IPoint, p3: IPoint) => {
    const a = p1.lng; const b = p1.lat; 
    const c = p2.lng; const d = p2.lat;
    const e = p3.lng; const f = p3.lat;
    const A = (f - b) * (c - a);
    const B = (d - b) * (e - a);
    return (A > B + Number.EPSILON) ? 1 : (A + Number.EPSILON < B) ? -1 : 0;
  }
  
  private static getLine(points: IPoint[], index: number): IPoint[] {
    const p1 = points[index];
    const p2 = index >= points.length - 1 ? points[0] : points[index+1];
    return [p1, p2];
  }

  /**
   * Does the line (p1,p2) intersect with line (p3,p4)?
   * 
   * @returns `true` if the lines intersect, `false` if the lines do _not_ 
   * intersect.
   */
  static isIntersect = (p1: IPoint, p2: IPoint, p3: IPoint, p4: IPoint) => {
    return (PolygonUtils.turn(p1, p3, p4) != PolygonUtils.turn(p2, p3, p4)) && (PolygonUtils.turn(p1, p2, p3) != PolygonUtils.turn(p1, p2, p4));
  }  

  /**
   * Is the polygon specified by the list of points valid, i.e it does _not_
   * self-intersect?
   * 
   * @param points Polygon points. Do not duplicate the last point.
   * @returns `true` if the polygon is valid (does not self-intersect),
   * `false` if the polygon is _invalid_ (has self-intersections).
   */
  static isValid = (points: IPoint[]): boolean => {
    // For each edge:
    for(let i = 0; i < points.length; i++) {
      const [p1, p2] = PolygonUtils.getLine(points, i);
      // Find non-connected edges:
      for(let j = 0; j < points.length - 3; j++) {
        let s = i + j + 2;
        if(s >= points.length) s -= points.length;
        const [p3, p4] = PolygonUtils.getLine(points, s);
        // Check for intersection:
        if(PolygonUtils.isIntersect(p1,p2,p3,p4)) return false;
      }
    }
    return true;
  }

  /**
   * Creats a Polygon GeoJSON feature from a list of points.
   */
  static createGeoJSON = (points: IPoint[]) => {
    const pts = points.map(p => [p.lng, p.lat]);
    pts.push(pts[0]);
    return polygon([pts]);
  }

  /**
   * Returns the area of a polygon in square meters.
   */
  static getArea = (points: IPoint[]): number => {
    const geojson = PolygonUtils.createGeoJSON(points);
    return area(geojson);
  }

  /**
   * Returns the perimeter of a polygon in meters.
   */
  static getPerimeter = (points: IPoint[]): number => {
    let total = PolygonUtils.distance(points[points.length-1], points[0]);
    for(let i = 0; i < points.length - 1; i++) {
      total += PolygonUtils.distance(points[i], points[i+1]);
    }
    return total;
  }

  /*
   * Returns bounding box for polygon. 
   * Can be passed to map.fitBounds.
   * Form is
   * [
   *   [ minLng, minLat ],
   *   [ maxLng, maxLat ]
   * ]
   */
  static getBbox = (points: IPoint[]): LngLatBounds => {
    const minLng = Math.min(...points.map(p => p.lng));
    const maxLng = Math.max(...points.map(p => p.lng));
    const minLat = Math.min(...points.map(p => p.lat));
    const maxLat = Math.max(...points.map(p => p.lat));
    return new LngLatBounds(
      new LngLat(minLng, minLat),
      new LngLat(maxLng, maxLat),
    );
  }

  /**
   * Returns the centroid of a polygon.
   */
  static getCentroid = (points: IPoint[]): IPoint => {
    const lng = points.map(p => p.lng).reduce((a,p) => a+p) / points.length;
    const lat = points.map(p => p.lat).reduce((a,p) => a+p) / points.length;
    return { lng, lat };
  }

  /**
   * Given a center point and a radius, construct a polygon that approaches a circle.
   */
  static getCirclePolygon = (point: IPoint, radius: number): Feature<Polygon> => {
    // Radius is in meters.
    const points: IPoint[] = [];
    for(let i = 0; i < NUM_CIRCLE_POINTS; i++) {
      const degrees = i * (360 / NUM_CIRCLE_POINTS);
      const rad = PolygonUtils.toRadians(degrees);
      const dx = Math.cos(rad) * radius;
      const dy = Math.sin(rad) * radius;
      points.push(PolygonUtils.addMeters(point, dx, dy));
    }

    // Create a feature collection of exactly one polygon:
    return polygon([points.map(p => [p.lng, p.lat]).concat([[points[0].lng, points[0].lat]])]);
  }


  /**
   * An `IArea` only has a horizontal resolution (in cells). Its vertical resolution
   * must be derived from its lat/lng extents. This requires converting latitudes to
   * distances first.
   * 
   * @param area Area to calculate vertical resolution for.
   * @returns Vertical resolution in cells.
   */
  static getAreaVerticalResolution = (area: IArea) => {
    const width = PolygonUtils.distance(
        { lng: area.rect.minLng, lat: area.rect.minLat}, 
        { lng: area.rect.maxLng, lat: area.rect.minLat});
    const height =PolygonUtils.distance(
        { lng: area.rect.minLng, lat: area.rect.minLat}, 
        { lng: area.rect.minLng, lat: area.rect.maxLat});
    const ratio = Math.abs(height / width);
    return Math.floor(area.resolution * ratio);
  }

}



export { PolygonUtils }
