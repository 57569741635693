import * as React from 'react';
import styled from 'styled-components';
import { Selector } from '@longline/aqua-ui/inputs/Selector';

interface IProps {
  value?: 'positive' | 'negative';
  onChange?: (value: 'positive' | 'negative') => void;
}

const BathymetryFormatControl = (props: IProps) => {
  return (
    <Wrapper>
      <Selector name="positive" label="Navy (positive bathymetry)" checked={props.value == 'positive'} onChange={() => props.onChange('positive')}/>
      <Selector name="negative" label="Army (negative bathymetry)" checked={props.value == 'negative'} onChange={() => props.onChange('negative')}/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 0 8px 12px;
  & span { color: ${p => p.theme.colors.neutral[10]}; }
`

export { BathymetryFormatControl }
