import * as React from 'react';
import styled from 'styled-components';

import { ListView } from '@longline/aqua-ui/controls/ListView/ListView';
import { SecondaryButton } from '@longline/aqua-ui/controls/SecondaryButton';
import { Icon } from '@longline/aqua-ui/controls/Icon';
import { Number } from '@longline/aqua-ui/formatters/Number';
import { SVG } from '@longline/aqua-ui/svg';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { PaneHeader } from '../PaneHeader';
import { IReference } from '../../types/IReference';
import { ReferenceInfoBox } from '../../infoboxes/ReferenceInfoBox';
import { useConfirm } from '../../contexts/confirm/ConfirmContext';
import { Pane } from '../Pane';
import { Guide } from '../../Guide';
import { Key } from '@longline/aqua-ui/controls/Key';

const ReferencePane = () => {
  const confirm = useConfirm();
  const globalState = useGlobalState();

  const handleDeleteAll = () => {
    globalState.clearReferences();
  }

  return (
    <>
      <Pane>
        {/* Title & Add structure buttons */}
        <PaneHeader title="Reference layers" onToggle={globalState.togglePaneExpansion} expanded={globalState.expanded}>
          <SecondaryButton positive icon={SVG.Icons.Plus} onClick={globalState.startReferenceImport}/>
        </PaneHeader>
        {/* References list */}
        <ListView 
          dark 
          data={globalState.references} 
          active={globalState.selectedReference}
          onClick={(reference) => globalState.setSelectedReference(reference)} 
          shadow
          aux={<ReferenceInfoBox/>}
          total
          singular="layer"
          plural="layers"
          noData="There are no reference layers yet."
          rowHeight={56}
        >
          <ListView.Column active width={3} name="Name">{(item: IReference) => item.name}</ListView.Column>
          <ListView.Column active={globalState.expanded} width="80px" name="Features" align="right">{(item: IReference) => <Number value={item.geojson.features.length} decimals={0}/>}</ListView.Column>
          <ListView.Column active width="64px" name="">{(item: IReference) => <Icon url={item.visible ? SVG.Icons.Eye : SVG.Icons.EyeClosed}/>}</ListView.Column>
        </ListView>
        {globalState.references.length > 0 &&
          <FabHolder>
            <SecondaryButton icon={SVG.Icons.Delete} onClick={() => confirm.start(() => handleDeleteAll, "Delete all reference layers", "Are you sure you wish to delete all reference layers?")}/>
          </FabHolder>}
      </Pane>
        {globalState.selectedReference == null && globalState.selectedStructure == null && <Guide x={-64} y={-48} title="Reference layer management">
        <ul>
          <li>Click <Key value="+"/> to create a new layer from a GeoJSON file.</li>
        </ul>
      </Guide>}
      {globalState.selectedReference != null && globalState.selectedStructure == null && <Guide x={-64} y={-48} title="Editing a reference layer">
        <ul>
          <li>Click <Icon url={SVG.Icons.Eye}/> to show or hide a layer.</li>
          <li>Click <Icon url={SVG.Icons.Delete}/> to remove a layer from the map.</li>
          <li>Select a GeoJSON property to render with color classification.</li>
          <li>Edit opacity and gradient to change layer visualization.</li>
        </ul>
      </Guide>}    
    </>
  )
}

const FabHolder = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
`

export { ReferencePane }
