// Tile size. DEM tiles@2x are 512x512.
const TILE_SIZE = 512;

/**
 * The `DigitalElevationModel` allows querying elevation for (lat,lng) positions from the
 * Mapbox DEM without a map.
 * 
 * Usage:
 * Construct an instance of DigitalElevationModel with the horizontal resolution of the area of 
 * interest, in cells. The vertical resolution is assumed to be in the same order of magnitude.
 * Also provide a width in degrees of longitude of the area of interest:
 * 
 * new DigitalElevationModel(
 *   100 // number of cells (horizontally) in area
 *   0.1 // area covers 0.1 degrees of longitude
 * );
 * 
 * This information is used to calculate the zoom level at which digital elevation model tiles
 * must be downloaded.
 * 
 * With the instance constructed, call queryElevation(lng, lat) to obtain elevation (in meters)
 * at a point.
 * 
 * The establishment of a minimum required zoom level to obtain at least one distinct pixel
 * per area cell minimizes the number of tiles that need to be downloaded. Downloaded tiles
 * are kept in a cache, since many elevation requests will hit the same tile.
 */
class DigitalElevationModel {

  private cache: { [key: string]: Uint8ClampedArray };
  private zoom: number;
  private access_token: string;

  /**
   * 
   * @param resolution Horizontal resolution, in cells, of the area of interest, e.g. `100`
   * @param widthInDegrees Width in longitude degrees of the area of interest, e.g. `0.1` degrees
   * @param access_token Mapbox access token
   */
  constructor(resolution: number, widthInDegrees: number, access_token: string) {
    // Clear cache:
    this.cache = {};
    this.access_token = access_token;
    // Establish zoom level at which tiles must be retrieved:
    this.zoom = Math.min(15, this.calcZoom(resolution, widthInDegrees));
  }

  private loadImage = (url: string): Promise<ImageBitmapSource> => {
    return new Promise((res, rej) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.src = url;
      img.onload = e => res(img);
      img.onerror = rej;
    });
  }  

  // https://stackoverflow.com/questions/55892083/javascript-load-image-into-offscreen-canvas-perform-webp-conversion
  private urlToCanvas = async (url: string): Promise<Uint8ClampedArray> => {
    // Load <img> from server:
    let img = await this.loadImage(url);
    // Create bitmap from image element.
    let bmp: ImageBitmap = await createImageBitmap(img as any);
    // Create canvas:
    const ctx = Object.assign(document.createElement('canvas'), { width: TILE_SIZE, height: TILE_SIZE }).getContext('2d');
    ctx.drawImage(bmp, 0, 0);
    return ctx.getImageData(0, 0, TILE_SIZE, TILE_SIZE).data;
  }

  /**  
   * Convert RGB value to elevation in meters.
   * See https://docs.mapbox.com/data/tilesets/reference/mapbox-terrain-dem-v1/#elevation-data
   * 
   * @param data Tile image
   * @param pixelX Pixel x-position within tile
   * @param pixelY Pixel y-position within tile
   * @returns Elevation in meters
   */
  private readElevation = (data: Uint8ClampedArray, pixelX: number, pixelY: number): number => {
    const offset = pixelY * TILE_SIZE * 4 + pixelX * 4;
    const r = data[offset];
    const g = data[offset + 1];
    const b = data[offset + 2];
    return -10000 + ((r * 256 * 256 + g * 256 + b) * 0.1);
  }

  /**
   * Given a horizontal export cells resolution and an export rectangle
   * width in degrees lontigude, returns a zoom level at which each cell
   * corresponds to a different pixel in DEM tiles.
   *
   * SAMPLE CALCULATION
   * Resolution    100  cells            
   * minLng          1  deg            
   * maxLng        1.5  deg 
   * LngWidth      0.5  deg            
   * LngPerCell  0.005                
   *                     
   * Zoom  Tiles wide  Pixels wide    PixelsPerLngDegree    Pixels in cell
   * z       2^z         2^z * 512    2 ^ z * 512 / 360     / lngPerCell   
   * 0         1               512          1.422222222     0.007111111
   * 1         2              1024          2.844444444     0.014222222
   * 2         4              2048          5.688888889     0.028444444
   * 3         8              4096          11.37777778     0.056888889
   * 4        16              8192          22.75555556     0.113777778
   * 5        32             16384          45.51111111     0.227555556
   * 6        64             32768          91.02222222     0.455111111
   * 7       128             65536          182.0444444     0.910222222
   * 8       256            131072          364.0888889     1.820444444
   * 9       512            262144          728.1777778     3.640888889
   *
   * @param resolution Horizontal area resolution in cells
   * @param widthInDegrees Area width in degrees of longitude
   * @returns Minimum zoom level
   */
  private calcZoom = (resolution: number, widthInDegrees: number): number => {
    const cellWidthInDegrees = widthInDegrees / resolution;
    let z = 0;
    let pixelsPerCell;
    do {
      pixelsPerCell = Math.pow(2, z) * TILE_SIZE / 360 * cellWidthInDegrees;
      z++;
    } while(pixelsPerCell < 1);

    return z;
  }  

  /**
   * Query elevation at (lng, lat) @ zoom. 
   * This will download the appropriate Mapbox DEM tile. Tiles are cached so they do not
   * get re-downloaded for repeated requests to the same tile.
   * 
   * @param lng Longitude in degrees
   * @param lat Latitude in degrees
   * @returns Elevation in meters at (lng, lat).
   */ 
  public queryElevation = async (lng: number, lat: number) => {
    // Number of tiles along the side of the map (horizontally or vertically):
    const numTiles = Math.pow(2, this.zoom);
    
    // Convert longitude to tileX, pixelX
    const hor = (lng + 180) / 360 * numTiles;
    const tileX = Math.floor(hor);
    const pixelX = Math.floor((hor - tileX) * TILE_SIZE);

    // Convert latitude to tileY, pixelY
    // const vert = (-lat + 90) / 180 * numTiles;
    const vert = (1-Math.log(Math.tan(lat*Math.PI/180) + 1/Math.cos(lat*Math.PI/180))/Math.PI)/2 *numTiles;
    const tileY = Math.floor(vert);
    const pixelY = Math.floor((vert - tileY) * TILE_SIZE);

    // Make unique cache key.
    const key = `${this.zoom}-${tileX}-${tileY}`;

    // If tile in cache, use it:
    if(this.cache[key]) {
      return this.readElevation(this.cache[key], pixelX, pixelY);
    }

    // Tile not cached. Download it first, then use it.
    const pixelArray = await this.urlToCanvas(`https://api.mapbox.com/v4/mapbox.terrain-rgb/${this.zoom}/${tileX}/${tileY}@2x.pngraw?access_token=${this.access_token}`);
    this.cache[key] = pixelArray;

    return this.readElevation(pixelArray, pixelX, pixelY);
  }

  /**
   * Returns the current cache size. This will ordinarily be around 4.
   * @returns Number of tiles in cache.
   */
  public getCacheSize = (): number => {
    return Object.keys(this.cache).length;
  }

  /**
   * Retrieve zoom level DEM will run at.
   * @returns Zoom level
   */
  public getZoom = (): number => {
    return this.zoom;
  }
}

const testDEM = async () => {
  const canvas = document.createElement('canvas');
  canvas.style.left="120px";
  canvas.style.bottom="20px";
  canvas.width = 100;
  canvas.height = 100;
  canvas.style.width = '200px';
  canvas.style.height = '200px';
  canvas.style.backgroundColor = 'red';
  canvas.style.position = 'absolute';
  canvas.style.zIndex = '10000';
  canvas.style.border = 'solid 1px #aaa';
  document.querySelector('body').appendChild(canvas);
  const ctx = canvas.getContext("2d");

  const cLng = -64.62972;
  const cLat = 44.03980;
  const offset = 0.01;
  const minLng = cLng - offset;
  const maxLng = cLng + offset;
  const minLat = cLat - offset;
  const maxLat = cLat + offset;

  const dLng = Math.abs(maxLng - minLng);

  const dem = new DigitalElevationModel(100, dLng, "pk.eyJ1IjoibG9uZ2xpbmVlbnZpcm9ubWVudCIsImEiOiJjbDdldTJhMmYwM3VlM3lvMW5mcTFyOWlnIn0.LzghnMQjPdtw795xN-BCWg");

  for(let y = 0; y < 100; y++) {
    const lat = (Math.abs(maxLat - minLat) / 100 * y + minLat);
    for(let x = 0; x < 100; x++) {
      const lng = Math.abs(maxLng - minLng) / 100 * x + minLng;
      const elevation = await dem.queryElevation(lng, lat);
      const color = elevation > 0 ? 255 : 0;
      // const color = elevation / 4000 * 255;
      ctx.fillStyle = `rgba(${color},${color},${color}, 1.0)`;
      ctx.fillRect(x, 99-y, 1,1);
    }
  }

  console.log("Cache size", dem.getCacheSize());
}

// Zoom 8, 84 not found.

export { DigitalElevationModel, testDEM }
