import * as React from 'react';
import styled from 'styled-components';

import { useGlobalState } from '../contexts/state/GlobalStateContext';
import { Legend } from './Legend';
import { ELayerType } from '../types/ELayerType';

interface IProps {
  /** @ignore */
  className?: string;
}

const LegendsBase = (props: IProps) => {
  const globalState = useGlobalState();

  const legends = React.useMemo(() => {
    return globalState.layers
      .filter(layer => layer.visible && layer.type != ELayerType.CurrentSpeed)
      .map((layer, index) => <Legend key={index} layer={layer}/> );
   }, [globalState.layers]);  

  return (
    <div className={props.className}>
      <ProjectName>{globalState.project.name ?? 'Unnamed project'}{globalState.project.species ? <> / {globalState.project.species}</> : null}</ProjectName>
      <List>
        {legends}
      </List>
    </div>
  )
}

const ProjectName = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 140%;
  margin-bottom: 8px;
`

const List = styled.div`
  width: 50vw;
  display: flex;
  flex-direction: column;
  gap: 4px;  
`

const LegendsStyled = styled(LegendsBase)`
  position: absolute;
  left: 20px;
  bottom: 20px;  
  text-shadow: 0px 0px 4px rgba(0,0,0,1.0), 0px 0px 4px rgba(0,0,0,1.0), 0px 0px 4px rgba(0,0,0,1.0);
  user-select: none;
`

const Legends = (props: IProps) => <LegendsStyled {...props}/>

export { Legends }
