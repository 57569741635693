import * as React from 'react';

import { ZoomInButton } from '@longline/aqua-ui/map/controls/ZoomInButton';
import { ZoomOutButton } from '@longline/aqua-ui/map/controls/ZoomOutButton';
import { MapButtonGroup } from '@longline/aqua-ui/map/controls/base/MapButtonGroup';
import { ScaleControl } from '@longline/aqua-ui/map/controls/ScaleControl';
import { Geocoder } from '@longline/aqua-ui/map/controls/Geocoder';
import { FullscreenButton } from '@longline/aqua-ui/map/controls/FullscreenButton';
import { useConfig } from '../services/config/IConfig';
import { AddCircularStructureButton } from './AddCircularStructureButton';
import { AddPolygonStructureButton } from './AddPolygonStructureButton';
import { ExportImageButton } from './ExportImageButton';

const MapControls = () => {
  const config = useConfig();

  return (
    <>
      <MapButtonGroup x={-16} y={-48}>
        <ZoomInButton/>
        <ZoomOutButton/>
      </MapButtonGroup>
      <FullscreenButton x={-16} y={-120}/>
      <MapButtonGroup x={-16} y={-159}>
        <AddCircularStructureButton x={-16} y={-159}/>
        <AddPolygonStructureButton x={-16} y={-198}/>
      </MapButtonGroup>
      <ExportImageButton x={-16} y={-231}/>
      <ScaleControl x={-16} y={-16}/>
      <Geocoder searchIcon x={16} y={16} access_token={config.mapboxKey}/>
    </>
  );
}

export { MapControls }
