import * as React from 'react';
import { useMap } from 'react-map-gl';
import { saveAs } from 'file-saver';

import { useToast } from '@longline/aqua-ui/services/Toast/useToast';
import { IMapButtonProps, MapButton } from '@longline/aqua-ui/map/controls/base/MapButton';

import { Sprites } from '../svg';

const ExportImageButton = (props: IMapButtonProps) => {
  const map = useMap();
  const toastProvider = useToast();

  const handleExport = () => {
    map.current.getCanvas().toBlob(blob => saveAs(blob, 'image.png'));
    toastProvider.toast("Image saved.", {});     
  }

  return (
    <MapButton 
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        handleExport();
      }} 
      hint="Export map as image"
      {...props}
    >
      <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <use xlinkHref={Sprites.Icons.Photo}></use>
      </svg>
    </MapButton>
  );
}

export { ExportImageButton }
