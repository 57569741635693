interface IProject {
  name: string;
  species: string;
  structureDepth: number;
  stocking: number;
}

const DefaultProject: IProject = {
  name: "Unnamed farm",
  species: "Atlantic Salmon",
  structureDepth: 0,
  stocking: 50000
}

export { IProject, DefaultProject }

