import * as React from 'react';
import styled from 'styled-components';

import { useGlobalState } from '../contexts/state/GlobalStateContext';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const PaneBase = (props: IProps) => {
  const globalState = useGlobalState();

  return (
    <div className={props.className} style={{ width: `${globalState.expanded ? 600 : 350}px` }}>
      {props.children}
    </div>
  );
}

const PaneStyled = styled(PaneBase)`
  position: absolute;
  left: 80px;
  top: 0;
  bottom: 0;
  background: #333;
  box-shadow: 1px 0px 15px rgba(0,0,0,1.0);
  z-index: 101;
  transition: width ease-in-out ${p => p.theme.animation.duration}ms;
  display: flex;
  flex-direction: column;  
`

const Pane = (props: IProps) => <PaneStyled {...props}/>

export { Pane }
