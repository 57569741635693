import * as React from 'react';

import { useGlobalState } from '../contexts/state/GlobalStateContext';
import { LayerUtils } from '../util/LayerUtils';
import { LayerFactory } from '../LayerFactory';

const DataLayers = () => {
  const globalState = useGlobalState();

  // Layers are implemented as a Memo, so that they don't get reinstantiated 
  // whenever other props change.
  const layers = React.useMemo(() => {
    return [...globalState.layers] // must make copy because sort is in-place
      .sort((a,b) => {
        const zA = LayerUtils.layerTypeToDefinition(a.type).zIndex;
        const zB = LayerUtils.layerTypeToDefinition(b.type).zIndex;
        return zA - zB;
      })
      .filter(layer => layer.visible)
      .map((layer, index) => LayerFactory.getLayer(index, layer) );
  }, [globalState.layers]);  

  return layers;
}

export { DataLayers }
