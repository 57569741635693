import * as React from 'react';
import { IGradientStop } from "@longline/aqua-ui/Types";
import { PresetGradients } from './PresetGradients';

interface ILayerTypeDefinition {
  /** Layer name */
  name: string;
  /** Layer unit */
  unit: React.ReactNode;
  /** Number of decimals to print in values. */
  decimals: number;
  /** Layer zIndex. Guarantees that bathymetry is under everything else. */
  zIndex: number;
  /** Available gradients */
  gradients: IGradientStop[][];
  /** (Interpolation layers): contour line frequency. 0 = no contour lines. */
  contours?: number;
  /** (Current velocity layers): Particle trail length */
  trail?: number;
  /** (Current velocity layers): Particle density */
  density?: number;
  /** (Current velocity layers): Particle point size */
  pointSize?: number;
  /** (Current velocity layers): Particle animtion delay factor (0 = no delay) */
  delay?: number;  
}

const DefaultLayerDefinitions: ILayerTypeDefinition[] = [ // indexed by ELayerType enum
  {
    name: 'Bathymetry', 
    unit: 'm',
    decimals: 2,
    zIndex: 0,
    contours: 8,
    gradients: [
      PresetGradients.BlueSolid, PresetGradients.Grey, PresetGradients.Rainbow
    ]
  }, {
    name: 'Current velocity', 
    unit: 'm s-1',
    decimals: 4,
    zIndex: 100,
    contours: 8,
    gradients: [
      PresetGradients.CurrentBlue, PresetGradients.CurrentGrey
    ]
  }, {
    name: 'Daily POC', 
    unit: 'gc m-2 d-1',
    decimals: 4,
    zIndex: 10,
    contours: 0,
    gradients: [
      PresetGradients.Rainbow, PresetGradients.Grey, PresetGradients.Red
    ]    
  }, {
    name: 'Yearly POC', 
    unit: 'gc m-2 y-1',
    decimals: 4,
    zIndex: 10,
    contours: 0,
    gradients: [
      PresetGradients.Rainbow, PresetGradients.Grey, PresetGradients.Red
    ]    
  }, {
    name: 'Oxygen', 
    unit: 'mg l-1',
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.Red, PresetGradients.Grey, PresetGradients.Mimosa, PresetGradients.Rainbow
    ]    
  }, {
    name: 'Ammonia', 
    unit: <>&micro;mol l-1</>,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.Green, PresetGradients.Grey, PresetGradients.MelloYellow, PresetGradients.Rainbow
    ]    
  }, {
    name: 'Chlorophyll', 
    unit: <>&micro;g l-1</>,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow
    ]    
  }, {
    name: 'Pathogen', 
    unit: <>%</>,
    decimals: 2,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.Mimosa, PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow, PresetGradients.GreyStepped
    ]    
  }, {
    name: 'POC in sediment', 
    unit: null,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.RetroWagon, PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow
    ]    
  }, {
    name: 'POM in sediment', 
    unit: null,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.RetroWagon, PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow
    ]    
  }, {
    name: 'Bacteria in sediment', 
    unit: null,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.RetroWagon, PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow
    ]    
  }, {
    name: 'Oxygen in sediment', 
    unit: null,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.RetroWagon, PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow
    ]    
  }, {
    name: 'Sulphate in sediment', 
    unit: null,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.RetroWagon, PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow
    ]    
  }, {
    name: 'Iron hydroxide in sediment', 
    unit: null,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.RetroWagon, PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow
    ]    
  }, {
    name: 'Total sulphide in sediment', 
    unit: null,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.RetroWagon, PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow
    ]    
  }, {
    name: 'Iron in sediment', 
    unit: null,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.RetroWagon, PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow
    ]    
  }, {
    name: 'Iron sulphide in sediment', 
    unit: null,
    decimals: 4,
    zIndex: 5,
    contours: 0,
    gradients: [
      PresetGradients.RetroWagon, PresetGradients.Green, PresetGradients.Grey, PresetGradients.Rainbow
    ]    
  }
]

export { ILayerTypeDefinition, DefaultLayerDefinitions, PresetGradients }

