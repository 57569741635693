import * as React from 'react';

import { IMapButtonProps, MapButton } from '@longline/aqua-ui/map/controls/base/MapButton';

import { Sprites } from '../svg';
import { useGlobalState } from '../contexts/state/GlobalStateContext';

const ToggleStructuresButton = (props: IMapButtonProps) => {
  const globalState = useGlobalState();

  return (
    <MapButton 
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        globalState.setShowStructures(!globalState.showStructures);
      }} 
      hint="Toggle structures display"
      active={globalState.showStructures}
      {...props}
    >
      <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <use xlinkHref={Sprites.Icons.Circle}></use>
      </svg>
    </MapButton>
  );
}

export { ToggleStructuresButton }
