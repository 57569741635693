import * as React from 'react';

interface IConfirmContext {
  start: (action: () => void, title: string, text: string) => void;
}

const ConfirmContext = React.createContext<IConfirmContext>(null);

const useConfirm = () => {
  return React.useContext(ConfirmContext);
}

export { IConfirmContext, ConfirmContext, useConfirm }
