import * as React from 'react';

import { Icon } from '@longline/aqua-ui/controls/Icon';
import { SVG } from '@longline/aqua-ui/svg';

interface IProps {
  visible: boolean;
  onClick?: () => void;
}

const EyeIcon = (props: IProps) =>
  <Icon 
    onClick={props.onClick}
    title={props.visible ? "Layer is visible" : "Layer is hidden"} 
    url={props.visible ? SVG.Icons.Eye : SVG.Icons.EyeClosed}
  />

export { EyeIcon }
