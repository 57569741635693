import * as React from 'react';

import { Icon } from '@longline/aqua-ui/controls/Icon';
import { SVG } from '@longline/aqua-ui/svg';
import { useConfirm } from './ConfirmContext';

interface IProps {
  /** Should deletion be confirmed? */
  confirm?: boolean;
  /** Tooltip and dialog title. */
  title: string;
  /** Dialog body text. */
  text: string;
  /** Fired when delete button is clicked (and confirmed, if necessary). */
  onClick: () => void;
}

/**
 * A `DeleteIcon` is an icon that, when clicked, either just runs an onClick event
 * (if confirm is false) or shows a confirmation dialog (if confirm is true) to confirm
 * a deletion operation.
 */
const DeleteIcon = (props: IProps) => {
  const dialog = useConfirm();

  const handleDelete = () => {
    props.onClick();
  }

  const handleClick = () => {
    if(props.confirm) {
      dialog.start(() => handleDelete, props.title, props.text);
    } else {
      handleDelete();
    }
  }

  return (
    <Icon 
      onClick={handleClick}
      title={props.title}
      url={SVG.Icons.Delete}
    />
  );
}

export { DeleteIcon }
