import * as React from 'react';
import { Layer, MapMouseEvent, Source, useMap } from 'react-map-gl';
import { FeatureCollection } from 'geojson';
import { featureCollection } from '@turf/helpers';
import { Mouse } from '@longline/aqua-ui/controls/Mouse';
import { Key } from '@longline/aqua-ui/controls/Key';

import { POLYGON_FILL_COLOR_VALID, POLYGON_LINE_COLOR_VALID } from '../EditorConfig';
import { PolygonUtils } from '../../util/PolygonUtils';
import { IPoint } from '../../types/IPoint';
import { Guide } from '../../Guide';


interface IProps {
  /** Fired when circle builder is cancelled. */
  onCancel: () => void;
  /** Fired when circle builder completes. */
  onComplete: (points: IPoint, radius: number) => void;
}

const CircleBuilder = (props: IProps) => {
  const { current: map } = useMap();
  // Current point position
  const pointRef = React.useRef<IPoint>(null);
  // Current radius
  const radiusRef = React.useRef<number>(0);
  // Radius state; update to cause component to rerender.
  const [radius, setRadius] = React.useState<number>(null);

  const handleKeydown = (e: KeyboardEvent) => {
    switch(e.code) {
      case 'Enter':
      case 'NumpadEnter':
        submit();
        break;
      case 'Escape':
        props.onCancel();
        break;
    }
  }  

  const handleMouseMove = (e: MapMouseEvent) => {
    if(pointRef.current === null) return;
    radiusRef.current = PolygonUtils.distance(pointRef.current, e.lngLat)
    setRadius(radiusRef.current);
  }

  const handleClick = (e: MapMouseEvent) => {
    e.preventDefault();
    if(pointRef.current === null) {
      pointRef.current = { lng: e.lngLat.lng, lat: e.lngLat.lat };
      setRadius(0);
    } else {
      submit();
    }
  }

  const submit = () => {
    if(pointRef.current !== null) {
      props.onComplete(pointRef.current, radiusRef.current);
    }
  }

  const mount = () => {
    // Start listening to keyboard:
    document.addEventListener('keydown', handleKeydown);         
    map.on('mousemove', handleMouseMove);
    map.on('click', handleClick);
    map.getCanvas().style.cursor = 'crosshair';
    pointRef.current = null;
  }  

  const unmount = () => {
    // Stop listing to keyboard:
    document.removeEventListener('keydown', handleKeydown);      
    map.off('mousemove', handleMouseMove);
    map.off('click', handleClick);
    map.getCanvas().style.cursor = '';
  }

  React.useEffect(() => {
    mount();
    return unmount
  }, []);

  // Create a circle of NUM_CIRCLE_POINTS points.
  const getCircleJSON = (): FeatureCollection => {
    if(!pointRef.current) return null;
    return featureCollection([PolygonUtils.getCirclePolygon(pointRef.current, radius)]);
  }  

  return (
    <>
      {pointRef.current == null && <Guide x={-64} y={-48} title="Adding a circular structure">
        To add a circular structure, move the cursor to the structure's center point. 
        Click <Mouse size={14} left/> the mouse once.
      </Guide>}
      {pointRef.current != null && <Guide x={-64} y={-48} title="Adding a circular structure">
        Move the mouse to set a radius, then click <Mouse size={14} left/> or <Key value="ENTER"/> to confirm. <Key value="ESC"/> cancels.
      </Guide>}      
      <Source type="geojson" data={getCircleJSON()}>
        <Layer 
          type="fill"
          paint={{
            "fill-color": POLYGON_FILL_COLOR_VALID,
            "fill-opacity": 0.2
          }}
        />        
        <Layer 
          type="line"
          paint={{
            "line-color": POLYGON_LINE_COLOR_VALID,
            "line-width": 1.5,
            "line-dasharray": [ 2, 1 ]
          }}
        />
      </Source>
    </>
  );
}

export { CircleBuilder }
