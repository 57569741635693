import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;

  logoUrl: string;
  help?: React.ReactNode;
}

const PublicRouteBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Content>
        {props.children}
      </Content>
    </div>
  );
}

const Content = styled.div`
  position: absolute;
  width: 400px;
  max-width: 90vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

const PublicRouteStyled = styled(PublicRouteBase)`
  position:    absolute;
  left:        0;
  top:         0;
  right:       0;
  bottom:      0;
  overflow:    hidden;  
  background:  conic-gradient(from 0deg at 0 0, #071119 0deg, #071119 90deg, #243961 180deg, #243961 -1deg);
  background-blend-mode: multiply;  
`

const PublicRoute = (props: IProps) => <PublicRouteStyled {...props}/>

export { PublicRoute }
