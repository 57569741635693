import * as React from 'react';
import styled from 'styled-components';

import { useGlobalState } from './contexts/state/GlobalStateContext';
import { Nav } from './Nav';
import { MapView } from './map/MapView';
import { InfoDialog } from './dialogs/Info/InfoDialog';
import { LayerPane } from './panes/LayerPane';
import { StructurePane } from './panes/StructurePane';
import { ReferencePane } from './panes/ReferencePane';
import { ProjectPane } from './panes/ProjectPane';
import { SamplePane } from './panes/SamplePane';
import { AreaPane } from './panes/AreaPane';

interface IProps {
  /** @ignore */
  className?: string;
}

const RootBase = (props: IProps) => {
  const globalState = useGlobalState();

  return (
    <div className={props.className}>
      <Nav/>
      {globalState.mode == 'project' && <ProjectPane/>}
      {globalState.mode == 'layers' && <LayerPane/>}
      {globalState.mode == 'structures' && <StructurePane/>}
      {globalState.mode == 'references' && <ReferencePane/>}
      {globalState.mode == 'areas' && <AreaPane/>}
      {globalState.mode == 'samples' && <SamplePane/>}
      {globalState.mode == 'info' && <InfoDialog/>}
      <MapView/>      
    </div>
  )
}

const RootStyled = styled(RootBase)`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`

const Root = (props: IProps) => <RootStyled {...props}/>

export { Root }
