export enum Icons {
  AddCircle = "/sprites.svg#add-circle",
  AddPolygon = "/sprites.svg#add-polygon",
  Analysis = "/sprites.svg#analysis",
  Cages = "/sprites.svg#cages",
  Circle = "/sprites.svg#circle",
  CircleNotch = "/sprites.svg#circle-notch",
  GeoLayers = "/sprites.svg#geo-layers",
  Grid = "/sprites.svg#grid",
  Import = "/sprites.svg#import",
  Layers = "/sprites.svg#layers",
  Photo = "/sprites.svg#photo",
  Polygon = "/sprites.svg#polygon",
  Project = "/sprites.svg#project",
  SampleSystems = "/sprites.svg#sample-systems",
  Structures = "/sprites.svg#structures",
  Warning = "/sprites.svg#warning"
}
