import * as React from 'react';
import styled from 'styled-components';
import { Layer, Source } from 'react-map-gl/mapbox';
import { TertiaryButton } from '@longline/aqua-ui/controls/TertiaryButton';
import { GlassPane } from '@longline/aqua-ui/containers/GlassPane';

import { IArea } from '../types/IArea';
import { Export } from '../Export';
import { useGlobalState } from '../contexts/state/GlobalStateContext';
import { ELayerType } from '../types/ELayerType';
import { useConfig } from '../services/config/IConfig';
import { PolygonUtils } from '../util/PolygonUtils';
import { Progress } from '@longline/aqua-ui/controls/Progress';

interface IProps {
  area: IArea;
  onClose: () => void;
}

const OrganixExportLayer = (props: IProps) => {
  const config = useConfig();
  const globalState = useGlobalState();
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  // Make a deep copy of area, so it cannot be changed.
  const areaRef = React.useRef<IArea>(JSON.parse(JSON.stringify(props.area)));
  
  const [canvasReady, setCanvasReady] = React.useState(false);
  // Current export progress percentage:
  const [progress, setProgress] = React.useState<number>(0);
  // Is export complete?
  const [complete, setComplete] = React.useState(false);

  const handleProgress = (percentDone: number) => {
    setProgress(percentDone);
  }

  const handleComplete = () => {
    setComplete(true);
  }

  const getFactor = () => {
    let factor = 1;
    let width = areaRef.current.resolution * factor;
    while(width < 200) {
      factor++;
      width = areaRef.current.resolution * factor;
    }
    return factor;
  }

  // Canvas is not immediately ready on first render.
  // Use local state to sigal readiness to <Source>.
  React.useEffect(() => {
    setCanvasReady(true);
    const ctx = canvasRef.current.getContext('2d');
    // TODO: Warn about missing depth or current layers.
    const exp = new Export(ctx, 
      globalState.project, globalState.structures, areaRef.current, 
      globalState.layers.find(l => l.type == ELayerType.Depth), 
      globalState.layers.find(l => l.type == ELayerType.CurrentSpeed), 
      getFactor(), config.mapboxKey, handleProgress, handleComplete);
    exp.run();
  }, []);



  return (
    <>
      <canvas 
        ref={canvasRef}
        style={{ imageRendering: 'pixelated', visibility: 'hidden' }}
        width={areaRef.current.resolution * getFactor()} height={PolygonUtils.getAreaVerticalResolution(areaRef.current) * getFactor()}
      />
      {canvasReady === true && <Source 
        type="canvas" 
        canvas={canvasRef.current}
        animate
        coordinates={[
          [areaRef.current.rect.minLng, areaRef.current.rect.minLat],
          [areaRef.current.rect.maxLng, areaRef.current.rect.minLat],
          [areaRef.current.rect.maxLng, areaRef.current.rect.maxLat],
          [areaRef.current.rect.minLng, areaRef.current.rect.maxLat]
        ]}
      >
        <Layer type="raster"/>
      </Source>}
      <ReportHolder>
        <GlassPane bordered>
          <ReportInner>
            {complete == false && <>
              Exporting ORGANIX data...
              <Progress numbered value={progress}/>
            </>}
            {complete == true && <>
              ORGANIX export complete. 
              <TertiaryButton onClick={props.onClose}>Close</TertiaryButton>
            </>}
          </ReportInner>
        </GlassPane>
      </ReportHolder>
    </>
  )
}

const ReportHolder = styled.div`
  position: absolute;
  bottom: 100px;
  left: 50%;
  translate: -50% 0%;
  border-radius: 20px;
  width: 250px;
`

const ReportInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  padding: 16px;
`

export { OrganixExportLayer }
