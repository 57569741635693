import * as XLSX from 'xlsx';

import { TCellValue } from './TCellValue';

type TSheetToArrayResult = {
  data: TCellValue[][];   // Data in worksheet, as array of rows (up to max rows requested)
  totalRows: number;      // Total rows in worksheet
  skiprows: number;       // Number of header rows sheet reader thinks should be skipped
  longitude: number;      // Column that reader thinks is longitude
  latitude: number;
  depth: number;
  eastspeed: number;
  northspeed: number;
  dailyPOC: number;
  yearlyPOC: number;
  oxygen: number;
  ammonia: number;
  chlorophyll: number;
  pathogen: number;
  pocInSediment: number;
  pomInSediment: number;
  bacteriaInSediment: number;
  oxygenInSediment: number;
  sulphateInSediment: number;
  ironHydroxideInSediment: number;
  totalSulphideInSediment: number;
  ironInSediment: number;
  ironSulphideInSediment: number;
}

/**
 * Reads data from an Excel worksheet and returns it as an array.
 * @param workbook Workbook to read from
 * @param sheetIndex Worksheet index
 * @param maxrows Read up to this many rows. Reads all rows if omitted.
 * @returns Returns an object containing an array of rows, containing arrays 
 * of cells. Each cell is a string, a number, a boolean or an object. The object
 * also contains a suggested number of rows to skip.
 */
const sheetToArray = (workbook: XLSX.WorkBook, sheetIndex: number, maxrows?: number): TSheetToArrayResult => {
  // Get sheet from workbook:
  const sheet: XLSX.WorkSheet = workbook.Sheets[workbook.SheetNames[sheetIndex]];

  // Convert sheet content to 2D array:
  const arr: (string|number)[][] = XLSX.utils.sheet_to_json(sheet, { raw: true, header: 1 });

  // Determine column count. This is the maximum length of all the rows.
  const columnCount = Math.max(...arr.map(row => row.length));

  // Clamp maxrows:
  if(!maxrows) maxrows = arr.length;
  if(maxrows > arr.length) maxrows = arr.length;

  let data: TCellValue[][] = [];
  arr.forEach((row, rowNumber) => {
    // Stop if desired number of rows reached:
    if(rowNumber > maxrows) return data;

    let dataRow: TCellValue[] = [];
    for(let i = 0; i < columnCount; i++) {
      const cell = i >= row.length ? null : row[i];
      // Empty cell?
      if(cell == null) dataRow.push(null);
      // Cell with value:
      else dataRow.push(cell);
    }
    data.push(dataRow);
  });

  // Go through first column to guess number of rows to skip:
  let skiprows = 0;
  for(let i = 0; i < data.length; i++) {
    if(typeof(data[i][0]) == 'number') break;
    skiprows++;
  }

  // If no data found:
  if(data.length == 0) {
    return {
      data,
      totalRows: arr.length,
      skiprows,
      longitude: null,
      latitude: null,
      eastspeed: null,
      northspeed: null,
      depth: null, 
      dailyPOC: null,
      yearlyPOC: null,
      oxygen: null,
      ammonia: null,
      chlorophyll: null,
      pathogen: null,
      pocInSediment: null,
      pomInSediment: null,
      bacteriaInSediment: null,
      oxygenInSediment: null,
      sulphateInSediment: null,
      ironHydroxideInSediment: null,
      totalSulphideInSediment: null,
      ironInSediment: null,
      ironSulphideInSediment: null
    }
  }

  // Find the occurrence of a search term in the 2D array. 
  // Return the index (0-based) of the column that the word was found in.
  // This allows searching for e.g. "longitude" and find that it's the
  // header of e.g. column 0.
  const findIndexOrNull = (...regexes: RegExp[]): number => {
    for(let i = 0; i < arr.length; i++) {
      const row = arr[i];
      // Ignore any rows with fewer columns that the full column width;
      // these are clearly not header rows.
      if(row.length < columnCount) continue;
      for(let j = 0; j < row.length; j++) {
        const cell = row[j];
        if (typeof cell === 'string') {
          for(let k = 0; k < regexes.length; k++) {
            if(regexes[k].test(cell)) return j;
          }
        }
      }
    }
    return null;
  }

  // Find column indieces:
  const longitude = findIndexOrNull(/longitude/i);
  const latitude = findIndexOrNull(/latitude/i);
  const depth = findIndexOrNull(/depth|bathymetry/i);
  const east = findIndexOrNull(/east/i, /u$/i);
  const north = findIndexOrNull(/north/i, /v$/i);
  const dailyPOCIndex = findIndexOrNull(/poc load \(gc m-2 d-1\)/i);
  const yearlyPOCIndex = findIndexOrNull(/poc load \(gc m-2 y-1\)/i) 
  const oxygenIndex = findIndexOrNull(/(dissolved )?oxygen/i);
  const ammoniaIndex = findIndexOrNull(/nh4|ammonia/i);
  const chlorophyllIndex = findIndexOrNull(/chlorophyll/i);
  const pathogenIndex = findIndexOrNull(/pathogen/i);
  const pocInSedimentIndex = findIndexOrNull(/poc in sediment/i);
  const pomInSedimentIndex = findIndexOrNull(/pom in sediment/i);
  const bacteriaInSedimentIndex = findIndexOrNull(/bacteria in sediment/i);
  const oxygenInSedimentIndex = findIndexOrNull(/oxygen in sediment/i);
  const sulphateInSedimentIndex = findIndexOrNull(/sulphate in sediment/i);
  const ironHydroxideInSedimentIndex = findIndexOrNull(/iron hydroxide in sediment/i);
  const totalSulphideInSedimentIndex = findIndexOrNull(/total sulphide in sediment/i);
  const ironInSedimentIndex = findIndexOrNull(/iron in sediment/i);
  const ironSulphideInSedimentIndex = findIndexOrNull(/iron sulphide in sediment/i);
  
  // If one of the Organix columns appears, then this is probably and Organix file.
  const hasOrganix = 
    dailyPOCIndex != null || 
    yearlyPOCIndex != null || 
    oxygenIndex != null || 
    ammoniaIndex != null || 
    chlorophyllIndex != null || 
    pathogenIndex != null || 
    pocInSedimentIndex != null || 
    pomInSedimentIndex != null || 
    bacteriaInSedimentIndex != null || 
    oxygenInSedimentIndex != null || 
    sulphateInSedimentIndex != null || 
    ironHydroxideInSedimentIndex != null || 
    totalSulphideInSedimentIndex != null || 
    ironInSedimentIndex != null || 
    ironSulphideInSedimentIndex != null;

  // Return data, totalrows, skiprows and column indices as a single object:
  return {
    data,
    totalRows: arr.length,
    skiprows,
    longitude: longitude,
    latitude: latitude,
    eastspeed: hasOrganix ? null : east,
    northspeed: hasOrganix ? null : north,
    depth: hasOrganix ? null : depth,
    dailyPOC: hasOrganix ? dailyPOCIndex : null,
    yearlyPOC: hasOrganix ? yearlyPOCIndex : null,
    oxygen: hasOrganix ? oxygenIndex : null,
    ammonia: hasOrganix ? ammoniaIndex : null,
    chlorophyll: hasOrganix ? chlorophyllIndex : null,
    pathogen: hasOrganix ? pathogenIndex : null,
    pocInSediment: hasOrganix? pocInSedimentIndex : null,
    pomInSediment: hasOrganix? pomInSedimentIndex : null,
    bacteriaInSediment: hasOrganix? bacteriaInSedimentIndex : null,
    oxygenInSediment: hasOrganix? oxygenInSedimentIndex : null,
    sulphateInSediment: hasOrganix? sulphateInSedimentIndex : null,
    ironHydroxideInSediment: hasOrganix? ironHydroxideInSedimentIndex : null,
    totalSulphideInSediment: hasOrganix? totalSulphideInSedimentIndex : null,
    ironInSediment: hasOrganix? ironInSedimentIndex : null,
    ironSulphideInSediment: hasOrganix? ironSulphideInSedimentIndex : null
  }
}

export { sheetToArray, TSheetToArrayResult }
