import * as React from 'react';
import { FeatureCollection } from 'geojson';
import { Layer, MapMouseEvent, Source, useMap } from 'react-map-gl';

import { POLYGON_FILL_COLOR_VALID, POLYGON_LINE_COLOR_VALID } from '../EditorConfig';
import { IRect } from '../../types/IRect';
import { featureCollection, polygon } from '@turf/helpers';
import { Mouse } from '@longline/aqua-ui/controls/Mouse';
import { Guide } from '../../Guide';
import { Key } from '@longline/aqua-ui/controls/Key';

interface IProps {
  /** Fired when rect builder is cancelled. */
  onCancel: () => void;
  /** Fired when rect builder completes. */
  onComplete: (rect: IRect) => void;
}

const RectBuilder = (props: IProps) => {
  const { current: map } = useMap();
  const rect = React.useRef<IRect>(null);
  const [time, setTime] = React.useState(null);

  const handleKeydown = (e: KeyboardEvent) => {
    switch(e.code) {
      case 'Enter':
      case 'NumpadEnter':
        submit();
        break;
      case 'Escape':
        props.onCancel();
        break;
    }
  }  

  const handleMouseMove = (e: MapMouseEvent) => {
    if(rect.current === null) return;
    rect.current = {
      ...rect.current,
      maxLng: e.lngLat.lng,
      maxLat: e.lngLat.lat,
    };
    setTime(Math.random());
  }

  const handleClick = (e: MapMouseEvent) => {
    e.preventDefault();

    if(rect.current === null) {
      rect.current = {
        minLng: e.lngLat.lng,
        maxLng: e.lngLat.lng,
        minLat: e.lngLat.lat,
        maxLat: e.lngLat.lat,
      };
      setTime(Math.random());
    } else {
      submit();
    }
  }

  const submit = () => {
    if(rect.current == null) return;
    
    const r = {...rect.current};
    // Ensure min and max of lng/lat are in right order
    // to prevent flipped/mirrored rect.
    if(r.minLng > r.maxLng) {
      const temp = r.minLng;
      r.minLng = r.maxLng;
      r.maxLng = temp;
    }
    if(r.minLat > r.maxLat) {
      const temp = r.minLat;
      r.minLat = r.maxLat;
      r.maxLat = temp;
    }      
    props.onComplete(r);
  }

  const mount = () => {
    // Start listening to keyboard:
    document.addEventListener('keydown', handleKeydown);         
    map.on('mousemove', handleMouseMove);
    map.on('click', handleClick);
    map.getCanvas().style.cursor = 'crosshair';
  }  

  const unmount = () => {
    // Stop listing to keyboard:
    document.removeEventListener('keydown', handleKeydown);      
    map.off('mousemove', handleMouseMove);
    map.off('click', handleClick);
    map.getCanvas().style.cursor = '';
  }

  React.useEffect(() => {
    mount();
    return unmount
  }, []);

  const getRectJSON = (): FeatureCollection => {
    if(!rect.current) return null;

    const poly = polygon([[
      [rect.current.minLng, rect.current.minLat],
      [rect.current.maxLng, rect.current.minLat],
      [rect.current.maxLng, rect.current.maxLat],
      [rect.current.minLng, rect.current.maxLat],
      [rect.current.minLng, rect.current.minLat]
    ]]);

    return featureCollection([poly]);
  }

  return (
    <>
      {time == null && <Guide x={-64} y={-48} title="Adding an export area">
        To add an export area, move the cursor to the area's first corner. 
        Click <Mouse size={14} left/> the mouse once.
      </Guide>}
      {time != null && <Guide x={-64} y={-48} title="Adding an export area">
        Move the mouse to define the area's size, then click <Mouse size={14} left/> or <Key value="ENTER"/> to confirm. <Key value="ESC"/> cancels.
      </Guide>}   
      <Source type="geojson" data={getRectJSON()}>
        <Layer 
          type="fill"
          paint={{
            "fill-color": POLYGON_FILL_COLOR_VALID,
            "fill-opacity": 0.2
          }}
        />        
        <Layer 
          type="line"
          paint={{
            "line-color": POLYGON_LINE_COLOR_VALID,
            "line-width": 1.5,
            "line-dasharray": [ 2, 1 ]
          }}
        />
      </Source>
    </>
  );
}

export { RectBuilder }
