import * as React from 'react';

import { IMapButtonProps, MapButton } from '@longline/aqua-ui/map/controls/base/MapButton';
import { useGlobalState } from '../contexts/state/GlobalStateContext';
import { Sprites } from '../svg';

const AddPolygonStructureButton = (props: IMapButtonProps) => {
  const globalState = useGlobalState();
  return (
    <MapButton 
      active={globalState.mode == 'polygon-builder'}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        globalState.setMode('polygon-builder');
      }} 
      hint="Add a polygon structure"
      {...props}
    >
      <svg width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <use xlinkHref={Sprites.Icons.AddPolygon}></use>
      </svg>
    </MapButton>
  );
}

export { AddPolygonStructureButton }
