import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const BodyBase = (props: IProps) => {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

const Body = styled(BodyBase)`
  background: #888;
  border-bottom-left-radius: ${p => p.theme.radius.normal}px;
  border-bottom-right-radius: ${p => p.theme.radius.normal}px;  
  padding: 18px;
  box-sizing: border-box;
  color: #FFFFFF;
`

export { Body }

