import * as React from 'react';
import styled from 'styled-components';

import { Input } from '@longline/aqua-ui/inputs/Input';
import { Slider } from '@longline/aqua-ui/inputs/Slider';
import { Icon } from '@longline/aqua-ui/controls/Icon';
import { Number } from '@longline/aqua-ui/formatters/Number';
import { Form } from '@longline/aqua-ui/containers/Form';

import { PolygonUtils } from '../../util/PolygonUtils';
import { IArea } from '../../types/IArea';
import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { InfoBoxRow } from '../InfoBoxRow';
import { BathymetryFormatControl } from './BathymetryFormatControl';
import { ELayerType } from '../../types/ELayerType';
import { Sprites } from '../../svg';

interface IProps {
  value?: IArea;
}

const AreaTab = (props: IProps) => {
  const globalState = useGlobalState();

  const handleUpdateName = (value: string) => {
    if(value.length > 0) {
      props.value.name = value;
      globalState.updateArea(props.value);
    }
  }

  const handleUpdateHorizontalResolution = (value: number) => {
    props.value.resolution = value;
    globalState.updateArea(props.value);
  }

  const handleUpdateBathymetry = (value: 'positive' | 'negative') => {
    props.value.bathymetry = value;
    globalState.updateArea(props.value);
  }

  const humanArea = (name: string, aream2: number) => {
    if(aream2 > 1000000) return <InfoBoxRow name={<>{name} <Unit>(km2)</Unit></>} value={<Number value={aream2/1000000} decimals={2}/>}/>;
    return <InfoBoxRow name={<>{name} <Unit>(m2)</Unit></>} value={<Number value={aream2} decimals={0}/>}/>;
  }

  const humanLength = (name: string, lengthMeters: number) => {
    if(lengthMeters > 1000) return <InfoBoxRow name={<>{name} <Unit>(km)</Unit></>} value={<Number value={lengthMeters/1000} decimals={2}/>}/>;
    return <InfoBoxRow name={<>{name} <Unit>(m)</Unit></>} value={<Number value={lengthMeters} decimals={0}/>}/>;
  }

  const getWidth = () =>
    PolygonUtils.distance(
      { lng: props.value.rect.minLng, lat: props.value.rect.minLat}, 
      { lng: props.value.rect.maxLng, lat: props.value.rect.minLat});

  const getHeight = () => 
    PolygonUtils.distance(
      { lng: props.value.rect.minLng, lat: props.value.rect.minLat}, 
      { lng: props.value.rect.minLng, lat: props.value.rect.maxLat});

  const getRatio = () => getHeight() / getWidth();

  const getVerticalResolution = () => {
    return Math.floor(props.value.resolution * getRatio());
  }

  const getArea = () => getWidth() * getHeight();

  const getStatus = () => {
    if(!globalState.layers.find(l => l.type == ELayerType.Depth)) return (<Status>
      <Icon url={Sprites.Icons.Warning}/> No bathymetry data available.
    </Status>);
    if(!globalState.layers.find(l => l.type == ELayerType.CurrentSpeed)) return (<Status>
      <Icon url={Sprites.Icons.Warning}/> No current velocity data available.
    </Status>);
    return null;
  }

  return (
    <>
      {humanLength("Width", getWidth())}
      {humanLength("Height", getHeight())}
      {/* {humanArea("Area", getArea())} */} {/* Not enough space available for this. */}
      {humanLength("Cell size", getWidth() / props.value.resolution)}
      {/* <InfoBoxRow name="Cell count" value={<Number value={props.value.resolution * getVerticalResolution()} decimals={0}/>}/> */}

      <Spacer/>

      <Form.Field
        label="Name" 
        name="name"
        hint="Please enter this export area's name."
        wrapper='box'
        value={props.value.name}
        onChange={handleUpdateName}
        weight={1}
        rules={{ required: {} }}
        control={<Input/>}
      />      

      <Form.Field
        label="Bathymetry format"
        name="negativeBathymetry"
        hint="Please select a bathymetry format."
        wrapper='box'
        value={props.value.bathymetry}
        onChange={handleUpdateBathymetry}
        weight={1}
        rules={{ required: {} }}
        control={<BathymetryFormatControl/>}
      />

      <Form.Field
        label="Horizontal resolution" 
        name="horizontal_resolution"
        hint={<>Dimensions: <Bold><Number value={props.value.resolution} decimals={0}/></Bold> <>&#10799; </> <Bold><Number value={Math.floor(props.value.resolution * getRatio())} decimals={0}/></Bold> = <Bold><Number value={props.value.resolution * getVerticalResolution()} decimals={0}/></Bold> cells</>}
        wrapper='box'
        value={props.value.resolution}
        onChange={handleUpdateHorizontalResolution}
        weight={1}
        rules={{ required: {} }}
        control={<Slider padded min={10} max={500} snapToTicks/>}
      />      

      {getStatus()}    
    </>
  )
}

const Bold = styled.span`
  font-weight: 700;
`

const Unit = styled.span`
  display: inline-block;
  font-size: 80%;
  font-weight: normal;
  color: #eee;
`

const Spacer = styled.div`
  height: 8px;
`

const Status = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;  
`

export { AreaTab }
