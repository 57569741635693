import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  className?: string;
}

const BubbleBase = (props: IProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const size = React.useRef(Math.random() * 20 + 10);
  const animationID = React.useRef<number>(null);

  React.useEffect(() => {
    animationID.current = requestAnimationFrame(() => animate(Math.random() * 100, Math.random() * 100 + 130, Math.random() / 3 + 0.1));
    return () => { cancelAnimationFrame(animationID.current); }
  }, [])

  const animate = (left: number, top: number, speed: number) => {
    ref.current.style.left = `${left}vw`;
    ref.current.style.top = `${top}vh`;

    left = left + (Math.random() - 0.5) / 10;
    top -= speed;
    if(top < -30) {
      top = 130;
      left = Math.random() * 100;
      speed = Math.random() / 3 + 0.1;
    }

    requestAnimationFrame(() => animate(left, top, speed));
  }
    
  return (
    <div className={props.className} ref={ref} style={{width: `${size.current}px`, height: `${size.current}px`}}></div>
  )
}

const Bubble = styled(BubbleBase)`
  position: absolute;
  left: 50px;
  top: 50px;
  border-radius: 50%;
  border: solid 2px #ffffff80;
  box-shadow: 1px 1px 1px #00000080;
  &:after {
    content: '';
    position: absolute;
    left: 15%;
    top: 15%;
    width: 20%;
    height: 30%;
    border-radius: 50%;
    transform: rotate(30deg);
    border: solid 1px #ffffff80;
  }
`

export { Bubble }
