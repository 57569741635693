import { IUser } from "../../models/IUser";

class AuthApi {

  /**
   * Login with email/password.
   * @param email Email to login with
   * @param password Password to login with
   * @returns IUser.
   */
  static login = async (email: string, password: string): Promise<IUser> => {
    if(!email || !password) throw("Unauthenticated");
    if(email.toLowerCase() != 'fins' || password.toLowerCase() != 'fins') throw("Unauthenticated");
    return { email: "fins", name: "fins" } as IUser; 
  }
  
}

export { AuthApi }
