import { ILayer } from "../types/ILayer";
import { DefaultLayerDefinitions, ILayerTypeDefinition } from "../types/ILayerTypeDefinition";
import { ELayerType } from "../types/ELayerType";
import { IDataPoint } from '../types/IDataPoint';

class LayerUtils {
  /**
   * Extract a list of values from a layer. For an interpolation layer, which has only
   * one dimension, this is simply a list of all the values in the layer. For a speed
   * layer, which dimensions U and V, this is sqrt(u2 + v2).
   */
  static getLayerValues = (layer: ILayer): number[] => {
    if(layer.type == ELayerType.CurrentSpeed) {
      return layer.data
        .filter(point => !isNaN(point.value[0]) && !isNaN(point.value[1]))
        .map(point => {
        const u = point.value[0];
        const v = point.value[1];
        return Math.sqrt(u*u + v*v);
      });
    } else {
      return layer.data
        .filter(point => !isNaN(point.value[0]))
        .map(point => point.value[0]);
    }
  }

  /**
   * Returns the min and max value contained in a layer.
   */
  static getMinMax = (layer: ILayer): number[] => {
    const values = LayerUtils.getLayerValues(layer);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    return [minValue, maxValue];
  }

  /**
   * Returns [lng, lat] of center of layer.
   */
  static getCentroid = (layer: ILayer): number[] => {
    const minLng = Math.min(...layer.data.map(p => p.lng));
    const maxLng = Math.max(...layer.data.map(p => p.lng));
    const minLat = Math.min(...layer.data.map(p => p.lat));
    const maxLat = Math.max(...layer.data.map(p => p.lat));
    const lng = minLng + (maxLng - minLng) / 2;
    const lat = minLat + (maxLat - minLat) / 2;    
    return [lng, lat];
  }

  static layerTypeToDefinition = (type: ELayerType): ILayerTypeDefinition => {
    return DefaultLayerDefinitions[type];
  }

  /**
   * Takes a layer type, source filename, and data, creating a layer instance.
   * @returns ILayer
   */
  static createLayer = (type: ELayerType, source: string, data: IDataPoint[]): ILayer => {
    const definition = this.layerTypeToDefinition(type);
    return {
      source: source,
      type: type, 
      data: data, 
      visible: true, 
      contours: definition.contours ?? 0, 
      gradient: definition.gradients[0], 
      trail: definition.trail ?? 80, 
      density: definition.delay ?? 50, 
      pointSize: definition.pointSize ?? 1, 
      delay: definition.delay ?? 1
    };
  }
}

export { LayerUtils }
