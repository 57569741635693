import * as React from 'react';
import styled from 'styled-components';

import { Icon } from '@longline/aqua-ui/controls/Icon';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { IStructure } from '../../types/IStructure';
import { Sprites } from '../../svg';
import { DeleteIcon } from '../../contexts/confirm/DeleteIcon';
import { SVG } from '@longline/aqua-ui/svg';

interface IProps {
  className?: string;
  value: IStructure;
}

const StructureInfoBoxHeaderBase = (props: IProps) => {
  const globalState = useGlobalState();

  const handleClose = () => {
    globalState.setSelectedStructure(null);
  }

  const handleKeyDown = (ev: KeyboardEvent) => {
    if(ev.code == 'Escape') handleClose();
  }

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  return (
    <div className={props.className}>
      <Block>
        <Icon url={props.value.type == 'circle' ? Sprites.Icons.Circle : Sprites.Icons.Polygon}/>
        {props.value.name}
      </Block>
      <Block>
        <DeleteIcon title="Delete structure" text="" onClick={() => globalState.deleteSelectedStructure()}/>
        <Icon url={SVG.Icons.Cross} onClick={handleClose}/>
      </Block>
    </div>
  )
}

const InfoBoxScroller = styled.div`
  padding: 8px 16px 8px 16px;
  max-height: 60vh;
  overflow-y: auto;
`

const Block = styled.div`
  display: flex;
  flex-direction: row;  
  justify-content: start;
  gap: 8px;
  align-items: center;
`

const StructureInfoBoxHeaderStyled = styled(StructureInfoBoxHeaderBase)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StructureInfoBoxHeader = (props: IProps) => <StructureInfoBoxHeaderStyled {...props}/>

export { StructureInfoBoxHeader }
