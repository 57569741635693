import * as React from 'react'; 
import styled from 'styled-components';

import { SVG } from '@longline/aqua-ui/svg';
import { SecondaryButton } from '@longline/aqua-ui/controls/SecondaryButton';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode; 
  /** Title to show in header block. Can be JSX. */
  title: React.ReactNode;
  /** Fired when expand/contract button is clicked. */
  onToggle: () => void;
  /** Is the pane currently expanded? */
  expanded: boolean;
}

const PaneHeaderBase = (props: IProps) => 
  <div className={props.className}>
    <Block>
      <Title>{props.title}</Title>
      {props.children}
    </Block>
    <SecondaryButton icon={{ url: SVG.Icons.Chevron, mirrored: !props.expanded }} onClick={props.onToggle}/>
  </div>

const Block = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Title = styled.div`
  font-size: 24px;
`

const PaneHeaderStyled = styled(PaneHeaderBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  flex-shrink: 0;
  box-sizing: border-box;
  padding: 0 16px 0 16px;
  background-color: ${p => p.theme.colors.primary[5]};
`

const PaneHeader = (props: IProps) => <PaneHeaderStyled {...props}/>

export { PaneHeader }

