import * as React from 'react';
import styled from 'styled-components';

import { Icon } from '@longline/aqua-ui/controls/Icon';

import { IStructure } from '../../types/IStructure';
import { Sprites } from '../../svg';

interface IProps {
  /** @ignore */
  className?: string;
  /** Structure to show */
  structure: IStructure;
}

const StructureCardBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Icon url={props.structure.type == 'circle' ? Sprites.Icons.Circle : Sprites.Icons.Polygon}/>
      {props.structure.name}
    </div>
  )
}

const StructureCardStyled = styled(StructureCardBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const StructureCard = (props: IProps) => <StructureCardStyled {...props}/>

export { StructureCard }
