import * as React from 'react';
import styled from 'styled-components';

import { InfoBox } from '@longline/aqua-ui/containers/InfoBox';
import { TertiaryButton } from '@longline/aqua-ui/controls/TertiaryButton';
import { Number } from '@longline/aqua-ui/formatters/Number';
import { Longitude } from '@longline/aqua-ui/formatters/GIS/Longitude';
import { Latitude } from '@longline/aqua-ui/formatters/GIS/Latitude';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { IStructure } from '../../types/IStructure';
import { PolygonUtils } from '../../util/PolygonUtils';
import { StructureInfoBoxHeader } from './StructureInfoBoxHeader';
import { InfoBoxRow } from '../InfoBoxRow';

interface IProps {
  value?: IStructure;
}

const StructureInfoBox = (props: IProps) => {
  const globalState = useGlobalState();

  const humanArea = (name: string, aream2: number) => {
    if(aream2 > 1000000) return <InfoBoxRow name={<>{name} <Unit>(km2)</Unit></>} value={<Number value={aream2/1000000} decimals={0}/>}/>;
    return <InfoBoxRow name={<>{name} <Unit>(m2)</Unit></>} value={<Number value={aream2} decimals={0}/>}/>;
  }

  const humanLength = (name: string, lengthMeters: number) => {
    if(lengthMeters > 1000) return <InfoBoxRow name={<>{name} <Unit>(km)</Unit></>} value={<Number value={lengthMeters/1000} decimals={0}/>}/>;
    return <InfoBoxRow name={<>{name} <Unit>(m)</Unit></>} value={<Number value={lengthMeters} decimals={0}/>}/>;
  }

  return (
    <InfoBox 
      header={<StructureInfoBoxHeader value={props.value}/>}
      width={300}
      footer={<>
        <TertiaryButton onClick={() => globalState.focusStructure(props.value)}>FOCUS</TertiaryButton>
        <TertiaryButton onClick={() => globalState.startEditStructure()}>EDIT</TertiaryButton>
      </>}
    >
      <InfoBoxScroller>
        {props.value.type == 'circle' && <>
          <InfoBoxRow name="Position" value={<><Longitude value={props.value.point.lng}/>, <Latitude value={props.value.point.lng}/></>}/>
          {humanLength("Diameter", 2 * props.value.radius)}
          {humanArea("Area", Math.PI * props.value.radius * props.value.radius)}
          {humanLength("Circumference", 2 * Math.PI * props.value.radius)}
        </>}

        {props.value.type == 'polygon' && <>
          <InfoBoxRow name="Position" value={<><Longitude value={PolygonUtils.getCentroid(props.value.points).lng}/>, <Latitude value={PolygonUtils.getCentroid(props.value.points).lat}/></>}/>
          <InfoBoxRow name="Points" value={props.value.points.length}/>
          {humanArea("Area", PolygonUtils.getArea(props.value.points))}
          {humanLength("Perimeter", PolygonUtils.getPerimeter(props.value.points))}
        </>}

        <InfoBoxRow name={<>Stocking <Unit>(animals)</Unit></>} value={(props.value.stocking === undefined || props.value.stocking === null) ? 'not set' : <Number value={props.value.stocking} decimals={0}/>}/>
      </InfoBoxScroller>
             
    </InfoBox>
  )
}

const InfoBoxScroller = styled.div`
  padding: 8px 16px 8px 16px;
  max-height: 60vh;
  overflow-y: auto;
`

const Unit = styled.span`
  display: inline-block;
  font-size: 80%;
  font-weight: normal;
  color: #eee;
`

export { StructureInfoBox }
