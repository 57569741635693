import * as React from 'react';
import styled from 'styled-components';

import { ListView } from '@longline/aqua-ui/controls/ListView/ListView';
import { SecondaryButton } from '@longline/aqua-ui/controls/SecondaryButton';
import { SVG } from '@longline/aqua-ui/svg';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { PaneHeader } from '../PaneHeader';
import { useConfirm } from '../../contexts/confirm/ConfirmContext';
import { AreaInfoBox } from '../../infoboxes/AreaInfoBox';
import { IArea } from '../../types/IArea';
import { Pane } from '../Pane';
import { Guide } from '../../Guide';
import { Key } from '@longline/aqua-ui/controls/Key';

const AreaPane = () => {
  const confirm = useConfirm();
  const globalState = useGlobalState();

  const handleDeleteAll = () => {
    globalState.clearAreas();
  }

  const handleAdd = () => {
    globalState.setSelectedArea(null);
    globalState.setMode('area-builder');
  }  

  return (
    <>
      <Pane>
        {/* Title & Add rect buttons */}
        <PaneHeader title="Export areas" onToggle={globalState.togglePaneExpansion} expanded={globalState.expanded}>
          <SecondaryButton positive icon={SVG.Icons.Plus} onClick={handleAdd}/>
        </PaneHeader>
        {/* Rects list */}
        <ListView 
          dark 
          data={globalState.areas} 
          active={globalState.selectedArea}
          onClick={(rect) => globalState.setSelectedArea(rect)} 
          shadow
          aux={<AreaInfoBox/>}
          total
          singular="area"
          plural="areas"
          noData="There are no export areas yet."
          rowHeight={56}
        >
          <ListView.Column active width={3} name="Name">{(item: IArea) => item.name}</ListView.Column>
        </ListView>
        {globalState.areas.length > 0 &&
          <FabHolder>
            <SecondaryButton icon={SVG.Icons.Delete} onClick={() => confirm.start(() => handleDeleteAll, "Delete all areas", "Are you sure you wish to delete all export areas?")}/>
          </FabHolder>}
      </Pane>
      {globalState.selectedArea == null && globalState.selectedStructure == null && <Guide x={-64} y={-48} title="Export areas">
        <ul>
          <li>Click <Key value="+"/> to create a new export area.</li>
        </ul>
      </Guide>}
    </>
  )
}

const FabHolder = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
`

export { AreaPane }
