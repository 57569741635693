/** @module @ignore */
import * as React from 'react';
import { FeatureCollection } from 'geojson';
import { Layer, MapMouseEvent, Source, useMap } from 'react-map-gl/mapbox';
import { GeoJSONFeature } from 'mapbox-gl';

import { POINTS_LAYER, POLYGON_CIRCLE_COLOR, POLYGON_CIRCLE_STROKE_COLOR, POLYGON_FILL_COLOR_VALID } from '../EditorConfig';
import { IRect } from '../../types/IRect';
import { featureCollection, point } from '@turf/helpers';

interface IProps {
  /** Rect points. */
  rect: IRect;
  /** Fired when points change. */
  onChange: (rect: IRect) => void;
}

const RectEditorPoints = (props: IProps) => {
  const { current: map } = useMap();
  const rect = React.useRef<IRect>({ ...props.rect });
  const selectedPoint = React.useRef<GeoJSONFeature>(null);
  const dragging = React.useRef(false);
  const pointIndex = React.useRef<number>(null);

  const handleMouseEnter = (e: MapMouseEvent) => {
    if(!dragging.current) map.getCanvas().style.cursor = 'pointer';
  }

  const handleMouseLeave = (e: MapMouseEvent) => {
    if(!dragging.current) map.getCanvas().style.cursor = '';
  }

  const handleMouseDown = (e: MapMouseEvent) => {
    pointIndex.current = e.features[0].id as number;
    if(selectedPoint.current) map.setFeatureState(selectedPoint.current, { selected: false });
    map.setFeatureState(e.features[0], { selected: true });
    selectedPoint.current = e.features[0];
    dragging.current = true;
    map.getCanvas().style.cursor = 'move';
    map.getMap().dragPan.disable();
  }

  const handleMouseUp = (e: MapMouseEvent) => {
    dragging.current = false;
    map.getCanvas().style.cursor = 'pointer';
    map.getMap().dragPan.enable();
    if(selectedPoint.current) map.setFeatureState(selectedPoint.current, { selected: false });
  }

  const handleMouseMove = (e: MapMouseEvent) => {
    if(!dragging.current) return;
    if(pointIndex.current === null) return;
    const newRect: IRect = {
      minLng: (pointIndex.current == 0 || pointIndex.current == 2) ? e.lngLat.lng : rect.current.minLng,
      maxLng: (pointIndex.current == 1 || pointIndex.current == 3) ? e.lngLat.lng : rect.current.maxLng,
      minLat: (pointIndex.current == 0 || pointIndex.current == 1) ? e.lngLat.lat : rect.current.minLat,
      maxLat: (pointIndex.current == 2 || pointIndex.current == 3) ? e.lngLat.lat : rect.current.maxLat
    }
    props.onChange(newRect);
  }

  const handleClick = (e: MapMouseEvent) => {
    e.preventDefault();
  }

  const mount = () => {
    rect.current = props.rect;
    // Register for clicks and moves:
    map.on('mouseenter', POINTS_LAYER, handleMouseEnter);
    map.on('mouseleave', POINTS_LAYER, handleMouseLeave);
    map.on('mousedown', POINTS_LAYER, handleMouseDown);
    map.on('mouseup', POINTS_LAYER, handleMouseUp);
    map.on('mousemove', handleMouseMove);
    map.on('click', POINTS_LAYER, handleClick);
  }

  const unmount = () => {
    // Unregister for clicks and moves:
    map.off('mouseleave', POINTS_LAYER, handleMouseLeave);
    map.off('mouseenter', POINTS_LAYER, handleMouseEnter);
    map.off('mousedown', POINTS_LAYER, handleMouseDown);
    map.off('mouseup', POINTS_LAYER, handleMouseUp);
    map.off('mousemove', handleMouseMove);
    map.off('click', POINTS_LAYER, handleClick);
  }

  React.useEffect(() => {
    mount();
    return unmount
  }, [props.rect]);

  // Convert points to a FeatureCollection:
  const getJSON = (): FeatureCollection => {
    return featureCollection([
      point([ props.rect.minLng, props.rect.minLat ], {}, { id: 0 }), // NW
      point([ props.rect.maxLng, props.rect.minLat ], {}, { id: 1 }), // NE
      point([ props.rect.minLng, props.rect.maxLat ], {}, { id: 2 }), // SW 
      point([ props.rect.maxLng, props.rect.maxLat ], {}, { id: 3 }), // SE
    ]);
  }

  return (
    <Source type="geojson" data={getJSON()}>
      <Layer 
        id={POINTS_LAYER}
        type="circle"
        paint={{
          "circle-color": POLYGON_CIRCLE_COLOR,
          "circle-stroke-color": POLYGON_CIRCLE_STROKE_COLOR,
          "circle-radius": [
            'case',
            ['boolean', ['feature-state', 'selected'], false],
            5,
            3
          ],
          "circle-stroke-width": 2
        }}
      />
    </Source>
  );
}

export { RectEditorPoints }

