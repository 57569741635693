class ExcelConstants {
  static STR_LATITUDE = "Latitude";
  static STR_LONGITUDE = "Longitude";
  static STR_DEPTH = "Depth";
  static STR_U = "Eastward velocity";
  static STR_V = "Northward velocity";
  static STR_DAILY_POC = "Daily POC";
  static STR_YEARLY_POC = "Yearly POC";
  static STR_OXYGEN = "Dissolved oxygen";
  static STR_AMMONIA = "NH4 concentration";
  static STR_CHLOROPHYLL = "Chlorophyll";
  static STR_PATHOGEN = "Pathogen";
  static STR_POC_IN_SEDIMENT = "POC in sediment";
  static STR_POM_IN_SEDIMENT = "POM in sediment";
  static STR_BACTERIA_SEDIMENT = "Bacteria in sediment";
  static STR_OXYGEN_IN_SEDIMENT = "Oxygen in sediment";
  static STR_SULPHATE_IN_SEDIMENT = "Sulphate in sediment";
  static STR_IRON_HYDROXIDE_IN_SEDIMENT = "Iron hydroxide in sediment";
  static STR_TOTAL_SULPHIDE_IN_SEDIMENT = "Total sulphide in sediment";
  static STR_IRON_IN_SEDIMENT = "Iron in sediment";
  static STR_IRON_SULPHIDE_IN_SEDIMENT = "Iron sulphide in sediment";
}

export { ExcelConstants }
