import * as React from 'react';
import styled from 'styled-components';

import { ListView } from '@longline/aqua-ui/controls/ListView/ListView';
import { SecondaryButton } from '@longline/aqua-ui/controls/SecondaryButton';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { PaneHeader } from '../PaneHeader';
import { StructureInfoBox } from '../../infoboxes/StructureInfoBox';
import { IStructure } from '../../types/IStructure';
import { Sprites } from '../../svg';
import { StructureCard } from './StructureCard';
import { Number } from '@longline/aqua-ui/formatters/Number';
import { SVG } from '@longline/aqua-ui/svg';
import { useConfirm } from '../../contexts/confirm/ConfirmContext';
import { Pane } from '../Pane';

const StructurePane = () => {
  const confirm = useConfirm();
  const globalState = useGlobalState();

  const handleAddCircle = () => {
    globalState.setMode('circle-builder');
  }

  const handleAddPolygon = () => {
    globalState.setMode('polygon-builder');
  }

  const handleDeleteAll = () => {
    globalState.clearStructures();
  }

  return (
    <Pane>
      {/* Title & Add structure buttons */}
      <PaneHeader title="Structures" onToggle={globalState.togglePaneExpansion} expanded={globalState.expanded}>
        <SecondaryButton positive icon={Sprites.Icons.AddCircle} onClick={handleAddCircle}/>
        <SecondaryButton positive icon={Sprites.Icons.AddPolygon} onClick={handleAddPolygon}/>
      </PaneHeader>
      {/* Structure list */}
      <ListView 
        dark 
        data={globalState.structures} 
        active={globalState.selectedStructure}
        onClick={(structure) => globalState.setSelectedStructure(structure)} 
        shadow
        aux={<StructureInfoBox/>}
        total
        singular="structure"
        plural="structures"
        noData="There are no structures yet."
        rowHeight={56}
      >
        <ListView.Column active width={3} name="Name">{(item: IStructure) => <StructureCard structure={item}/>}</ListView.Column>
        <ListView.Column active={globalState.expanded} width="150px" align="right" name="Stocking">{(item: IStructure) => <Number value={item.stocking} decimals={0}/>}</ListView.Column>
      </ListView>
      {globalState.structures.length > 0 && 
        <FabHolder>
          <SecondaryButton icon={SVG.Icons.Delete} onClick={() => confirm.start(() => handleDeleteAll, "Delete all structures", "Are you sure you wish to delete all structures?")}/>
        </FabHolder>}
    </Pane>
  )
}

const FabHolder = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
`

export { StructurePane }
