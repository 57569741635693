import * as React from 'react';
import styled from 'styled-components';
import { InfoBox } from '@longline/aqua-ui/containers/InfoBox';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { TertiaryButton } from '@longline/aqua-ui/controls/TertiaryButton';
import { InfoBoxRow } from '../InfoBoxRow';
import { ISample } from '../../types/ISample';
import { SampleInfoBoxHeader } from './SampleInfoBoxHeader';
import { Number } from '@longline/aqua-ui/formatters/Number';
import axios from 'axios';

interface IProps {
  value?: ISample;
}

const SampleInfoBox = (props: IProps) => {
  const globalState = useGlobalState();

  const handleLoad = () => {
    axios.get(`resources/${props.value.path}`)
    .then(res => globalState.loadProject(res.data));
  }

  return (
    <InfoBox 
    header={<SampleInfoBoxHeader value={props.value}/>}
      width={300}
      footer={<>
        <TertiaryButton onClick={handleLoad}>LOAD</TertiaryButton>
      </>}
    >
      <InfoBoxScroller>
        <InfoBoxRow name="Source" value={props.value.path}/>
        <InfoBoxRow name="Size" value={<><Number value={props.value.size} decimals={0}/> kB</>}/>
      </InfoBoxScroller>
    </InfoBox>
  )
}

const InfoBoxScroller = styled.div`
  padding: 8px 16px 8px 16px;
  max-height: 60vh;
  overflow-y: auto;
`

export { SampleInfoBox }
