import * as React from 'react';
import { styled } from 'styled-components';

import { Input } from '@longline/aqua-ui/inputs/Input';

interface IProps {
  months: string[];
  onChange: (months: string[]) => void;
}

const MonthsTab = (props: IProps) => {
  const handleChange = (i: number, value: string) => {
    props.months[i] = value;
    props.onChange([...props.months]);
  }

  const getMonthName = (i: number) => {
    return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][i];
  }

  return (<>
    {[0,1,2,3,4,5,6,7,8,9,10,11].map(i => 
      <InputWrapper key={i}>
        <MonthName>{getMonthName(i)}</MonthName>
        <Input transparent fluid value={props.months[i]} onChange={(v) => handleChange(i,v)}/>
      </InputWrapper>
    )}
  </>);
}

const MonthName = styled.div`
  background: ${p => p.theme.colors.primary[4]};
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
`

const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-auto-rows: 32px;
  align-items: center;
  background: ${p => p.theme.colors.neutral[100]};
  border-radius: ${p => p.theme.radius.normal}px;
  margin-bottom: 4px;
  padding: 0 10px 0 0;
  overflow: hidden;
  &:focus-within {
    outline: solid 2px ${p => p.theme.colors.primary[1]};
  }
`

export { MonthsTab }
