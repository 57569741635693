import * as React from 'react';
import styled from 'styled-components';

import { Dialog } from '@longline/aqua-ui/services/Dialog';
import { PrimaryButton } from '@longline/aqua-ui/controls/PrimaryButton';
import { Label } from '@longline/aqua-ui/containers/Form/elements/Label';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { Sprites } from '../../svg';


const InfoDialog = () => {
  const globalState = useGlobalState();
  
  return (
    <Dialog inverted canClose={true} open={true} onClose={() => globalState.setMode(null)}>
      <Dialog.Header>
        About FINS: Farming in Natural Systems
      </Dialog.Header>
      <Dialog.Content>
        <Logo>
          <svg>
            <use xlinkHref={Sprites.Logos.LogoHorizontal}></use>
          </svg> 
        </Logo>
        <Logos>
          <svg>
            <use xlinkHref={Sprites.Logos.Longline}></use>
          </svg> 
          <svg>
            <use xlinkHref={Sprites.Logos.Dalhousie}></use>
          </svg>
        </Logos> 

        <Addresses>
          <Address>
            <Label>Address</Label>
            <Line>Longline Environment</Line>
            <Line>63 St. Mary Axe</Line>
            <Line>London, EC3A 8AA</Line>
            <Line>United Kingdom</Line>
          </Address>
          <Address>
            <Label>Contact</Label>
            <Line><a target="_blank" href="https://www.longline.co.uk">www.longline.co.uk</a></Line>
            <Line><a href="mailto:info@longline.co.uk">info@longline.co.uk</a></Line> 
            <Line>t. +44 (0) 20 719 36121</Line>
            <Line>f. +44 (0) 20 790 03372</Line>
          </Address>
        </Addresses>

        <Copyright>Copyright &copy; 2019 Longline Environment. All rights reserved.</Copyright>     
      </Dialog.Content>
      <Dialog.Footer>
        <PrimaryButton onClick={() => globalState.setMode(null)}>OK</PrimaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

const Logo = styled.div`
  display: flex;
  justify-content: center;
  svg {
    width: 100%;
  }
  margin-bottom: 32px;
`

const Logos = styled.div`
  display: flex;
  gap: 16px;
  svg {
    fill: #fff;
    height: 100px;
    background: ${p => p.theme.colors.primary[5]};
    padding: 10px;
    border-radius: 10px;
  }
  margin-bottom: 16px;
`

const Copyright = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
  font-size: 80%;
`

const Addresses = styled.div`
  display: flex;
  gap: 16px;
`

const Address = styled.div`
  flex: 1;
  padding: 0 16px 0 16px;
`

const Heading = styled.div`
  display: flex;
  height: 22px;
  align-items: center;
  font-weight: 700;
`

const Line = styled.div`
  display: flex;
  height: 22px;
  align-items: center;
  a {
    color: ${p => p.theme.colors.primary[1]} !important;
  }
`

export { InfoDialog }
