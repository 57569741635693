import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /**
   * Fired when the marker is clicked.
   */
  onClick?: () => void;
}

const MarkerBase = (props: IProps) => {

  // Radius is passed in as inline style, to avoid generating 1000s of styled-component classes.
  return (
    <div className={props.className} style={{ width: `20px`, height: `20px`}} onClick={props.onClick}>
    </div>
  )
}

const MarkerStyled = styled(MarkerBase)`
  // Position and size:
  position: relative;
  box-sizing: border-box;

  // Appearance:
  border-radius: 50%;
  background-color: rgb(255 255 255 / 100%);
  box-shadow: 
    0 0 0 6px rgb(255 255 255 / 44%),  // outer ring small
    0 0 0 12px rgb(255 255 255 / 20%), // outer ring big
    inset 0px 0 0px 4px #fff,        // inner white ring
    inset 0px 7px 3px 0px rgba(0, 0, 0, 0.25); // inner dropshadow
  cursor: ${p => p.onClick ? 'pointer' : 'inherit'};
  outline: none;
  user-select: none;

  & > svg {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    margin-left: -50%;
    margin-top: -50%;
  }

  & > span {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    // Content:
    display: flex;
    justify-content: center;
    align-items: center;
    color: #3C698A;
    font: normal 700 12px/12px "Roboto Condensed";
  }
`

/**
 * A `ClusterMarker` may be used with an `HtmlMarkerLayer`, or as a component on its own.
 * 
 */
const Marker = ({ ...props}: IProps) => {
  return <MarkerStyled {...props}/>
}

export { Marker }
