import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const BoxBase = (props: IProps) =>
  <div className={props.className}>
    {props.children}
  </div>

const Box = styled(BoxBase)`
  box-shadow: 0px 1px 3px 0px #0000004D, 
              0px 4px 8px 3px #00000026;
  border-radius: ${p => p.theme.radius.normal}px;
`

export { Box }
