/** @module @ignore */
import * as React from 'react';
import { Layer, MapMouseEvent, Source, useMap } from 'react-map-gl';
import { featureCollection, polygon } from '@turf/helpers';
import { FeatureCollection } from 'geojson';

import { useGlobalState } from '../contexts/state/GlobalStateContext';

const AreasLayer = () => {
  const globalState = useGlobalState();
  const { current: map } = useMap();

  const getJSON = React.useMemo((): FeatureCollection => {
    // Currently-selected area is rendered as empty polygon.
    return featureCollection(globalState.areas.map((a, idx) => 
      polygon(a == globalState.selectedArea ? [] : [[
        [a.rect.minLng, a.rect.minLat],
        [a.rect.maxLng, a.rect.minLat],
        [a.rect.maxLng, a.rect.maxLat],
        [a.rect.minLng, a.rect.maxLat],
        [a.rect.minLng, a.rect.minLat]
      ]], {}, { id: idx })
    ));
  }, [globalState.areas, globalState.selectedArea]);

  const handleClick = (e: MapMouseEvent) => {
    if(!e.features) return;
    if(e.features.length == 0) return;
    const feature = e.features[0];
    if(feature.layer.id != 'areas') return;
    globalState.setSelectedArea(globalState.areas[feature.id as number]);
  }  

  React.useEffect(() => {
    map.on("click", "areas", handleClick);
    return () => {
      map.off("click", "areas", handleClick);
    }
  }, [globalState.structures, globalState.selectedStructure]);

  return (
    <Source type="geojson" data={getJSON}>
      <Layer 
        id="areas"
        type="fill"
        paint={{
          'fill-color': 'gold',
          'fill-opacity': 0.3
        }}
      />        
      <Layer 
        type="line"
        paint={{
          'line-color': 'white',
          "line-width": 1.5,
          "line-dasharray": [ 2, 1 ]
        }}
      />
    </Source>    
  );
}

export { AreasLayer }
