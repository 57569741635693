import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
}

const ButtonsBase = (props: IProps) => {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

const Buttons = styled(ButtonsBase)`
  margin-top: 12px;
  display: flex;
  // If there's only one child, right-align it:
  justify-content: ${p => React.Children.count(p.children) > 1 ? 'space-between' : 'end'};
  padding-left: 17px;
  padding-right: 17px;
  @media (max-width: 400px) {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 8px;
    align-items: center;
  }
`

export { Buttons }
