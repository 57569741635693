import * as XLSX from 'xlsx';
import { Feature, Polygon } from 'geojson';
import { featureCollection, polygon } from '@turf/helpers';
import booleanIntersects from '@turf/boolean-intersects';
import intersect from '@turf/intersect';
import bbox from '@turf/bbox';

import { IArea } from "./types/IArea"
import { IProject } from './types/IProject';
import { PolygonUtils } from './util/PolygonUtils';
import { IStructure } from './types/IStructure';
import { ILayer } from './types/ILayer';
import { DigitalElevationModel } from './DigitalElevationModel';
import { LayerUtils } from './util/LayerUtils';

interface IBBox {
  minLng: number;
  maxLng: number;
  minLat: number;
  maxLat: number;
}

class Export {
  private horizontal_resolution: number;
  private vertical_resolution: number;
  private lngStep: number;
  private latStep: number;

  private ctx: CanvasRenderingContext2D;
  private project: IProject;
  private structures: IStructure[];
  private area: IArea;
  private bathymetryLayer: ILayer;
  private currentSpeedLayer: ILayer;
  private access_token: string;
  private dem: DigitalElevationModel;
  private polys: Feature<Polygon>[];
  private bboxes: IBBox[];
  private data: any[][];
  private pixelSize: number;
  private onProgress: (percentDone: number) => void;
  private onComplete: () => void;
  private minDepth: number;
  private maxDepth: number;

  constructor(
    ctx: CanvasRenderingContext2D, project: IProject, structures: IStructure[], area: IArea, 
    bathymetryLayer: ILayer, currentSpeedLayer: ILayer, pixelSize: number, access_token: string, 
    onProgress: (percentDone: number) => void, onComplete: () => void
  ) {
    this.ctx = ctx;
    this.project = project;
    this.structures = structures;
    this.area = area;
    this.bathymetryLayer = bathymetryLayer;
    this.currentSpeedLayer = currentSpeedLayer;
    this.access_token = access_token;
    this.pixelSize = pixelSize;
    this.dem = new DigitalElevationModel(area.resolution, this.area.rect.maxLng - this.area.rect.minLng, this.access_token);
    this.onProgress = onProgress;
    this.onComplete = onComplete;
  }

  private getWidth = (area: IArea) =>
    PolygonUtils.distance(
      { lng: area.rect.minLng, lat: area.rect.minLat}, 
      { lng: area.rect.maxLng, lat: area.rect.minLat});

  private getHeight = (area: IArea) => 
    PolygonUtils.distance(
      { lng: area.rect.minLng, lat: area.rect.minLat}, 
      { lng: area.rect.minLng, lat: area.rect.maxLat});

  private getVerticalResolution = (area: IArea) => {
    const ratio = this.getHeight(area) / this.getWidth(area);
    return Math.floor(area.resolution * ratio);
  }

  // IDW for layers with a single value per point
  private idw = (lng: number, lat: number, power: number, layer: ILayer) => {
    let top = 0;
    let bot = 0;
    layer.data.forEach(dp => {
      const dist = Math.sqrt(Math.pow(dp.lng - lng, 2) + Math.pow(dp.lat - lat, 2));
      top += dp.value[0] / Math.pow(dist, power);
      bot += 1 / Math.pow(dist, power);
    });
    if (bot == 0) { //When the distance between point and sensor is 0 (the point IS the sensor) Gives black spots, so fix this   
      return top;
    } else {
      return (top / bot);
    }
  }

  // IDW for layers with a two values per point
  private idw2 = (lng: number, lat: number, power: number, layer: ILayer): number[] => {
    let top = [0,0];
    let bot = 0;
    layer.data.forEach(dp => {
      const dist = Math.sqrt(Math.pow(dp.lng - lng, 2) + Math.pow(dp.lat - lat, 2));
      top[0] += dp.value[0] / Math.pow(dist, power);
      top[1] += dp.value[1] / Math.pow(dist, power);
      bot += 1 / Math.pow(dist, power);
    });
    if (bot == 0) { //When the distance between point and sensor is 0 (the point IS the sensor) Gives black spots, so fix this   
      return top;
    } else {
      return top.map(t => t / bot);
    }
  }    

  // Do two bounding boxes overlap?
  private checkOverlap = (bbox1: IBBox, bbox2: IBBox): boolean => {
    const aLeftOfB = bbox1.maxLng < bbox2.minLng;
    const aRightOfB = bbox1.minLng > bbox2.maxLng;
    const aAboveB = bbox1.minLat > bbox2.maxLat;
    const aBelowB = bbox1.maxLat < bbox2.minLat;
    return !( aLeftOfB || aRightOfB || aAboveB || aBelowB );
  }  

  // Convert a structure to a polygon.
  private structureToPolygon = (structure: IStructure): Feature<Polygon> => {
    if(structure.type == 'polygon') {
      return polygon([[...structure.points.map(p => [ p.lng, p.lat]), [structure.points[0].lng, structure.points[0].lat]]]);
    } else { // circle
      return PolygonUtils.getCirclePolygon(structure.point, structure.radius);
    }
  }  

  private createMetadataWorksheet = () => {
    const width_meters = this.getWidth(this.area);
    const height_meters = this.getHeight(this.area);
    const vertical_resolution = this.getVerticalResolution(this.area);
    
    const worksheet = XLSX.utils.sheet_new();
    XLSX.utils.sheet_add_aoa(worksheet, [
      [ "Farm name", this.project.name ],
      [ "Species", this.project.species ],
      [ "Matrix dimension X", this.horizontal_resolution, "cells" ],
      [ "Matrix dimension Y", this.vertical_resolution, "cells" ],
      [ "Cell width", width_meters / this.horizontal_resolution, "m" ],
      [ "Cell height", height_meters / vertical_resolution, "m" ],
      [ "Number of structures", this.structures.length ],
      [ "Structure depth", this.project.structureDepth, "m" ],
      [ "Stocking", this.project.stocking, "animals" ],
    ]);
    worksheet["!cols"] = [ { wch: 25 }, { wch: 16 } ];    
    return worksheet;
  }

  private createStructuresWorksheet = () => {
    const worksheet = XLSX.utils.sheet_new();
    XLSX.utils.sheet_add_aoa(worksheet, [ ["ID" , "Name", "Stocking"], ...this.structures.map((s, idx) => [ idx+1, s.name, s.stocking ])]);
    worksheet["!cols"] = [ { wch: 10 }, { wch: 25 }, { wch: 10 } ];    
    return worksheet;
  }

  private createDataWorksheet = () => {
    const worksheet = XLSX.utils.sheet_new();
    XLSX.utils.sheet_add_aoa(worksheet, [ 
      ["X" , "Y", "Latitude", "Longitude", "Depth", "Current U", "Current V", "Structure", "Stocking"], 
      ...this.data
    ]);
    return worksheet;
  }  

  private calculateDepth = (lng: number, lat: number): number => {
    return this.bathymetryLayer ? this.idw(lng, lat, 3, this.bathymetryLayer) : null;
  }

  private calculateUV = (lng: number, lat: number): number[] => {
    return this.currentSpeedLayer ? this.idw2(lng, lat, 3, this.currentSpeedLayer) : null;
  }

  private calculateStructure = (x: number, y: number, lng: number, lat: number): number => {
    const cellBbox: IBBox = { 
      minLng: lng - 0.5 * this.lngStep,
      maxLng: lng + 0.5 * this.lngStep,
      minLat: lat - 0.5 * this.latStep,
      maxLat: lat + 0.5 * this.latStep
    }

    // Check cellBBox against all structure bboxes:
    for(let i = 0; i < this.bboxes.length; i++) {
      // Bboxes overlap?
      if(this.checkOverlap(this.bboxes[i], cellBbox)) {
        // Calculate a polygon for this cell to see if the actual polygon overlaps the cell bbox:
        const cellPoly = polygon([[
          [lng - 0.5 * this.lngStep, lat - 0.5 * this.latStep],
          [lng + 0.5 * this.lngStep, lat - 0.5 * this.latStep],
          [lng + 0.5 * this.lngStep, lat + 0.5 * this.latStep],
          [lng - 0.5 * this.lngStep, lat + 0.5 * this.latStep],
          [lng - 0.5 * this.lngStep, lat - 0.5 * this.latStep]
        ]]);   
        if(intersect(featureCollection([this.polys[i], cellPoly])) != null) return i;
        // TODO: do something with the intersection result (which is a polygon).

        // Old boolean intersection:
        // if(booleanIntersects(this.polys[i], cellPoly)) return i;
      }
    }

    return null;
  }

  private drawPixel = (x: number, y: number, color: string) => {
    if(this.pixelSize == 1) {
      this.ctx.fillStyle = color;
      this.ctx.fillRect(x, y, 1, 1);
    } else {
      this.ctx.fillStyle = color;
      this.ctx.fillRect(x * this.pixelSize, y * this.pixelSize, this.pixelSize, this.pixelSize);
      //this.ctx.fillStyle = color;
      //this.ctx.fillRect(x * this.pixelSize, y * this.pixelSize, this.pixelSize - 1, this.pixelSize - 1);
    }    
  }

  private calc = async (x: number, y: number) => {
    const lat = this.area.rect.minLat + (y + 0.5) * this.latStep;
    const lng = this.area.rect.minLng + (x + 0.5) * this.lngStep;

    // Perform calculations:
    const elevation = await this.dem.queryElevation(lng, lat)
    // const color = elevation > 0 ? 255 : 0;
    const depth = elevation < 1.0001 ? this.calculateDepth(lng, lat) : null;
    const uv = this.calculateUV(lng, lat);
    const structureIndex = this.calculateStructure(x, y, lng, lat);

    // Draw pixel:
    if(depth != null) {
      const depth255 = 200 - Math.floor((depth - this.minDepth) / (this.maxDepth - this.minDepth) * 200);
      const color = `rgba(${depth255},${depth255},${depth255}, 0.5)`;
      this.drawPixel(x,y,color);
    } else {
      this.drawPixel(x,y,'white');
    }

    // Draw structure pixel, if there is a structure.
    if(structureIndex != null) {
      this.drawPixel(x,y, `rgba(255,0,0,0.5)`);
    }

    // Store data point:
    this.data.push([
      x + 1,
      y + 1,
      lat,
      lng,
      this.area.bathymetry == 'negative' ? -depth : depth,
      (depth && uv) ? uv[0] : null,
      (depth && uv) ? uv[1] : null,
      structureIndex != null ? structureIndex + 1 : null,
      structureIndex == null ? null : this.structures[structureIndex].stocking
    ]);    
  }

  private step = async (y: number) => {
    for(let x = 0; x < this.horizontal_resolution; x++) {
      await this.calc(x,y);
    }
    this.onProgress(y / this.vertical_resolution * 100);
    setTimeout(() => {
      y++;
      if(y < this.vertical_resolution) {
        this.step(y);
      } else {
        this.finish();
      }
    }, 0);
  }

  public run = async () => {
    this.horizontal_resolution = this.area.resolution;
    this.vertical_resolution = this.getVerticalResolution(this.area);
    this.lngStep = (this.area.rect.maxLng - this.area.rect.minLng) / this.horizontal_resolution;
    this.latStep = (this.area.rect.maxLat - this.area.rect.minLat) / this.vertical_resolution;

    // Calculate min, max depth.
    [this.minDepth, this.maxDepth] = LayerUtils.getMinMax(this.bathymetryLayer);

    // Calculate a polygon for each structure:
    this.polys = this.structures.map(s => this.structureToPolygon(s));

    // Calculate a bbox for each structure:
    this.bboxes = this.polys.map(p => {
      const res = bbox(p);
      return { minLng: res[0], minLat: res[1], maxLng: res[2], maxLat: res[3] };
    });    

    this.data = [];
    this.step(0);
  }

  public finish = () => {
    const metadataWorksheet = this.createMetadataWorksheet();
    const dataWorksheet = this.createDataWorksheet();
    const structuresWorksheet = this.createStructuresWorksheet();

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, metadataWorksheet, "Metadata");
    XLSX.utils.book_append_sheet(workbook, dataWorksheet, "Data");
    XLSX.utils.book_append_sheet(workbook, structuresWorksheet, "Structures");

    XLSX.writeFile(workbook, "export.xlsx", { compression: true });
    this.onComplete();
  }
}

export { Export }
