enum ELayerType {
  Depth,        // depth (bathymetry)
  CurrentSpeed, // current velocity
  DailyPOC,     // POC load (gc m-2 d-1)
  YearlyPOC,    // POC load (gc m-2 y-1)
  Oxygen,       // dissolved oxygen (mg l-1)
  Ammonia,      // NH4 concentration (umol l-1)
  Chlorophyll,  // chlorophyll (ug l-1)
  Pathogen,     // pathogen (%)
  PocInSediment,
  PomInSediment,
  BacteriaInSediment,
  OxygenInSediment,
  SulphateInSediment,
  IronHydroxideInSediment,
  TotalSulphideInSediment,
  IronInSediment,
  IronSulphideInsediment,
  Therapeutants
}

export { ELayerType }
