import * as React from 'react';
import styled from 'styled-components';

import { ListView } from '@longline/aqua-ui/controls/ListView/ListView';
import { SVG } from '@longline/aqua-ui/svg';
import { Icon } from '@longline/aqua-ui/controls/Icon';

import { useGlobalState } from '../../contexts/state/GlobalStateContext';
import { ILayer } from '../../types/ILayer';
import { LayerInfoBox } from '../../infoboxes/LayerInfoBox';
import { LayerCard } from './LayerCard';
import { PaneHeader } from '../PaneHeader';
import { Number } from '@longline/aqua-ui/formatters/Number';
import { SecondaryButton } from '@longline/aqua-ui/controls/SecondaryButton';
import { useConfirm } from '../../contexts/confirm/ConfirmContext';
import { Pane } from '../Pane';
import { Guide } from '../../Guide';
import { Key } from '@longline/aqua-ui/controls/Key';
import { EyeIcon } from '../../infoboxes/EyeIcon';

const LayerPane = () => {
  const confirm = useConfirm();
  const globalState = useGlobalState();

  const handleDeleteAll = () => {
    globalState.clearLayers();
  }

  return (
    <>
      <Pane>
        {/* Title & Import button */}
        <PaneHeader title="Layers" onToggle={globalState.togglePaneExpansion} expanded={globalState.expanded}>
          <SecondaryButton positive icon={SVG.Icons.Plus} onClick={globalState.startImport}/>
        </PaneHeader>
        {/* Layer list */}
        <ListView 
          dark 
          data={globalState.layers} 
          onClick={(layer) => globalState.setActiveLayer(layer)} 
          shadow
          active={globalState.activeLayer}
          aux={<LayerInfoBox/>}
          total
          singular="layer"
          plural="layers"
          noData="There are no layers yet."
          rowHeight={56}
        >
          <ListView.Column active width={3} name="Name">{(item: ILayer) => <LayerCard layer={item}/>}</ListView.Column>
          <ListView.Column active={globalState.expanded} width="80px" name="Points" align="right">{(item: ILayer) => <Number value={item.data.length} decimals={0}/>}</ListView.Column>
          <ListView.Column active width="64px" name="">{(item: ILayer) => <EyeIcon visible={item.visible} onClick={() => globalState.toggleLayer(item)}/>}</ListView.Column>
        </ListView>
        {globalState.layers.length > 0 && 
          <FabHolder>
            <SecondaryButton icon={SVG.Icons.Delete} onClick={() => confirm.start(() => handleDeleteAll, "Delete all layers", "Are you sure you wish to delete all layers?")}/>
          </FabHolder>}
      </Pane>
      {globalState.activeLayer == null && globalState.selectedStructure == null && <Guide x={-64} y={-48} title="Layer management">
        <ul>
          <li>Click <Key value="+"/> to create a new layer from an Excel file.</li>
        </ul>
      </Guide>}
      {globalState.activeLayer != null && globalState.selectedStructure == null && <Guide x={-64} y={-48} title="Editing a layer">
        <ul>
          <li>Click <Icon url={SVG.Icons.Eye}/> to show or hide a layer.</li>
          <li>Click <Icon url={SVG.Icons.Delete}/> to remove a layer from the map.</li>
          <li>Edit contours and gradient to change layer visualization.</li>
        </ul>
      </Guide>}
    </>
  )
}

const FabHolder = styled.div`
  position: absolute;
  right: 8px;
  bottom: 8px;
`

export { LayerPane }