import * as React from 'react';
import { ThemeProvider } from 'styled-components';
import { StyleReset, StyleBase, NormalTheme } from '@longline/aqua-ui/styles';
import { ToastProvider } from '@longline/aqua-ui/services/Toast/ToastProvider';
import { ToastContainer } from '@longline/aqua-ui/services/Toast/ToastContainer';

import 'mapbox-gl/dist/mapbox-gl.css';
import { GlobalState } from './contexts/state/GlobalState'; 
import { Root } from './Root';
import { Confirm } from './contexts/confirm/Confirm';

const Main = () => {
  return (
    <ThemeProvider theme={NormalTheme}>
      <StyleReset/>
      <StyleBase/>
      <ToastProvider>
        <ToastContainer horizontalAlign='right' horizontalOffset={64} verticalOffset={48}/>
        <GlobalState>
          <Confirm>
            <Root/>
          </Confirm>
        </GlobalState>
      </ToastProvider>
    </ThemeProvider>
  )
}

export { Main }
